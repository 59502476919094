<template>
  <div class="progress-bar">
    <div
      class="progress-bar__fill flex justify-center items-center text-white"
      :style="{ width: progress + '%' }"
    >
      {{ progress }}%
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProgressBar',
  data() {
    return {}
  },
  props: {
    progress: {
      type: Number,
      required: true,
      validator: (value) => value >= 0 && value <= 100,
    },
    // progressCount: {
    //   type: Number,
    //   required: true,
    //   validator: (value) => value >= 0 && value <= 100,
    // },
  },
  components: {},
  methods: {},
  computed: {},
  mounted() {},
  watch: {},
}
</script>
<style scoped>
.progress-bar {
  width: 50%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar__fill {
  height: 100%;
  background-color: rgb(33, 193, 33);
  transition: width 0.3s ease;
}
</style>
