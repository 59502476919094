<template>
  <div class="w-full h-full flex flex-row justify-center items-center gap-8">
    <div
      @click="showDescriptionFunction"
      class="flex cursor-pointer hover:text-black justify-center text-[#646464] items-center gap-3"
    >
      <span v-if="isShowDescription">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM14.0303 6.96967C14.3232 7.26256 14.3232 7.73744 14.0303 8.0303L9.0303 13.0303C8.7374 13.3232 8.2626 13.3232 7.96967 13.0303L5.96967 11.0303C5.67678 10.7374 5.67678 10.2626 5.96967 9.9697C6.26256 9.6768 6.73744 9.6768 7.03033 9.9697L8.5 11.4393L10.7348 9.2045L12.9697 6.96967C13.2626 6.67678 13.7374 6.67678 14.0303 6.96967Z"
            fill="#E2E2E2"
          />
        </svg>
      </span>

      <span v-if="!isShowDescription">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM14.0303 6.96967C14.3232 7.26256 14.3232 7.73744 14.0303 8.0303L9.0303 13.0303C8.7374 13.3232 8.2626 13.3232 7.96967 13.0303L5.96967 11.0303C5.67678 10.7374 5.67678 10.2626 5.96967 9.9697C6.26256 9.6768 6.73744 9.6768 7.03033 9.9697L8.5 11.4393L10.7348 9.2045L12.9697 6.96967C13.2626 6.67678 13.7374 6.67678 14.0303 6.96967Z"
            fill="#1C274C"
          />
        </svg>
      </span>
      Show Description
    </div>
    <div
      class="text-[#646464] cursor-pointer hover:text-black"
      @click.prevent="$emit('selectAll')"
    >
      Select all
    </div>
    <div
      class="text-[#646464] cursor-pointer hover:text-black"
      @click.prevent="$emit('clearAll')"
    >
      Clear All
    </div>
  </div>
</template>
  
  <script>
export default {
  name: 'MultipleChecker',
  data() {
    return {
      isShowDescription: false,
    }
  },
  methods: {
    showDescriptionFunction() {
      this.isShowDescription = !this.isShowDescription
      this.$emit('showDescription')
    },
  },
}
</script>
  
  <style scoped>
</style>