<script>

import { Overlay } from '../../@trading-vue-js/src/index'

export default {
    name: 'ROC',
    mixins: [Overlay],
    methods: {
        meta_info() {
            return {
                author: 'StdSquad', version: '1.0.0',
                desc: 'Rate of Change',
                preset: {
                    name: 'Rate of Change',
                    side: 'offchart',
                    settings: {
                        lineWidth: 0.5,
                        color: '#279fc4',
                        lineType: 'solid'
                    }
                }
            }
        },
        use_for() { return ['ROC'] },
        calc() {
            return {
                props: {
                    days: { def: 9, text: 'days' }
                },
                conf: { renderer: 'Spline' },
                update: `
                    return roc(close, days)[0]
                `
            }
        },
      legend(values) {

        // console.log("val",values)
        return [{
          value: `${values[this._i1 || 1].toFixed(3)} %`, color:"#279FC4"
        }]
      },
    }
}
</script>