<template>
    <div class="w-full h-full flex items-center justify-center">
        <img :src="`https://logo.synthfinance.com/ticker/${ticker?.split('.')[0]}`" class="rounded-full w-full h-full object-contain" />
    </div>
</template>

<script>
export default{
    name:'TickerIcon',
    props:['ticker']
}

</script>