<template>
  <div>
    <input
      :type="inp.type"
      :placeholder="inp.placeholder"
      :value="inp.default"
      v-model="inp.default"
      :class="val.isSelected ? 'border-appBlue' : 'border-appBlue'"
      class="w-full text-[#7A7A7A] dark:text-[#6C707A] dark:bg-[#FFFFFF1A] radio-no-outline flex justify-center items-center dark:border-[#2D2D2D] border-[1px] py-2 px-3 focus:outline-none rounded-[5px]"
    />
    <!-- <label
      class="absolute right-[2rem] top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-[#6C707A] pointer-events-none"
    >
      {{ inp.placeholder }}
    </label> -->
  </div>
</template>

<script>
export default {
  props: {
    inp: Object,
    val: Object,
  },
  methods: {
  },
  computed: {
    placeHolderHandler(){
      return this.inp.default || 0
    }
  },
}
</script>

<style scoped>
.radio-no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid gray !important;
}

/* Custom style for the input number arrows */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  /* -webkit-appearance: none; */
  margin: 0;
  background-color: gray;
}

/* For dark mode styling */
.dark input[type='number']::-webkit-outer-spin-button,
.dark input[type='number']::-webkit-inner-spin-button {
  background-color: #1a202c; /* Tailwind's gray-900 */
  color: #e2e8f0 !important; /* Tailwind's gray-300 */
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
