<template>
  <div
    ref="symbolArea"
    class="w-full fles -bg-green-400 -border-5 -border-lime-600 h-full relative flex justify-center items-center space-x-2 !overflow-visible"
  >
    <!-- <div class="!w-[7rem] h-[3rem] -border-2 -!border-green-600 text-[12px]">
      <span>
        {{ selectedSymbolType }}
      </span>
    </div> -->
    <div class="border -!border-green-600 flex items-center">
      <div class="-!w-[10rem] h-[2.8rem]">
        <input
          v-model="value"
          placeholder="SEARCH"
          class="w-full h-full uppercase text-[12px] shadow-lg p-2 -border rounded-[4px] bg-transparent border-none focus:outline-none"
        />
      </div>

      <div
        class="!w-[7rem] h-[2.8rem] -bg-red-500 -border-2 flex items-center justify-center -!border-green-600 text-[12px]"
      >
        <span>
          {{ selectedSymbolType }}
        </span>
      </div>

      <div class="flex items-center">
        <div
          class="p-2.5 -rounded-[4px] cursor-pointer border-r flex items-center justify-center hover:bg-lightGrayHover bg-appGrayLight dark:bg-[#2d2d2d] dark:hover:bg-[#252526]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6 text-[#383838] dark:text-white"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
        <div
          @click="clearData"
          class="p-2.5 -rounded-[4px] cursor-pointer flex items-center justify-center hover:bg-lightGrayHover bg-appGrayLight dark:bg-[#2d2d2d] dark:hover:bg-[#252526]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6 text-[#383838] dark:text-white"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>

    <!-- search results -->

    <div
      v-if="openSymbolDropdown && value"
      style="z-index: 20000"
      class="w-[20rem] max-h-[20rem] absolute top-[3rem] shadow-lg border-[0.1px] border-[#E7E7E7] dark:!border-[#404248] dark:!bg-[#000000] !bg-[#ffffff] px-3 flex flex-colpy-2 overflow-y-auto scroll-container"
    >
      <!-- Loader -->
      <div
        v-if="isLoading"
        class="w-full h-[10rem] flex items-center justify-center"
      >
        <span>
          <Loader customClass="w-10 h-10" />
        </span>
      </div>

      <!-- Search results -->

      <div
        v-else-if="filteredOptions?.length > 0"
        v-for="(item, ind) in filteredOptions"
        :key="ind"
        @click="selectSymbolModalData(item)"
        class="w-full flex items-center cursor-pointer space-x-4 px-2 dark:hover:bg-[#2D2D2D] hover:bg-[#E4E4E460] border-b dark:border-b-[#2D2D2D] py-3 border-b-[#E7E7E7] transition-all"
      >
        <div
          class="w-10 h-10 flex uppercase items-center -dark:bg-[#1a1a1a] dark:text-[#f6f6f6] justify-center rounded-full bg-[#E2E8F0] dark:bg-[#676868]"
        >
          {{ item?.symbol[0] }}
        </div>

        <div
          class="w-full h-full -bg-black leading-5 flex flex-col justify-center"
        >
          <div class="flex gap-2">
            <span
              class="text-[#292929] dark:!text-[#e9ecf8] text-[12px] font-bold"
              >{{ item?.symbol }}</span
            >
            <span
              :title="item?.companyName"
              class="text-[12px] text-[#1e1e1e] dark:!text-[#858a97] font-normal"
              >{{ getTruncateText(item?.companyName) }}</span
            >
            <!-- <div v-html="getSvg('clockicon')" v-if="ind == 0"></div> -->
          </div>
          <div class="flex gap-2 mt-1 justify-between w-full">
            <div class="flex items-center space-x-2">
              <span
                class="text-[#000000] text-[11px] dark:text-[#34c244] font-semibold"
                >{{ '$185.79' }}</span
              >
              <span
                class="text-[#CE1717] text-[9px] dark:!text-[10px] font-semibold"
                >{{ '-11.11(-0.59%)' }}</span
              >
            </div>
            <div>
              <span class="text-[10px] dark:text-[#DEDEDE] text-[#323232]">{{
                item?.exchange
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="w-full h-[10rem] flex items-center justify-center">
        <span class="dark:text-[#ffffff] text-[14px]">No symbol found</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { fetchSymbolByName } from '../services/symbol'
import Loader from './Loading.vue'
import { modifyResult } from '../utils/common'
import BaseModal from './BaseModal.vue'

export default {
  name: 'SymbolSearchBox',
  components: {
    Loader,
    BaseModal,
  },
  props: {
    symbolNameProp: {
      type: String,
      default: '',
    },
    value: {
      type: String,
    },
    selectedSymbolType: {
      type: String,
      default: 'ASX',
    },
  },
  data() {
    return {
      filteredOptions: [],
      openSymbolDropdown: false,
      isLoading: false,
      symbolInput: '',
    }
  },
  methods: {
    ...mapMutations(['changeSymbol', 'deleteSymbol']),
    selectSymbolModalData(val) {
      let symbolWithExchange = `${val?.symbol}~${val.exchange}`
      this.changeSymbol({
        propName: this.symbolNameProp,
        propValue: symbolWithExchange,
      })
      this.$emit('input', symbolWithExchange)
      this.symbolInput = val.symbol
      this.openSymbolDropdown = false
    },

    async onInputChange(text) {
      console.log('text', text)
      this.isLoading = true
      if (!text) {
        let filtered2 = await fetchSymbolByName(this, '1')
        this.filteredOptions = modifyResult(filtered2)
        this.openSymbolDropdown = true
        this.$store.commit('TOP_LIST_SYMBOL', '1')
        this.$store.commit('TOP_LIST_SYMBOL_NAME', '1')
        this.optionDisplays = 'none'
        return
      } else {
        let filtered2 = await fetchSymbolByName(this, text)
        this.optionDisplays = 'block'
        this.filteredOptions = modifyResult(filtered2)
        this.openSymbolDropdown = true
        console.log('filteredOptions', this.filteredOptions)
        if (this.filteredOptions && this.filteredOptions.length) {
          this.$store.commit(
            'TOP_LIST_SYMBOL',
            JSON.stringify(filtered2[0].data[0].symbol)
          )
          this.$store.commit(
            'TOP_LIST_SYMBOL_NAME',
            JSON.stringify(filtered2[0].name)
          )
        } else {
          this.$store.commit('TOP_LIST_SYMBOL', '')
          this.$store.commit('TOP_LIST_SYMBOL_NAME', '')
          this.optionDisplays = 'none'
        }
      }
      this.isLoading = false
    },
    getTruncateText(val) {
      if (val?.length > 15) {
        return val?.substring(0, 15) + '..'
      } else {
        return val
      }
    },
    clearData() {
      this.$emit('input', '')
    },
    handleClickOutside(event) {
      if (!this.$refs.symbolArea.contains(event.target)) {
        this.openSymbolDropdown = false
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  watch: {
    value(val) {
      if (val != this.symbolInput) {
        this.onInputChange(val)
      } else {
        this.filteredOptions = []
      }
    },
  },
}
</script>
