export const formatNumber = (num) => {
  if (num >= 1e12) {
    return (num / 1e12).toFixed(1).replace(/\.0$/, '') + 'T';
  } else if (num >= 1e9) {
    return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B';
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'K';
  } else {
    return num?.toString();
  }
}

export const getTruncateText = (val, max) => {
  if (val?.length > max) {
    return val?.substring(0, max) + '..'
  } else {
    return val
  }
}

export const modifyResult = (data) => {
  const updatedArray = data?.flatMap(exchangeObj =>
    exchangeObj?.data?.map(companyObj => ({
      ...companyObj,
      exchange: exchangeObj?.name
    }))
  );
  return updatedArray || []
}

export const commaSeperatedNum = (num) => {
  return num?.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}


export const setAllValuesToTrue = (obj) => {
  return Object.fromEntries(
    Object.keys(obj).map(key => [key, true])
  );
}

export const setAllValuesToFalse = (obj) => {
  return Object.fromEntries(
    Object.keys(obj).map(key => [key, false])
  )
}


export const setAllValuesToTrueWithIsActiveKey = (obj) => {
  for (let key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if ('isActive' in obj[key]) {
        obj[key].isActive = true;
      }
    } else {
      obj[key] = true;
    }
  }
  return obj;
}
export const setAllValuesToFalseWithIsActiveKey = (obj) => {
  for (let key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if ('isActive' in obj[key]) {
        obj[key].isActive = false;
      }
    } else {
      obj[key] = false;
    }
  }
  return obj;
}