<script>
import { Overlay } from '../../@trading-vue-js/src/index'
export default {
  name: 'WMA',
  mixins: [Overlay],
  methods: {
    meta_info() {
      return {
        author: 'StdSquad',
        version: '1.0.0',
        desc: 'Weighted Moving Average',
        preset: {
          name: 'WMA',
          side: 'onchart',
          settings: {
            lineWidth: 0.5,
            color: '#e57440',
            lineType:'solid'

          }
        }
      }
    },
    use_for() {
      return ['WMA']
    },
    calc() {
      return {
        props: {
          period: { def: 12, text: 'Period' }
        },
        conf: { renderer: 'Spline' },
        update: `
                    return wma(close, period)[0]
                `
      }
    }
  }
}
</script>