<template>
  <div class="p-8">
    <!-- Button Tabs Components -->
    <div v-if="selectedButtonTypeName == 'Charting Patterns'">
      <ChartingPatternComp :dialogId="dialogId" class="fontSize" :id="id" />
    </div>
    <div v-else-if="selectedButtonTypeName == 'Events'">
      <events-filter :dialogId="dialogId" class="fontSize" :id="id" />
    </div>
    <div v-else-if="selectedButtonTypeName == 'Filters'" >
      <Filters :id="id" />
    </div>
  </div>  
</template>

<script>
import EventsFilter from './EventsFilter.vue'
import ChartingPatternComp from './ChartingPatternComp.vue'
import PatternFilter from './PatternFilter.vue'
import Filters from './Filters.vue'

export default {
  name: 'ChartPatternsIndicator',
  components: { EventsFilter, ChartingPatternComp, PatternFilter, Filters },
  props: {
    selectedButtonTypeName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: 'patterns',
      dialogId: 'chartEvent',
    }
  },
  methods: {},
  computed: {},
  mounted() {},
}
</script>

<style>
</style>