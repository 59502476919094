<template>
  <div
    class="w-full bg-white dark:bg-[#1A1A1A] dark:text-[#F6F6F6]"
    ref="outerContainer"
  >
    <!-- Handle for resizing from the left -->
    <div class="left-resize-handle" @mousedown="startLeftResize"></div>
    <div class="bg-white dark:!bg-[#1A1A1A] w-full !h-[83vh]">
      <!-- Header -->
      <div
        class="flex items-center dark:!bg-[#1A1A1A] justify-between px-2 h-[4rem] w-full border-b dark:border-[#2D2D2D]"
      >
        <!-- watchlist model btn -->
        <div
          @click="openWatchlistDetail"
          class="flex items-center gap-2 h-[2.8rem] dark:bg-[#1A1A1A] rounded-sm py-3 px-2 -w-[20%]"
        >
          <span
            class="text-[#131729] dark:text-[#F6F6F6] text-[14px] font-[600]"
            >Scanner Result</span
          >
        </div>

        <!--  icons icon -->
      </div>
      <!-- Symbols Table -->
      <div class="w-full h-[calc(95vh-12rem)] -bg-green-400">
        <ScannerTable
          @openRemoveSymbolModal="openRemoveSymbolModal"
          @getSymbolData="handleChartUpdate"
        />
      </div>
    </div>
  </div>
</template>
<script>
import StackInfoVue from './StackInfo.vue'
import ScanResultVue from './ScanResult.vue'
import MyWatchListVue from './MyWatchList.vue'
import TickerInfo from './TickerInfo.vue'
import { mapActions, mapGetters } from 'vuex'
import Minimize from './Minimize.vue'
import ScannerTable from './ScannerTable.vue'

export default {
  name: 'ScannerUiUx',
  data() {
    return {
      isMinimizeStock: true,
      isMinimizeScan: true,
      isMinimizeWatch: true,

      width: innerWidth,

      symbols: this.getScanResult,

      initialHeight: 80, // Initial height of the box
      boxHeight: 80, // Current height of the box
      boxTop: 768, // Initial top position of the box
      startY: 0, // Initial mouse Y position
      startHeight: 0, // Initial height before resizing
      startTop: 0, // Initial top position before resizing
      isResizing: false, // Track whether top resizing is active

      initialWidth: 200, // Initial width of the box
      boxWidth: 400, // Current width of the box
      boxLeft: 0, // Initial left position of the box
      startX: 0, // Initial mouse X position
      startWidth: 0, // Initial width before resizing
      startLeft: 0, // Initial left position before resizing
      isLeftResizing: false, // Track whether left resizing is active
    }
  },
  components: {
    StackInfoVue,
    ScanResultVue,
    MyWatchListVue,
    Minimize,
    TickerInfo,
    ScannerTable,
  },

  methods: {
    ...mapActions[('addToWatchlist', 'updatedWatchlist', 'removedWatchlist')],

    openWatchlistDetail() {
      this.$emit('openWatchlistDetail')
      console.log('open watchlist Modal Detail')
    },

    openAdvancedViewModal() {
      alert('Under construction AdvancedViewModal')
    },

    openSettingsModal() {
      alert('Under construction SettingsModal')
    },

    // Resizable box
    startResize(event) {
      if (this.$refs.box) {
        this.startY = event.clientY
        this.startHeight = this.$refs.box.offsetHeight
        this.startTop = this.boxTop
        this.isResizing = true
        document.addEventListener('mousemove', this.resize)
        document.addEventListener('mouseup', this.stopResize)
      }
    },
    resize(event) {
      if (this.isResizing && this.$refs.outerContainer) {
        const dy = event.clientY - this.startY
        const newHeight = this.startHeight - dy
        const newTop = this.startTop + dy

        // Get the parent container height
        const containerHeight = this.$refs.outerContainer.offsetHeight

        // Prevent the box from going outside the container and below its initial height
        if (
          newHeight >= this.initialHeight &&
          newTop >= 0 &&
          newTop + newHeight <= containerHeight
        ) {
          this.boxHeight = newHeight
          this.boxTop = newTop
        }
      }
    },
    stopResize() {
      this.isResizing = false
      document.removeEventListener('mousemove', this.resize)
      document.removeEventListener('mouseup', this.stopResize)
    },
    startLeftResize(event) {
      if (this.$refs.box) {
        this.startX = event.clientX
        this.startWidth = this.$refs.box.offsetWidth
        this.startLeft = this.boxLeft
        this.isLeftResizing = true
        document.addEventListener('mousemove', this.leftResize)
        document.addEventListener('mouseup', this.stopLeftResize)
      }
    },
    leftResize(event) {
      if (this.isLeftResizing && this.$refs.outerContainer) {
        const dx = event.clientX - this.startX
        const newWidth = this.startWidth - dx
        const newLeft = this.startLeft + dx

        // Get the parent container width
        const containerWidth = this.$refs.outerContainer.offsetWidth

        // Prevent the box from going outside the container and below its initial width
        if (
          newWidth >= this.initialWidth &&
          newLeft >= 0 &&
          newLeft + newWidth <= containerWidth
        ) {
          this.boxWidth = newWidth
          this.boxLeft = newLeft
        }
      }
    },
    stopLeftResize() {
      this.isLeftResizing = false
      document.removeEventListener('mousemove', this.leftResize)
      document.removeEventListener('mouseup', this.stopLeftResize)
    },

    openAddSymbolModal(id) {
      this.$emit('openAddSymbolModal', id)
    },
    openEditWatchlist(item) {
      console.log('edit emit', item)
      this.$emit('openEditWatchlist', item)
    },
    openRemoveSymbolModal(item) {
      this.$emit('openRemoveSymbolModal', item)
    },
    handleAddWatchlist() {
      this.$emit('handleAddWatchlist')
    },
    onDeleteWatchlistSymbol(item) {
      this.$emit('openRemoveWatchlistSymbol', item)
    },
    handleChartUpdate(sym) {
      this.$emit('symbolPress', sym)
    },
    openDownloadSymbolModal(item) {
      console.log('download item', item)
      this.$emit('openDownloadSymbol', item)
    },
  },
  computed: {
    ...mapGetters(['getScanResult']),
  },
  mounted() {
    // Ensure refs are set correctly
    if (this.$refs.box) {
      this.boxHeight = this.$refs.box.offsetHeight
      this.boxWidth = this.$refs.box.offsetWidth
      this.boxTop = this.$refs.box.offsetTop
      this.boxLeft = this.$refs.box.offsetLeft
    }
  },
  watch: {},
}
</script>
<style scoped>
.table-container {
  overflow-y: auto;
  max-height: 400px; /* Adjust height as needed */
}

th {
  position: sticky;
  top: 0;
  background-color: #fff; /* Background color for the header */
  z-index: 10;
}

.outer-container {
  position: relative;
  width: 32.5rem;
  /* margin-left: 2rem; */
  height: 960px;
  /* border: 2px solid orange; */
  /* background-color: green; Maximum height of the container */
  overflow: hidden; /* Prevent overflow */
}

.resizable-box {
  position: absolute;
  width: 100%;
  /* background-color: gray; Slate color */
  overflow: auto;
  transition: all;
}

.content {
  height: 100%;
  width: 100%;
  /* background-color: gray; Darker slate color */
  padding: 1rem;
}

.resize-handle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  width: 100%;
  cursor: ns-resize;
  background-color: rgba(0, 0, 0, 0.1);
}

.left-resize-handle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.5px;
  cursor: ew-resize;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: none;
}
.scroll-container {
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* table {
    width: 100%;
    border-collapse: collapse;
  }
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  th {
    background-color: #f2f2f2;
  } */
</style>
