var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full"},[_c('FormLayout',[(_vm.linkSent)?_c('div',[_c('div',[_c('span',{staticClass:"largeText",staticStyle:{"font-size":"44px"}},[_vm._v("Reset password link has been sent to your "),_c('span',{staticStyle:{"color":"#f20e17"}},[_vm._v("Email")])])])]):_c('div',{staticClass:"w-full h-full md:space-y-3"},[_c('div',{staticClass:"formFreeTrial mt-[1.5rem]",staticStyle:{"height":"15%","display":"flex","flex-direction":"column","justify-content":"center","font-size":"40px","color":"#300003","font-weight":"bold"}},[_c('div',{staticClass:"formSevenDayTrial text-appBlue border !border-appBlue",staticStyle:{"font-size":"18px","width":"fit-content","border-radius":"5px","padding":"5px 15px 5px 15px","font-weight":"500","display":"none","margin-bottom":"10px"}},[_vm._v(" 3-MONTHS FREE TRIAL ")]),_c('span',{staticClass:"headerTitle text-appGrayDark"},[_vm._v(" Forgot Password ")]),(_vm.showResponseError)?_c('span',{staticClass:"errorText",staticStyle:{"color":"#f20e17","font-size":"15px","margin-top":"4%","font-weight":"300"}},[_vm._v(_vm._s(_vm.showResponseError))]):_vm._e()]),_c('div',{staticClass:"inputHeight",staticStyle:{"height":"8%"}},[_c('DynamicInput',{attrs:{"type":"text","placeholder":"Email","isError":_vm.isEmailError,"iconImg":require(`@/assets/${_vm.isEmailError ? 'redEmail.svg' : 'email.svg'}`)},on:{"enter-pressed":_vm.handleForget},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',{staticStyle:{"margin-top":"1%"}},[_c('span',{staticClass:"errorText",staticStyle:{"color":"#f20e17","font-size":"13px"}},[_vm._v(_vm._s(_vm.emailErrorMsg))])]),_c('div',{staticClass:"btnStyle inputHeight !mt-10",staticStyle:{"height":"8%"}},[_c('button',{class:_vm.email
              ? 'bg-appBlue hover:bg-blueHover'
              : 'bg-[#E0E0E0] hover:bg-lightGrayHover',staticStyle:{"height":"100%","width":"100%","border-radius":"5px","font-size":"18px","display":"flex","align-items":"center","justify-content":"center","cursor":"pointer"},style:({
            color: _vm.email ? 'white' : '#828282',
            cursor: _vm.isLoading ? 'not-allowed' : 'pointer',
            pointerEvents: _vm.isLoading ? 'none' : 'auto',
          }),on:{"click":_vm.handleForget}},[(_vm.isLoading)?_c('span',{staticStyle:{"margin-right":"10px"}},[_c('Loader',{attrs:{"customClass":"w-8 h-8"}})],1):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.isLoading ? 'Sending link' : 'Send link')+" ")]),(_vm.email)?_c('div',{staticStyle:{"margin-left":"8%"}},[_c('img',{attrs:{"src":require("../assets/rightarrow.svg")}})]):_vm._e()])]),_c('div',{staticClass:"w-full flex justify-end"},[_c('router-link',{attrs:{"to":{ name: 'SignIn' }}},[_c('div',{staticClass:"w-fit hover:underline text-[#F20E17] text-appBlue cursor-pointer"},[_vm._v(" Back to Sign In ")])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }