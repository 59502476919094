<template>
  <div
    style="
      height: 100%;
      width: 100%;
      background: white;
      border-radius: 30px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-x: hidden;
    "
  >
    <!-- <div style="height:5%;font-size:12px;width:100%">
    
      <div style="display: flex; align-items: center;justify-content: center;">
      <div style="width: 90%;display: flex;justify-content: space-between;">
          <div style="display: flex;width:fit-content; margin-right: 2rem;" v-for="(item,i) of marqueData" :key="i">
                  <span style="color:#828282;width: 80%;white-space: nowrap;">{{ item?.name }}</span> 
                  <span style="color:#F20E17;padding-left: 2rem;">{{ item?.percent }}</span> 
              </div>
      </div>
      </div>



              </div> -->
    <div
      style="
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
      "
    >
      <div style="height: 70%; width: 90%" class="trial-info-header">
        <!-- trial content -->
        <div
          class="text-appBlue border !border-appBlue"
          style="
            font-size: 18px;
            width: fit-content;
            border-radius: 5px;
            padding: 5px 15px 5px 15px;
            font-weight: 500;
          "
        >
          3-MONTHS FREE TRIAL
        </div>
        <div
          class="trial-Heading leading-[5.5rem]"
          style="margin: 6% 0% 2% 0%; font-size: 65px; font-weight: bold"
        >
          <span class="text-appGrayDark">Scans 1000+ Stocks in </span>
          <span class="text-appBlue">seconds </span>
          <span class="text-appGrayDark">with ProTrading Scans</span>
        </div>
        <div class="join-us-container">
          <div class="user-profile">
            <img
              src="https://images.unsplash.com/photo-1558203728-00f45181dd84?q=80&w=2074&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="User 1"
            />
          </div>
          <div class="user-profile">
            <img
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="User 2"
            />
          </div>
          <div class="user-profile">
            <img
              src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="User 3"
            />
          </div>
          <div class="user-profile">
            <img
              src="https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1961&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="User 4"
            />
          </div>
          <div class="join-us-text">
            5k+ Stock Traders joined us, now it's your turn
          </div>
        </div>
      </div>
      <div
        style="
          height: 30%;
          width: 90%;
          color: #aeaeae;
          font-size: 15px;
          text-align: justify;
        "
        class="importantNotice"
      >
        <!-- important notice -->
        <span style="font-weight: bold">IMPORTANT NOTICE: </span>
        <span style="font-weight: 200; color: #c5c5c5" class="disclaimer-text">
          ProTrading Scans website is for general information only and is not
          intended to address any person’s personal financial requirements,
          goals, objectives, or life situation. The information does not form
          and should not be considered as personal, tailored, or one-to-one
          advice. Nor is it a recommendation about your investment decisions.
          ProTrading Scans are not financial advisors and we do not hold
          ourselves out to be financial advisors or planners. This website is
          not a substitute for professional financial advice. The information
          does not consider your personal circumstances. The contents of this
          site change daily and the past performance results on this website do
          not guarantee future results. This website contains general
          information only. The information on this website is delivered to the
          general public and is not aimed at any individual. We encourage you to
          seek advice from a trusted and qualified financial adviser. Nothing on
          this website should be taken as a solicitation to buy or sell a
          financial product. Any reliance you place on information on this
          website is strictly at your own risk. You alone accept the
          responsibility for your investment decisions. Investing in stocks
          carries a risk of financial loss when stock prices fall. Only trade
          with funds you can afford to lose.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// import DynamicMarquee from 'vue-dynamic-marquee'

export default {
  name: 'TrialInfo',
  components: {
    //   DynamicMarquee
  },
  props: {},
  data() {
    return {
      marqueData: [
        { name: 'Apple Inc', percent: '+0.37%' },
        // {name:"E. I. du Pont de Nemours and Company",percent:"+0.08%"},
        // {name:"Alcoa Corporation",percent:"+1.31%"},
        { name: 'Adicet Bio Inc', percent: '-0.14%' },
        { name: 'Adagene Inc', percent: '+0.42%' },
        { name: 'Addus HomeCare Corp', percent: '+0.99% ' },
      ],
    }
  },
  methods: {},
}
</script>

<style scoped>
.join-us-container {
  /* text-align: center; */
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.user-profile {
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin-left: -30px;
  z-index: 1;
}

.user-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile:nth-child(2) {
  margin-left: -20px;
}

.user-profile:nth-child(3) {
  margin-left: -20px;
}

.join-us-text {
  font-size: 20px;
  color: #333333;
  margin-left: 20px;
  /* margin-top: 20px; */
}
</style>
