<template>
  <div class="w-full h-full flex flex-col">
    <div class="w-full h-[5rem] border-b">
      <DropDownHead @ShowNavDropDownClose="ShowNavDropDownClose" />
    </div>
    <div class="w-full !h-[calc(100%-10rem)] -bg-blue-300 overflow-hidden">
      <div v-if="btnName == 'chartType'" class="w-full h-full -bg-green-300">
        <ChartTypeVue @handleSelectChartType="ShowNavDropDownClose" />
      </div>

      <div v-if="btnName == 'interval'" class="w-full h-full">
        <IntervalVue
          @handleSelectInterval="ShowNavDropDownClose"
          @timeFrameEmit="handleSelectInterval"
        />
      </div>

      <div v-if="btnName == 'range'" class="w-full h-full">
        <RangeVue
          @handleSelectRange="ShowNavDropDownClose"
          @getTimeStamp="handleSelectRange"
          @closeRange="handleClose"
        />
      </div>

      <div v-if="btnName == 'navmenu'" class="w-full h-full">
        <NavBtnInMob
          @handleSelectRange="ShowNavDropDownClose"
          @openRightBarFromMob="handleSelectRightBarData"
          @closeRange="handleClose"
        />
      </div>
    </div>

    <div class="w-full h-[5rem] bg-white px-8 border-t">
      <DropDownFooter
        firstTitle="Close"
        colorType="color"
        @close="ShowNavDropDownClose"
      />
    </div>
  </div>
</template>
<script>
import ChartTypeVue from './ChartTypeVue.vue'
import DropDownHead from './DropDownHead.vue'
import IntervalVue from './IntervalVue.vue'
import RangeVue from './RangeVue.vue'
import NavBtnInMob from './NavBtnInMob.vue'
import DropDownFooter from './DropDownFooter.vue'

export default {
  name: 'App',
  data() {
    return {}
  },
  components: {
    ChartTypeVue,
    DropDownHead,
    IntervalVue,
    RangeVue,
    NavBtnInMob,
    DropDownFooter,
  },
  methods: {
    ShowNavDropDownClose() {
      this.$emit('ShowNavDropDownClose')
    },
    handleSelectInterval(name) {
      this.$emit('handleSelectInterval', name)
    },

    handleSelectRange(name) {
      this.$emit('handleSelectRange', name)
    },

    handleSelectRightBarData(name) {
      this.$emit('openRightBarFromMob', name)
      console.log('openRightBarFromMobName', name)
    },

    handleClose() {
      this.$emit('closeRange')
    },
  },
  computed: {},
  props: {
    btnName: String,
  },
  mounted() {},
  watch: {},
}
</script>
<style scoped></style>
