<template>
  <div class="w-full h-full flex flex-col">
    <!-- Button Tabs Components -->

    <div class="flex justify-between items-center w-full h-[53%]">
      <div class="w-[75%] px-7 py-4">
        <ButtonTabs
          :buttonsProps="buttons"
          @selected-button="selectedButton"
          :id="id"
          :selectedButtonTypeName="selectedButtonTypeName"
          :scannerNameList="scannerNameList"
        />
      </div>
      <div class="w-[25%] flex flex-row gap-6 justify-center items-center">
        <div class="text-[14px] text-[#323232]">Candle Interval</div>
        <select
          style="outline: none; box-shadow: none"
          class="w-[10rem] h-[2.5rem] font-bold px-3 border-[2px] focus:ring-transparent !border-[#323232]"
          v-model="selectedTimeFrame"
        >
          <option
            class=""
            v-for="(option, index) in scanTypes"
            :key="index"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>

    <div class="w-full h-[47%] flex flex-row justify-between items-center">
      <div class="w-[78%] flex flex-row gap-4 ml-8">
        <select
          v-model="selectedMarketCode"
          class="w-[10rem] h-[2.5rem] font-bold px-3 border-[2px] focus:ring-transparent !border-[#323232]"
        >
          <option
            v-for="option in marketTypes"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </select>

        <select
          class="w-[10rem] h-[2.5rem] font-bold px-3 border-[2px] focus:ring-transparent !border-[#323232]"
          v-if="selectedMarketCode === 'market'"
          v-model="selectedMarketType"
        >
          <option
            v-for="option in marketType"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </select>

        <select
          class="w-[10rem] h-[2.5rem] font-bold px-3 border-[2px] focus:ring-transparent !border-[#323232]"
          v-model="selectedFilterBy"
          v-if="selectedMarketCode === 'market'"
        >
          <option
            v-for="option in filterTypes"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </select>

        <select
          class="w-[10rem] h-[2.5rem] font-bold px-3 focus:ring-transparent border-[2px] border-[#323232]"
          v-if="selectedFilterBy === 'sector'"
        >
          <option
            v-for="option in getMarketCategory"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </select>

        <select
          class="w-[10rem] h-[2.5rem] font-bold px-3 focus:ring-transparent border-[2px] border-[#323232]"
          v-model="selectedIndustry"
          v-if="selectedFilterBy === 'industry'"
        >
          <option
            v-for="option in getIndustryGroupDD"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </select>

        <select
          class="w-[10rem] h-[2.5rem] font-bold px-3 focus:ring-transparent border-[2px] border-[#323232]"
          v-if="selectedMarketCode === 'watch-list'"
          v-model="selectedWatchCode"
        >
          <option
            v-for="option in getWatchListFromStore"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </select>

        <select
          v-model="selectedScanCode"
          class="w-[15rem] h-[2.5rem] font-bold px-3 focus:ring-transparent border-[2px] border-[#323232]"
          v-if="selectedMarketCode === 'scan-result'"
        >
          <option
            v-for="option in getScanListFromStore"
            :value="option.value"
            :key="option.value"
          >
            {{ option.text }}
          </option>
        </select>
      </div>
      <div class="h-full w-[35%]">
        <multiple-checker
          @showDescription="showDescription"
          @selectAll="selectAll"
          @clearAll="clearAll"
        />
      </div>
    </div>
  </div>
</template>
   <script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js'
import ButtonTabs from '../component/ButtonTabs.vue'
import { tabButtons } from '../utils/newScanner.js'
import { marketsData } from '../../src/utils/marketData.js'
import MultipleChecker from './MultipleChecker.vue'
export default {
  name: 'RightSideBar',
  components: { ButtonTabs, MultipleChecker },
  props: {
    id: {
      type: String,
      default: '',
    },
    selectedButtonTypeName: {
      type: String,
    },
    type: {
      type: String,
      default: 'scanner',
    },
    scannerNameList: Object,
  },
  data() {
    return {
      buttons: JSON.parse(JSON.stringify(tabButtons)),
      selectedScanType: 'W',
      scanTypes: [
        { value: 'D', label: 'DAILY' },
        { value: 'W', label: 'WEEKLY' },
        { value: 'M', label: 'MONTHLY' },
      ],
      filterTypes: [
        {
          text: 'All',
          value: 'all',
        },
        {
          text: 'Sector',
          value: 'sector',
        },
        {
          text: 'Industry',
          value: 'industry',
        },
        {
          text: 'SubIndustry',
          value: 'subIndustry',
        },
      ],
    }
  },
  methods: {
    ...mapActions([
      'setScanDataSettings',
      'setScanDataSettingsOld',
      'setSelectedMarket',
      'setSelectedMarketNew',
      'retrieveSymbolsList',
      'toggleDescription',
      'setToggleAll',
    ]),
    clearAll() {
      let payload = {
        id: this.id,
        tab: this.selectedButtonTypeName,
        type: 'clearAll',
      }
      this.setToggleAll(payload)
    },
    selectAll() {
      let payload = {
        id: this.id,
        tab: this.selectedButtonTypeName,
        type: 'selectAll',
      }
      this.setToggleAll(payload)
    },
    showDescription() {
      this.toggleDescription()
    },
    selectedButton(payload) {
      this.$emit('selectedBtn', payload)
    },
  },
  computed: {
    ...mapGetters([
      'dataSettings',
      'dataSettingsForScan',
      'selectedMarketForScan',
      'themePrimaryColor',
      'themeSecondaryColor',
      'showWatchRenameModal',
      'themeFontColor',
      'scanResults',
      'watchLists',
      'selectedMarket',
      'getIndustryGroup',
      'getSubIndustryGroup',
      'getSectorGroup',
      'userChoice',
      'themeDarkMode',
    ]),
    modalID() {
      return this.$props.id
    },
    modalType() {
      return this.$props.type
    },
    getWatchListFromStore() {
      return this.watchLists.map((v, k) => {
        // console.log(JSON.stringify(v))
        return { value: v.id, text: v.name }
      })
    },
    selectedWatchCode: {
      get() {
        if (this.modalID && this.modalType === 'scanner')
          return this.dataSettingsForScan(this.modalID, this.modalType)
            .watchCode
        else if (this.modalID && this.modalType === 'guru') {
          return this.dataSettingsForScan(this.modalID, this.modalType)
            .watchCode
        } else return this.dataSettings.watchCode
      },
      set(value) {
        // console.log(value, this.watchLists[value])
        if (this.modalID && this.modalType === 'scanner') {
          this.setScanDataSettings({
            id: this.modalID,
            type: this.modalType,
            settings: {
              watchCode: value,
              watchCodeId: value,
              scanCode: '',
            },
          })
        } else if (this.modalID && this.modalType === 'guru') {
          return this.setScanDataSettings({
            type: 'guru',
            id: this.modalID,
            settings: {
              watchCode: value,
              watchCodeId: value,
              scanCode: '',
            },
          })
        } else {
          this.setScanDataSettingsOld({
            watchCode: value,
            watchCodeId: value,
            scanCode: '',
          })
        }
      },
    },
    selectedIndustry: {
      get() {
        if (this.modalID && this.modalType === 'scanner')
          return this.dataSettingsForScan(this.modalID, this.modalType).industry
        else if (this.modalID && this.modalType === 'guru') {
          return this.dataSettingsForScan(this.modalID, this.modalType).industry
        } else return this.dataSettings.industry
      },
      set(value) {
        if (this.modalID && this.modalType === 'scanner') {
          this.setScanDataSettings({
            id: this.modalID,
            type: this.modalType,
            settings: { industry: value },
          })
        } else if (this.modalID && this.modalType === 'guru') {
          return this.setScanDataSettings({
            type: 'guru',
            id: this.modalID,
            settings: { industry: value },
          })
        } else {
          this.setScanDataSettingsOld({ industry: value })
        }
      },
    },
    getMarketCategory() {
      let data = []
      if (this.getSectorGroup[this.selectedMarketType]) {
        return this.getSectorGroup[this.selectedMarketType].map((d) => {
          return { value: d, text: d }
        })
      }
      return data
    },
    getIndustryGroupDD() {
      let data = []
      if (this.getIndustryGroup[this.selectedMarketType]) {
        return this.getIndustryGroup[this.selectedMarketType].map((d) => {
          return { value: d, text: d }
        })
      }
      return data
    },
    selectedFilterBy: {
      get() {
        if (this.modalID && this.modalType === 'scanner')
          return this.dataSettingsForScan(this.modalID, this.modalType).filterBy
        else if (this.modalID && this.modalType === 'guru') {
          return this.dataSettingsForScan(this.modalID, this.modalType).filterBy
        } else return this.dataSettings.filterBy
      },
      set(value) {
        if (this.modalID && this.modalType === 'scanner') {
          this.setScanDataSettings({
            id: this.modalID,
            type: this.modalType,
            settings: { filterBy: value, sector: '' },
          })
        } else if (this.modalID && this.modalType === 'guru') {
          return this.setScanDataSettings({
            type: 'guru',
            id: this.modalID,
            settings: { filterBy: value, sector: '' },
          })
        } else {
          this.setScanDataSettingsOld({ filterBy: value })
        }
      },
    },
    selectedMarketType: {
      get() {
        if (this.modalID && this.modalType === 'scanner')
          return this.dataSettingsForScan(this.modalID, this.modalType).type
        else if (this.modalID && this.modalType === 'guru') {
          return this.dataSettingsForScan(this.modalID, this.modalType).type
        } else return this.dataSettings.type
      },
      set(value) {
        console.log('selectedMarketTye value', value)
        if (this.modalID && this.modalType === 'scanner') {
          this.setScanDataSettings({
            id: this.modalID,
            type: this.modalType,
            settings: { type: value },
          })
        } else if (this.modalID && this.modalType === 'guru') {
          return this.setScanDataSettings({
            type: this.modalType,
            id: this.modalID,
            settings: { type: value },
          })
        } else {
          this.setScanDataSettingsOld({ type: value })
        }
      },
    },
    selectedMarketCode: {
      get() {
        if (this.modalID) {
          return this.selectedMarketForScan(this.modalID, this.modalType)
        }
        return this.selectedMarket
      },
      set(value) {
        if (this.modalID) {
          this.setSelectedMarketNew({
            id: this.modalID,
            value,
            type: this.modalType,
          })
        } else {
          this.setSelectedMarket(value)
        }
      },
    },
    marketType: {
      get() {
        return marketsData.map((m) => {
          return { value: m.code, text: m.desc }
        })
      },
    },
    marketTypes() {
      return this.scanResults?.length > 0
        ? [
            {
              text: 'Market',
              value: 'market',
            },
            {
              text: 'Watch list',
              value: 'watch-list',
            },
            {
              text: 'Scan Result',
              value: 'scan-result',
            },
          ]
        : [
            {
              text: 'Market',
              value: 'market',
            },
            {
              text: 'Watch list',
              value: 'watch-list',
            },
          ]
    },
    selectedTimeFrame: {
      get() {
        if (this.modalID && this.modalType === 'scanner') {
          return this.dataSettingsForScan(this.modalID, this.modalType)
            ?.timeFrame
        } else if (this.modalID && this.modalType === 'guru') {
          return this.dataSettingsForScan(this.modalID, this.modalType)
            ?.timeFrame
        } else return this.dataSettings.timeFrame
      },
      set(value) {
        if (this.modalID && this.modalType === 'scanner') {
          this.setScanDataSettings({
            type: 'scanner',
            id: this.modalID,
            settings: { timeFrame: value },
          })
        } else if (this.modalID && this.modalType === 'guru') {
          return this.setScanDataSettings({
            type: 'guru',
            id: this.modalID,
            settings: { timeFrame: value },
          })
        } else {
          this.setScanDataSettingsOld({ timeFrame: value })
        }
      },
    },
    getScanListFromStore() {
      return this.scanResults.map((v, k) => {
        // console.log(JSON.stringify(v))
        return { value: v.scanId, text: v.scanName }
      })
    },
    selectedScanCode: {
      get() {
        if (this.modalID && this.modalType === 'scanner')
          return this.dataSettingsForScan(this.modalID, this.modalType).scanCode
        else if (this.modalID && this.modalType === 'guru') {
          return this.dataSettingsForScan(this.modalID, this.modalType).scanCode
        } else return this.dataSettings.scanCode
      },
      set(value) {
        // console.log(value,this.watchLists[value])
        let filterList = this.scanResults.find((f) => f.scanId === value)
        // console.log(filterList.results,value)
        if (filterList && filterList.results && filterList.results.length) {
          let symbolList = filterList.results.map((s) => s.symbol)
          if (this.modalID && this.modalType === 'scanner') {
            this.setScanDataSettings({
              id: this.modalID,
              type: this.modalType,
              settings: { scanCode: value, scanList: symbolList },
            })
          } else if (this.modalID && this.modalType === 'guru') {
            this.setScanDataSettings({
              id: this.modalID,
              settings: { scanCode: value, scanList: symbolList },
            })
          } else {
            this.setScanDataSettingsOld({
              scanCode: value,
              scanList: symbolList,
            })
          }
        } else {
          if (this.modalID && this.modalType === 'scanner') {
            this.setScanDataSettings({
              id: this.modalID,
              type: this.modalType,
              settings: { scanCode: value, scanList: [] },
            })
          } else if (this.modalID && this.modalType === 'guru') {
            this.setScanDataSettings({
              id: this.modalID,
              settings: { scanCode: value, scanList: [] },
            })
          } else {
            this.setScanDataSettingsOld({ scanCode: value, scanList: [] })
          }
        }
      },
    },
  },
  mounted() {
    this.retrieveSymbolsList()
    console.log('watchlist==>', this.watchLists)
    console.log('this.$props.id', this.$props.id)
  },
  watch: {},
}
</script>
   
   <style>
</style>