<template>
    <div @click="handlePress" :class="colorType=='color'?'text-appBtnTxt bg-appBtnActive hover:bg-appBtnHover dark:!border-[#2D2D2D]': colorType == 'green' ? 'text-[#FFFFFF] bg-[#00c92e] hover:bg-[#0caf2f] dark:!border-[#2D2D2D]' : ' hover:bg-appBtnHover dark:!border-[#2D2D2D] dark:bg-[#FFFFFF1A] dark:hover:bg-[#232323] dark:text-[#F6F6F6] text-[#292929]'" class=" font-[600] text-[12px] px-10 cursor-pointer flex items-center space-x-2 py-2 border-[#BCBCBC] border rounded-[6px]">
        <span>
            {{ title }}
        </span>  
        <span v-if="isLoading" >
          <Loader customClass="w-4 h-4" />
        </span>
    </div>
</template>

<script>
import Loader from './Loading.vue';

export default {
    name:'ButtonComp',
    props:['colorType','title','isLoading'],
    components:{
        Loader
    },

    methods:{
        handlePress(){
            this.$emit('press')
        }
    }
}



</script>