<template>
  <div class="flex flex-col xxs:w-[22rem] md:w-[35vw] h-auto overflow-hidden">
    <!-- header -->
    <div
      class="flex flex-row -bg-gray-400 justify-between items-center py-2 mt-2 px-3"
    >
      <div class="w-auto h-auto mb-4 flex items-center">
        <svg
          width="26"
          class="text-[#4C4C4C] dark:text-[#989898]"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.8335 7.04102H17.3335"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.49984 7.04102H2.1665"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.8332 10.8333C12.9273 10.8333 14.6248 9.13575 14.6248 7.04167C14.6248 4.94758 12.9273 3.25 10.8332 3.25C8.73909 3.25 7.0415 4.94758 7.0415 7.04167C7.0415 9.13575 8.73909 10.8333 10.8332 10.8333Z"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.6665 18.959H2.1665"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M23.8333 18.957H19.5"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.1667 22.7493C17.2607 22.7493 18.9583 21.0518 18.9583 18.9577C18.9583 16.8636 17.2607 15.166 15.1667 15.166C13.0726 15.166 11.375 16.8636 11.375 18.9577C11.375 21.0518 13.0726 22.7493 15.1667 22.7493Z"
            stroke="currentColor"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <div
        class="flex xxs:px-2 2lg:px-8 border-b dark:border-[#181818] -bg-green-500 flex-row items-center xxs:space-x-2 2lg:space-x-[3rem] h-[3rem] xxs:w-[70%] 2lg:w-full"
      >
        <div
          v-for="(heading, index) in headingData"
          :key="index"
          class="xxs:text-[10px] -w-auto w-[120px] flex items-center !-justify-center xl:text-[14px] relative font-medium cursor-pointer -bg-slate-800"
          @click="selectedBox = heading"
          :class="
            selectedBox === heading
              ? 'text-appBlue font-medium'
              : 'text-[#6B7280] dark:text-[#f6f6f6]'
          "
        >
          {{ heading }}
          <div
            v-if="selectedBox === heading"
            class="absolute left-[-0.5rem] xxs:w-[4rem] self-end 2xl:w-[10rem] xl:w-[70%] h-[1.2px] !mb-[-.7rem] bg-appBlue"
          ></div>
        </div>
      </div>

      <div
        @click="closeModal"
        class="p-2.5 mb-4 -bg-[#ececec] hover:bg-[#F0F3F5] xl:ml-[-4rem] -!bg-red-500 hover:opacity-70 dark:bg-[#2d2d2d] cursor-pointer rounded-[3px] flex justify-center items-center"
      >
        <!-- <img :src="require('../../src/assets/cross.svg')" class="w-[20px] h-[20px]" alt="close"> -->
        <svg
          width="20"
          height="20"
          :class="getTheme ? 'text-white' : 'text-black'"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 6L6 18"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 6L18 18"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>

    <!-- components -->
    <div
      class="xxs:w-full -bg-black h-[60vh] py-4 xxs:px-4 2lg:px-[3rem] -bg-black !flex-wrap !flex-col overflow-y-auto no-scrollbar"
    >
      <IndicatorSetting
        v-if="selectedBox == 'Indicator Settings'"
        @update-indicator-settings="updateIndicatorSettings"
        :indicatorSettingsProp="indicatorSettings"
        @update-indicator-settings-with-name="updateIndicatorSettingsWithName"
        :indicatorsSettingWithNameProps="indicatorsSettingWithName"
      />
      <StyleTheme
        v-if="selectedBox == 'Styles & Theme'"
        :chartSettingProp="chartSettingData"
        :themeSettingProp="themeSetting"
        @update-theme-settings="updateThemeSettings"
        @update-chart-settings="updateChartSettings"
      />
      <AccountSetting
        v-if="selectedBox == 'Account'"
        :accountSettingProp="accountSettingData"
        @update-account-settings="updateAccountSettings"
        :subscriptionSettingProp="subscriptionSetting"
        @update-subscription-settings="updateSubscriptionSettings"
      />
    </div>

    <!--Footer component  -->
    <div
      class="flex xxs:flex-col md:flex-row justify-between items-center dark:border-[#181818] border-t xxs:px-3 md:px-6 py-6"
    >
      <div
        class="xxs:w-full xxs:h-[4rem] -border md:w-auto md:h-auto flex flex-col xxs:!items-start md:justify-items-center"
      >
        <div class="text-[#4F4F4F] dark:text-[#ffffff]">Last updated on</div>
        <div class="text-[#888888]">05:15 pm EST</div>
      </div>
      <div class="flex flex-start items-center xxs:gap-3 md:gap-4 -border">
        <div
          @click="resetToDefault"
          class="hover:bg-[#F6F6F6] dark:!border-[#2D2D2D] dark:bg-[#FFFFFF1A] dark:hover:bg-[#232323] border cursor-pointer font-bold border-[#D1D1D1] text-[#292929] dark:text-[#F6F6F6] px-[20px] py-[8px] rounded-[10px]"
        >
          Reset
        </div>
        <div
          @click="closeModal"
          class="cursor-pointer border hover:bg-[#F6F6F6] dark:bg-[#FFFFFF1A] dark:hover:bg-[#232323] font-bold border-[#D1D1D1] dark:!border-[#2D2D2D] text-[#292929] dark:text-[#F6F6F6] px-[20px] py-[8px] rounded-[10px]"
        >
          Cancel
        </div>
        <div
          @click="handleSave"
          class="cursor-pointer text-[#fff] bg-appBlue hover:bg-blueHover !border-none border dark:border-[#2D2D2D] border-[#D1D1D1] px-[20px] py-[8px] rounded-[10px]"
        >
          Save
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StyleTheme from './StyleTheme.vue'
import IndicatorSetting from './IndicatorSetting.vue'
import AccountSetting from './AccountSetting.vue'
import { mapGetters, mapActions } from 'vuex'
import {
  copyChartSettingData,
  copyIndicatorSettings,
  copyIndicatorSettingName,
  copyThemeSetting,
  copyAccountInfo,
} from '../utils/chartSetting'

export default {
  components: {
    StyleTheme,
    IndicatorSetting,
    AccountSetting,
  },
  data() {
    return {
      selectedBox: 'Indicator Settings',
      headingData: ['Indicator Settings', 'Styles & Theme', 'Account'],
      indicatorSettings: [
        {
          headingName: 'Simple Moving Average (SMA)',
          settings: [
            {
              name: '',
              inputs: [
                { type: 'color', defaultValue: '#FC0C0C' },
                { type: 'number', defaultValue: 30 },
              ],
            },
            {
              name: '',
              inputs: [
                { type: 'color', defaultValue: '#110404' },
                { type: 'number', defaultValue: 30 },
              ],
            },
          ],
        },
        {
          headingName: 'Simple Moving Average of OBV',
          settings: [
            {
              name: '',
              inputs: [
                { type: 'color', defaultValue: '#008724' },
                { type: 'number', defaultValue: 30 },
              ],
            },
          ],
        },
        {
          headingName: 'Simple Moving Average of OBV of Volume',
          settings: [
            {
              name: '',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 30 },
              ],
            },
          ],
        },
        {
          headingName: 'Weighted Moving Average',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 30 },
              ],
            },
          ],
        },
        {
          headingName: 'Exponential Moving Average',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#1E31DB' },
                { type: 'number', defaultValue: 30 },
              ],
            },
          ],
        },
      ],
      indicatorsSettingWithName: [
        {
          headingName: 'Trend Channels',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#FC0C0C' },
                { type: 'number', defaultValue: 70 },
              ],
            },
          ],
        },
        {
          headingName: 'Bollinger Bands',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#FC0C0C' },
                { type: 'number', defaultValue: 21 },
              ],
            },
            {
              name: 'Deviations',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 40 },
              ],
            },
          ],
        },
        {
          headingName: 'Average True Range',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 34 },
              ],
            },
            {
              name: 'Multiplier',
              inputs: [
                { type: 'color', defaultValue: '#FC0C0C' },
                { type: 'number', defaultValue: 34 },
              ],
            },
          ],
        },
        {
          headingName: 'MACD',
          settings: [
            {
              name: 'Fast Period',
              inputs: [
                { type: 'color', defaultValue: '#1E31DB' },
                { type: 'number', defaultValue: 34 },
              ],
            },
            {
              name: 'Slow Period',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 34 },
              ],
            },
            {
              name: 'Signal Period',
              inputs: [
                { type: 'color', defaultValue: '#FC0C0C' },
                { type: 'number', defaultValue: 34 },
              ],
            },
          ],
        },
        {
          headingName: 'Rate Of Change',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 34 },
              ],
            },
          ],
        },
        {
          headingName: 'Relative Strength Index',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#FC0C0C' },
                { type: 'number', defaultValue: 34 },
              ],
            },
          ],
        },
        {
          headingName: 'Stochastic Oscillator',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 15 },
              ],
            },
            {
              name: '%K Period',
              inputs: [
                { type: 'color', defaultValue: '#FC0C0C' },
                { type: 'number', defaultValue: 5 },
              ],
            },
            {
              name: '%D Period',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 5 },
              ],
            },
          ],
        },
        {
          headingName: 'Williams %R',
          settings: [
            {
              name: 'Period',
              inputs: [
                { type: 'color', defaultValue: '#9EA00E' },
                { type: 'number', defaultValue: 15 },
              ],
            },
          ],
        },
        {
          headingName: 'Parabolic SAR',
          settings: [
            { name: 'Start', inputs: [{ type: 'number', defaultValue: 15 }] },
            {
              name: 'Increament',
              inputs: [{ type: 'number', defaultValue: 5 }],
            },
            { name: 'Max', inputs: [{ type: 'number', defaultValue: 5 }] },
            {
              name: 'Color',
              inputs: [{ type: 'color', defaultValue: '#FC0C0C' }],
            },
          ],
        },
        {
          headingName: 'VWAP',
          settings: [
            {
              name: 'Color',
              inputs: [{ type: 'color', defaultValue: '#FC0C0C' }],
            },
          ],
        },
      ],
      themeSetting: [
        {
          heading: 'Theme',
          key: 'theme',
          settings: [
            {
              isGroup: [
                // {
                //   name: 'Legacy Charts',
                //   type: 'checkbox',
                //   defaultValue: false,
                //   key: 'legacyChart',
                //   variant: 'fifthVariant',
                //   variantList: {
                //     firstVariant: false,
                //     secondVariant: false,
                //     thirdVariant: false,
                //     fourthVariant: false,
                //     fifthVariant: true,
                //   },
                // },
                {
                  name: 'Yahoo Charts',
                  type: 'checkbox',
                  defaultValue: false,
                  key: 'yahooChart',
                  variant: 'firstVariant',
                  variantList: {
                    firstVariant: true,
                    secondVariant: false,
                    thirdVariant: false,
                    fourthVariant: false,
                    fifthVariant: false,
                  },
                },
                {
                  name: 'Standard Green/Red',
                  type: 'checkbox',
                  defaultValue: false,
                  key: 'standardGR',
                  variant: 'secondVariant',
                  variantList: {
                    firstVariant: false,
                    secondVariant: true,
                    thirdVariant: false,
                    fourthVariant: false,
                    fifthVariant: false,
                  },
                },
                {
                  name: 'Standard Blue/Red',
                  type: 'checkbox',
                  defaultValue: true,
                  key: 'standardBR',
                  variant: 'fourthVariant',
                  variantList: {
                    firstVariant: false,
                    secondVariant: false,
                    thirdVariant: false,
                    fourthVariant: true,
                    fifthVariant: false,
                  },
                },
                {
                  name: 'Greyscale',
                  type: 'checkbox',
                  defaultValue: false,
                  key: 'grayScale',
                  variant: 'thirdVariant',
                  variantList: {
                    firstVariant: false,
                    secondVariant: false,
                    thirdVariant: true,
                    fourthVariant: false,
                    fifthVariant: false,
                  },
                },
              ],
            },
          ],
        },
        // {
        //   heading: 'Dark/Light Mode',
        //   key: 'darkLightMode',
        //   settings: [
        //     {
        //       isGroup: [
        //         {
        //           name: 'System Preference',
        //           type: 'checkbox',
        //           defaultValue: false,
        //           key: 'system',
        //         },
        //         {
        //           name: 'Dark Mode',
        //           type: 'checkbox',
        //           defaultValue: false,
        //           key: 'dark',
        //         },
        //         {
        //           name: 'Light Mode',
        //           type: 'checkbox',
        //           defaultValue: true,
        //           key: 'light',
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
      subscriptionSetting: [
        {
          heading: 'Subscription',
          key: 'subscription',
          settings: [
            {
              isGroup: [
                {
                  name: 'Monthly Plan',
                  type: 'checkbox',
                  defaultValue: true,
                  key: 'monthly',
                },
                {
                  name: 'Yearly Plan',
                  type: 'checkbox',
                  defaultValue: false,
                  key: 'yearly',
                },
              ],
            },
          ],
        },
      ],
      accountSettingData: [
        {
          heading: 'Account Information',
          key: 'accountInformation',
          showToggle: false,
          isToggle: false,
          settings: [
            {
              name: 'First Name',
              type: 'text',
              defaultValue: '',
              key: 'firstName',
              icon: '',
            },
            {
              name: 'Last Name',
              type: 'text',
              defaultValue: '',
              key: 'lastName',
              icon: '',
            },
            {
              name: 'Email',
              type: 'text',
              defaultValue: '',
              key: 'email',
              icon: 'email',
            },
            {
              name: 'Old',
              type: 'text',
              defaultValue: '',
              key: 'old',
              icon: '',
            },
            {
              name: 'New Password',
              type: 'password',
              defaultValue: '',
              key: 'newPassword',
              icon: '',
            },
            {
              name: 'Retype New Password',
              type: 'password',
              defaultValue: '',
              key: 'retypeNewPassword',
              icon: '',
            },
          ],
        },
      ],
      chartSettingData: [
        {
          heading: 'Line Chart',
          key: 'lineChart',
          showToggle: false,
          isToggle: false,
          settings: [
            {
              name: 'Line Color',
              type: 'color',
              defaultValue: '#3853B4',
              key: 'lineColor',
            },
          ],
        },
        {
          heading: 'Up/Down Bar Chart Color',
          showToggle: true,
          key: 'upDownBarChart',
          isToggle: true,
          settings: [
            {
              name: 'Bar Chart Color',
              type: 'color',
              key: 'barChartColor',
              defaultValue: '#3853B4',
            },
            {
              isGroup: [
                {
                  name: 'Up Color',
                  type: 'color',
                  defaultValue: '#0D66CE',
                  key: 'barUpColor',
                },
                {
                  name: 'Down Color',
                  type: 'color',
                  defaultValue: '#F23645',
                  key: 'barDownColor',
                },
              ],
            },
          ],
        },
        {
          heading: 'Up/Down Volume Color',
          showToggle: true,
          isToggle: true,
          key: 'upDownVolume',
          settings: [
            {
              name: 'Volume Color',
              type: 'color',
              key: 'volumeColor',
              defaultValue: '#3853B4',
            },
            {
              isGroup: [
                {
                  name: 'Up Volume Color',
                  type: 'color',
                  key: 'upVolumeColor',
                  defaultValue: '#0D66CE',
                },
                {
                  name: 'Down Volume Color',
                  type: 'color',
                  key: 'downVolumeColor',
                  defaultValue: '#F23645',
                },
              ],
            },
            {
              name: 'On-Balance Volume Color',
              type: 'color',
              key: 'onBalanceVolume',
              defaultValue: '#3853B4',
            },
          ],
        },
        {
          heading: 'Cross Hair',
          key: 'crossHair',
          showToggle: true,
          isToggle: true,
          settings: [
            {
              name: 'Line Color',
              type: 'color',
              key: 'crossLineColor',
              defaultValue: '#6C757D',
            },
            {
              name: 'Magnet',
              type: 'checkbox',
              key: 'magnet',
              defaultValue: true,
            },
          ],
        },
        {
          heading: 'Hover Y-Axis',
          key: 'yAxis',
          showToggle: false,
          isToggle: false,
          settings: [
            {
              isGroup: [
                {
                  name: 'Box Color',
                  type: 'color',
                  key: 'boxColor',
                  defaultValue: '#0D66CE',
                },
                {
                  name: 'Text Color',
                  type: 'color',
                  key: 'boxTextColor',
                  defaultValue: '#ffffff',
                },
              ],
            },
          ],
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['getTheme', 'getAppSetting', 'getUserEmail']),
  },
  methods: {
    ...mapActions(['updateAppSetting', 'updateTheme']),

    resetToDefault() {
      this.indicatorSettings = copyIndicatorSettings
      this.indicatorsSettingWithName = copyIndicatorSettingName
      this.chartSettingData = copyChartSettingData
      this.themeSetting = copyThemeSetting
      this.accountSettingData = copyAccountInfo
    },
    updateIndicatorSettingsWithName(data) {
      this.indicatorsSettingWithName = data
    },
    updateThemeSettings(data) {
      this.themeSetting = data
    },
    updateSubscriptionSettings(data) {
      this.subscriptionSetting = data
    },
    updateAccountSettings(data) {
      this.accountSettingData = data
    },
    updateChartSettings(data) {
      this.chartSettingData = data
    },
    updateIndicatorSettings(data) {
      this.indicatorSettings = data
    },
    closeModal() {
      this.$emit('closeModal')
    },

    DarkLightMode() {
      let data = this.themeSetting?.find((val) => val?.key === 'darkLightMode')
      if (data) {
        let setting = data?.settings[0]?.isGroup?.find(
          (item) => item?.defaultValue == true
        )
        if (setting?.key == 'dark') {
          this.updateTheme(true)
        } else if (setting?.key == 'light') {
          this.updateTheme(false)
        } else {
          const isLightMode = window.matchMedia(
            '(prefers-color-scheme: light)'
          ).matches
          if (isLightMode) {
            this.updateTheme(false)
          } else {
            this.updateTheme(true)
          }
        }
      }
    },
    handleSave() {
      let obj = {
        themeSetting: this.themeSetting,
        chartSetting: this.chartSettingData,
        indicatorSetting: this.indicatorSettings,
        indicatorsSettingWithName: this.indicatorsSettingWithName,
        accountSetting: this.accountSettingData,
        subscriptionSetting: this.subscriptionSetting,
      }
      this.updateAppSetting(obj)
      this.DarkLightMode()
      this.closeModal()
    },
    updateThemeValue() {
      let systemPref = this.themeSetting[1].settings[0].isGroup?.find(
        (item) => item.key == 'system'
      )
      if (!systemPref?.defaultValue) {
        this.themeSetting[1].settings[0].isGroup.forEach((setting) => {
          if (this.getTheme) {
            if (setting.key === 'dark') {
              setting.defaultValue = true
            } else {
              setting.defaultValue = false
            }
          } else {
            if (setting.key === 'light') {
              setting.defaultValue = true
            } else {
              setting.defaultValue = false
            }
          }
        })
      }
    },
  },
  mounted() {
    if (this.getAppSetting) {
      this.themeSetting = this.getAppSetting.themeSetting
      this.chartSettingData = this.getAppSetting.chartSetting
      this.indicatorSettings = this.getAppSetting.indicatorSetting
      this.indicatorsSettingWithName =
        this.getAppSetting.indicatorsSettingWithName
      this.accountSettingData = this.getAppSetting.accountSetting
      this.subscriptionSetting = this.getAppSetting.subscriptionSetting
    }

    if (this.getUserEmail) {
      let updatedArr = this.accountSettingData?.map((item) => {
        return {
          ...item,
          settings: item?.settings?.map((setting) =>
            setting.key == 'email'
              ? { ...setting, defaultValue: this.getUserEmail }
              : setting
          ),
        }
      })
      this.accountSettingData = updatedArr
    }

    this.updateThemeValue()
  },
}
</script>

<style scoped></style>
