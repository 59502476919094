<template>
  <!-- bg hover #2D2D2D -->
  <!-- bg normal #1A1A1A -->
  <!-- title text #e9ecf8 -->
  <!-- subTitle text #858a97 -->
  <!-- price text #34c244 -->
  <!-- border #404248 -->
  <!-- text #6C707A -->

  <div class="w-full h-[100%]">
    <div class="w-full h-full -border bg-white dark:!bg-[#1A1A1A]">
      <div
        class="w-full py-2 flex justify-between items-center border-b border-[#DEDEDE] dark:border-[#404248] px-[13px]"
      >
        <p
          class="text-[#323232] font-sans dark:text-[#dedede] text-[12px] font-medium"
        >
          30 Stock(s) found.
        </p>
        <div class="w-auto h-full flex items-center gap-1">
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="text-[#4C4C4C] dark:text-[#6C707A]"
            >
              <path
                d="M6.04355 1.25697C6.18824 0.932356 6.26062 0.77005 6.36137 0.720069C6.44887 0.676644 6.55162 0.676644 6.63912 0.720069C6.73987 0.77005 6.81224 0.932356 6.95693 1.25697L8.10943 3.84255C8.15224 3.93851 8.17362 3.98649 8.20674 4.02324C8.23599 4.05575 8.2718 4.08176 8.31174 4.09956C8.35693 4.11968 8.40918 4.12519 8.51368 4.13622L11.3289 4.43335C11.6823 4.47065 11.859 4.4893 11.9377 4.56967C12.006 4.63947 12.0377 4.73723 12.0235 4.83388C12.0071 4.94513 11.8751 5.06406 11.6111 5.302L9.50818 7.19712C9.43018 7.26744 9.39112 7.30263 9.36643 7.34544C9.34455 7.38338 9.33087 7.42544 9.3263 7.46894C9.32112 7.51813 9.33199 7.5695 9.3538 7.67231L9.94118 10.4415C10.0149 10.7892 10.0518 10.963 9.99968 11.0626C9.95437 11.1493 9.87124 11.2096 9.77493 11.2259C9.66405 11.2447 9.51012 11.1559 9.20224 10.9784L6.75005 9.564C6.65905 9.5115 6.61355 9.48531 6.56518 9.475C6.52237 9.46594 6.47812 9.46594 6.4353 9.475C6.38693 9.48531 6.34143 9.5115 6.25043 9.564L3.79825 10.9784C3.49039 11.1559 3.33646 11.2447 3.22558 11.2259C3.12926 11.2096 3.04611 11.1493 3.00084 11.0626C2.94872 10.963 2.98559 10.7892 3.05933 10.4415L3.64668 7.67231C3.66848 7.5695 3.67938 7.51813 3.6742 7.46894C3.66962 7.42544 3.65595 7.38338 3.63408 7.34544C3.60936 7.30263 3.57034 7.26744 3.49229 7.19712L1.3894 5.302C1.1254 5.06406 0.993392 4.94513 0.976979 4.83388C0.962729 4.73723 0.994485 4.63947 1.06282 4.56967C1.1415 4.4893 1.31822 4.47065 1.67165 4.43335L4.48684 4.13622C4.59132 4.12519 4.64356 4.11968 4.68874 4.09956C4.7287 4.08176 4.76449 4.05575 4.79378 4.02324C4.82687 3.98649 4.84826 3.93851 4.89104 3.84255L6.04355 1.25697Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div
            class="cursor-pointer w-auto h-auto"
            v-html="getSvg('exclamationicon')"
          ></div>
          <div
            class="cursor-pointer w-auto h-auto"
            v-html="getSvg('downloadicon')"
          ></div>
        </div>
      </div>
      <div class="w-full h-[88%] overflow-y-auto scroll-container">
        <div
          class="flex w-full pb-2.5 pt-3 items-center gap-3 border-b-[0.5px] bg-white border-[#DEDEDE] dark:!bg-[#1A1A1A] dark:hover:!bg-[#2D2D2D] hover:!bg-[#E4E4E460] dark:!border-[#404248] px-[13px]"
          v-for="(item, i) in stockData"
          :key="i"
        >
          <div class="w-full h-full">
            <SymbolDetailComp />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getSvgByName } from '../assets/rightsidebarsvgs/utils/SvgUtils'
import SymbolDetailComp from './SymbolDetailComp'
export default {
  name: 'ScanResult',
  data() {
    return {
      isMinimize: false,
      stockData: [
        {
          id: 1,
          icon: 'brandicon',
          title: 'IBM',
          btn: 'Saucer Botton',
          desc: 'International Business Machines Corporation',
          price: '$185.79',
          change: '-1.11(-0.59%)',
        },
        {
          id: 2,
          icon: 'brandicon',
          title: 'IBM',
          btn: 'Saucer Botton',
          desc: 'International Business Machines Corporation',
          price: '$185.79',
          change: '-1.11(-0.59%)',
        },
        {
          id: 3,
          icon: 'brandicon',
          title: 'IBM',
          btn: 'Saucer Botton',
          desc: 'International Business Machines Corporation',
          price: '$185.79',
          change: '-1.11(-0.59%)',
        },
        {
          id: 3,
          icon: 'brandicon',
          title: 'IBM',
          btn: 'Saucer Botton',
          desc: 'International Business Machines Corporation',
          price: '$185.79',
          change: '-1.11(-0.59%)',
        },
        {
          id: 3,
          icon: 'brandicon',
          title: 'IBM',
          btn: 'Saucer Botton',
          desc: 'International Business Machines Corporation',
          price: '$185.79',
          change: '-1.11(-0.59%)',
        },
        {
          id: 3,
          icon: 'brandicon',
          title: 'IBM',
          btn: 'Saucer Botton',
          desc: 'International Business Machines Corporation',
          price: '$185.79',
          change: '-1.11(-0.59%)',
        },
        {
          id: 3,
          icon: 'brandicon',
          title: 'IBM',
          btn: 'Saucer Botton',
          desc: 'International Business Machines Corporation',
          price: '$185.79',
          change: '-1.11(-0.59%)',
        },
        {
          id: 1,
          icon: 'brandicon',
          title: 'IBM',
          btn: 'Saucer Botton',
          desc: 'International Business Machines Corporation',
          price: '$185.79',
          change: '-1.11(-0.59%)',
        },
        {
          id: 2,
          icon: 'brandicon',
          title: 'IBM',
          btn: 'Saucer Botton',
          desc: 'International Business Machines Corporation',
          price: '$185.79',
          change: '-1.11(-0.59%)',
        },
        {
          id: 3,
          icon: 'brandicon',
          title: 'IBM',
          btn: 'Saucer Botton',
          desc: 'International Business Machines Corporation',
          price: '$185.79',
          change: '-1.11(-0.59%)',
        },
        {
          id: 3,
          icon: 'brandicon',
          title: 'IBM',
          btn: 'Saucer Botton',
          desc: 'International Business Machines Corporation',
          price: '$185.79',
          change: '-1.11(-0.59%)',
        },
        {
          id: 3,
          icon: 'brandicon',
          title: 'IBM',
          btn: 'Saucer Botton',
          desc: 'International Business Machines Corporation',
          price: '$185.79',
          change: '-1.11(-0.59%)',
        },
        {
          id: 3,
          icon: 'brandicon',
          title: 'IBM',
          btn: 'Saucer Botton',
          desc: 'International Business Machines Corporation',
          price: '$185.79',
          change: '-1.11(-0.59%)',
        },
        {
          id: 3,
          icon: 'brandicon',
          title: 'IBM',
          btn: 'Saucer Botton',
          desc: 'International Business Machines Corporation',
          price: '$185.79',
          change: '-1.11(-0.59%)',
        },
      ],
    }
  },
  components: {
    SymbolDetailComp,
  },
  methods: {
    getSvg(name) {
      return getSvgByName(name)
    },
  },
  computed: {},
  mounted() {},
  watch: {},
}
</script>
<style scoped>
.scroll-container {
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>
