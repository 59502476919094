<template>
  <div class="w-full h-full">
    <div class="w-full -bg-green-300">
      <RightBarHead
        :mainHeading="'Scanner Result'"
        :subHeading="`${
          scanResults[scanResults?.length - 1]?.results?.length ?? 0
        } results found`"
        :selectedRight="selectedRight"
      />
    </div>

    <div class="w-full !h-[calc(100%-4.5rem)] -bg-green-400"> 
      <ScannerResultSymbols @handleRun="handleRun"/>
    </div>
  </div>
</template>
<script>
import RightBarHead from '../../RightBarHead.vue'
import ScannerResultSymbols from './ScannerResultSymbols.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  data() {
    return {}
  },
  components: {
    RightBarHead,
    ScannerResultSymbols,
  },
  props: {
    mainHeading: String,
    subHeading: String,
    selectedRight: String,
  },
  methods: {},
  computed: {
    ...mapGetters(['scanResults']),
  },

  mounted() {
    console.log('scanResults', this.scanResults)
  },
  watch: {
    scanResults() {
      console.log('scanResults1234', this.scanResults)
    },
  },
}
</script>
<style scoped></style>
