<template>
  <div
    class="w-full -h-[95vh] -mt-[0.2rem] relative flex flex-col items-center"
  >
    <div
      v-if="openCursorDropdown"
      class="w-[150px] h-auto bg-white shadow-lg top-3 absolute left-20"
      style="z-index: 9999 !important"
    >
      <div
        v-for="(item, i) in cursorData"
        :key="i"
        @click="handleCursor(item)"
        :class="
          item.icon == getChartCursor?.icon
            ? 'bg-appBtnActive !text-appBtnTxt cursor-default'
            : ' cursor-pointer hover:bg-appBtnHover'
        "
        class="flex w-full items-center space-x-2 xxs:px-4 xxs412:px-6 xs:px-2 py-2 -border text-[#323232] dark:!text-[#6C707A] -rounded-md"
      >
        <div>
          <div
            v-html="getSvg(item?.icon, item.icon == getChartCursor?.icon)"
          ></div>
        </div>
        <div>
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="w-full flex justify-center -px-3">
      <div
        class="md:!min-w-[70%] xxs:w-full h-auto flex py-2 xxs:flex-grow md:flex-col justify-around items-center overflow-x-auto scroll-container"
      >
        <div
          ref="cursorRef"
          :title="getChartCursor?.name"
          :class="
            !getCurrentDrawing
              ? 'bg-appBtnActive'
              : 'dark:hover:!bg-[#2D2D2D] hover:!bg-appBtnHover'
          "
          @click="openCursorDropdown = !openCursorDropdown"
          class="w-[4rem] relative h-[3.3rem] dark:text-[#6C707A] dark:!bg-[#1A1A1A] flex cursor-pointer items-center justify-center"
        >
          <div
            :class="
              !getCurrentDrawing
                ? 'bg-appBtnActive'
                : 'dark:hover:!bg-[#2D2D2D] hover:!bg-appBtnHover'
            "
          >
            <div
              v-html="getSvg(getChartCursor?.icon, !getCurrentDrawing)"
            ></div>
          </div>
        </div>

        <div
          v-for="(item, i) in toolButtons"
          :key="i"
          :title="item.toolTip"
          @click="item.onClick"
          :class="
            item.key == getCurrentDrawing
              ? 'bg-appBtnActive'
              : 'dark:hover:!bg-[#2D2D2D] hover:!bg-appBtnHover'
          "
          class="w-[4rem] h-[3.3rem] dark:text-[#6C707A] dark:!bg-[#1A1A1A] flex cursor-pointer items-center justify-center"
        >
          <div>
            <div
              v-html="getSvg(item?.img, item.key == getCurrentDrawing)"
            ></div>
          </div>
        </div>
        <div
          title="Delete All Drawings"
          @click="handleDelete"
          class="w-[4rem] h-[3.3rem] dark:text-[#6C707A] dark:!bg-[#1A1A1A] flex cursor-pointer items-center dark:hover:!bg-[#2D2D2D] hover:!bg-appBtnHover justify-center"
        >
          <div>
            <div v-html="getSvg('delete')"></div>
          </div>
        </div>
      </div>
    </div>

    <trailing-stop
      v-if="showTrailingStop"
      :trialingStopData="trialingStopData"
      @hideTrailingStop="trailingStopDialogOnClose"
    />
  </div>
</template>
<script>
import { getSvgByName } from '../assets/leftsidebarsvgs/Utils/SvgUtils'
import { chartEvents } from '../chartEvents'
import TrailingStop from './dialogBoxes/TrailingStop.vue'
import { mapGetters } from 'vuex'
import store from '@/store'

export default {
  name: 'LeftBar',
  data() {
    return {
      showTrailingStop: false,
      trialingStopData: {},
      selectedDrawing: null,
      cursorData: [
        { name: 'Cross', icon: 'crosshair' },
        { name: 'Arrow', icon: 'default' },
        // {name:'Dot',icon:'dot'},
      ],
      cursorType: 'crosshair',
      openCursorDropdown: false,
      toolButtons: [
        {
          img: 'ruler',
          onClick: () => this.selectTool('RangeTool:PriceTime'),
          toolTip: 'Price Measurement Tool',
          toolTipMobile: 'Measure Tool',
          key: 'RangeTool:PriceTime',
          style: 'background-color : red',
        },
        {
          img: 'trendLine',
          onClick: () => this.selectTool('TrendLine:Trend'),
          toolTip: 'Line Tool',
          toolTipMobile: 'Line Tool',
          key: 'TrendLine:Trend',
          style: 'background-color : blue',
        },

        {
          img: 'horizontalLine',
          onClick: () => this.selectTool('HLineTool:Segment'),
          toolTip: 'Horizontal Line Tool',
          toolTipMobile: 'Horizontal Tool',
          key: 'HLineTool:Segment',
          style: 'background-color : green',
        },
        {
          img: 'arc',
          onClick: () => this.selectTool('ArcTool:Arc'),
          toolTip: 'Arc Tool',
          toolTipMobile: 'Arc Tool',
          key: 'ArcTool:Arc',
          style: 'background-color : blue',
        },
        {
          img: 'T',
          onClick: () => this.selectTool('TextTool:Label'),
          isText: true,
          toolTip: 'Text Tool',
          key: 'TextTool:Label',
          toolTipMobile: 'Text Tool',
        },
        {
          img: 'rectangle',
          onClick: () => this.selectTool('RectangleTool:Shape'),
          toolTip: 'Box Tool',
          key: 'RectangleTool:Shape',
          toolTipMobile: 'Box Tool',
        },
        {
          img: 'parallelLine',
          onClick: () => this.selectTool('ChannelTool:Segment'),
          toolTip: 'Parellel Lines Tool',
          key: 'ChannelTool:Segment',
          toolTipMobile: 'Parellel Tool',
        },
        {
          img: 'fibRetracement',
          onClick: () => this.selectTool('FibonacciRetrace:Segment'),
          toolTip: 'Fibonacci Retracement Tool',
          toolTipMobile: 'Fibonacci Tool',
          key: 'FibonacciRetrace:Segment',
        },
        {
          img: 'tradePlanner',
          onClick: () => this.selectTool('TradeVisualizerTool:PL'),
          toolTip: 'Trade Visualizer',
          key: 'TradeVisualizerTool:PL',
          toolTipMobile: 'Trade Visualizer',
        },

        {
          img: 'tradeStop',
          onClick: () => this.selectTool('TrailingStopTool:PL'),
          toolTip: 'Trailing Stop',
          toolTipMobile: 'Trailing Stop',
          key: 'TrailingStopTool:PL',
        },
      ],

      isActive: false,
      activeBtn: null,
    }
  },
  components: { TrailingStop },
  methods: {
    trailingStopDialogOnClose() {
      this.showTrailingStop = false
    },
    selectTool(toolID) {
      console.log('toolID', toolID)
      store.commit('SET_CURRENT_DRAWING', toolID)
      chartEvents.$emit('generateCustomEvent', 'tool-selected', [toolID])
    },
    handlePlus() {
      this.openCursorDropdown = true
    },
    handleCursor(val) {
      this.openCursorDropdown = false
      store.commit('SET_CHART_CURSOR', val)
    },
    handleDelete() {
      chartEvents.$emit('onDeleteButtonClick')
    },
    toggleActive(active) {
      this.activeBtn = active
      this.isActive = !this.isActive
    },
    getSvg(name, isTrue = false) {
      return getSvgByName(name, isTrue)
    },
    handleClickOutside(event) {
      if (!this.$refs.cursorRef.contains(event.target)) {
        this.openCursorDropdown = false
      }
    },
  },
  computed: {
    ...mapGetters(['getCurrentDrawing', 'getChartCursor']),
  },
  mounted() {
    const drawingModeOff = () => {
      store.commit('SET_CURRENT_DRAWING', '')
    }

    chartEvents.$on('updateTrailingStopModal', (params) => {
      console.log('TrailingStopTool', params)
      this.trialingStopData = params
      this.showTrailingStop = true
    })
    chartEvents.$on('drawingOff', drawingModeOff)
    chartEvents.$emit('bindComponentEvent', 'remove-tool', this.onToolRemoved)

    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
    chartEvents.$off('drawingOff')
  },
  watch: {},
}
</script>
<style scoped></style>
