<template>
    <div class="w-full h-full text-[1.5rem] flex items-center justify-center text-appBlue font-medium">
        {{title}}
    </div>
</template>

<script>
export default {
    name:'NoData',
    props:{
        title:String
    }
}


</script>