<template>
    <div class="smallFooterLine" style="height:100%;width: 100%;color:#AEAEAE;font-size: 20px;line-height: 20px;">
        <div style="text-align: justify;">
                <span class="importantHeading" style="font-weight:bold;">IMPORTANT NOTICE: </span> 
                <span style="font-weight:200;color:#c5c5c5;" class="disclaimer-text">ProTrading Scans website is for general information only and is not intended to address any person’s personal financial requirements, goals, objectives, or life situation. The information does not form and should not be considered as personal, tailored, or one-to-one advice. Nor is it a recommendation about your investment decisions. ProTrader are not financial advisors and we do not hold ourselves out to be financial advisors or planners. This website is not a substitute for professional financial advice. The information does not consider your personal circumstances. The contents of this site change daily and the past performance results on this website do not guarantee future results. This website contains general information only. The information on this website is delivered to the general public and is not aimed at any individual. We encourage you to seek advice from a trusted and qualified financial adviser. Nothing on this website should be taken as a solicitation to buy or sell a financial product. Any reliance you place on information on this website is strictly at your own risk. You alone accept the responsibility for your investment decisions. Investing in stocks carries a risk of financial loss when stock prices fall. Only trade with funds you can afford to lose.</span>
            </div>
            <div style="width: 100%; display: flex; align-items: center; justify-content: center; margin-top: 20px;">
                <div class="errorText" style="color:#333333;font-Size:16px;font-weight: 500;">&copy; ProTrading Scans</div>
            </div>           
    </div>
 </template>
 
 <script>
 export default {
    name: 'SmallFooter',
   props: {
  
   },
   data() {
     return {
     
     };
   },
   methods: {
 
   }
 };
 </script>
 
 <style scoped>
    
 </style>