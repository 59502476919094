<template>
  <div class="w-full !h-full pb-8">
    <div
      v-if="getSelectedWatchlist?.symbolList?.length > 0"
      class="w-full !h-full -bg-slate-500"
    >
      <div
        v-for="(item, index) in getSelectedWatchlist?.symbolList"
        :key="index"
        class="w-full h-[4rem] flex border-b items-center justify-between cursor-default hover:bg-appBtnHover -bg-slate-400 px-3"
      >
        <!-- Icon Brand Name -->
        <div class="w-[30%] flex justify-start items-center gap-2">
          <!-- Brand Icon -->
          <div
            class="min-w-[2.5rem] min-h-[2.5rem] max-w-[2.5rem] max-h-[2.5rem] rounded-full text-white text-[18px] -bg-[red] flex justify-center items-center"
          >
            <TickerIcon :ticker="item.name" />
          </div>
          <!-- Brand Name -->
          <div class="w-[70%] h-full flex flex-col -!bg-black">
            <span @click="handleSymbolPress(item.name)" class="xxs:text-[12px] qm:text-[14px] hover:underline cursor-pointer">{{ item.name }}</span>
            <span class="xxs:text-[11px] qm:text-[12px]">{{ item.id }}</span>
          </div>
        </div>

        <!-- Price -->
        <div class="w-[45%] flex flex-col items-end justify-center">
          <span
            class="text-[#131722] xxs:!text-[12px] qm:text-[14px] font-[400]"
          >
            $220.32
          </span>
          <span class="text-[green] xxs:!text-[12px] qm:text-[14px] font-[400]">
            +9.66 (4.59%)
          </span>
        </div>
      </div>
    </div>
    <div v-else class="w-full h-[20rem] flex">
      <NoData title="Your watchlist is empty" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import TickerIcon from '../../TickerIcon.vue'
import { chartEvents } from '@/chartEvents.js'
import NoData from '../../NoData'

export default {
  name: 'App',
  data() {
    return {
      showBtn: false,
      symbols: [
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
      ],
    }
  },
  components: {
    TickerIcon,
    NoData
  },
  props: {
    selectedRight: String,
  },
  methods: {
    handleSymbolPress(sym){
      chartEvents.$emit('load-symbol',sym)
    },
  },
  computed: {
    ...mapGetters(['getSelectedWatchlist']),
  },
  mounted() {
    console.log('getSelectedWatchlist', this.getSelectedWatchlist)
  },
  watch: {},
}
</script>
<style scoped></style>
