<template>
  <div
    v-if="showResendEmail"
    style="
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 40px 40px 40px 80px;
    "
    class="page-background mobileMainContainer"
  >
    <div style="width: 100%; height: 100%; position: relative">
      <div style="height: 10%">
        <Header></Header>
      </div>

      <div style="height: 80%">
        <ResendEmail
          @resendEmail="handleResendEmail"
          :isResendLoading="isResendLoading"
          :isResponseMessage="isResponseMessage"
        ></ResendEmail>
      </div>

      <div class="smallFooter scroll-container" style="display: none">
        <SmallFooter></SmallFooter>
      </div>

      <div class="hideDetail" style="height: 5%">
        <Footer></Footer>
      </div>
    </div>
  </div>
  <div v-else class="w-full h-full">
    <FormLayout>
      <div class="w-full h-full md:space-y-6 2lg:space-y-0">
        <!-- form -->
        <div
          style="display: flex; align-items: center; font-size: 15px"
          class="errorText"
        >
          <span style="color: #828282">Already have an account?</span>
          <router-link :to="{ name: 'SignIn' }">
            <span style="display: flex; align-items: center">
              <span
                style="margin: 0px 5px 0px 5px; cursor: pointer"
                class="hoverUnderline text-appBlue"
              >
                Sign In
              </span>
              <img src="../assets/login.svg" />
            </span>
          </router-link>
        </div>

        <div
          class="formFreeTrial"
          style="
            height: 15%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 40px;
            color: #300003;
            font-weight: bold;
          "
        >
          <div
            class="formSevenDayTrial text-appBlue border !border-appBlue"
            style="
              font-size: 18px;
              width: fit-content;
              border-radius: 5px;
              padding: 5px 15px 5px 15px;
              font-weight: 500;
              display: none;
              margin-bottom: 10px;
            "
          >
            3-MONTHS FREE TRIAL
          </div>
          <span class="headerTitle text-appGrayDark"> Create an Account </span>
          <span
            v-if="showResponseError"
            class="errorText"
            style="
              color: #f20e17;
              font-size: 15px;
              margin-top: 4%;
              font-weight: 300;
            "
            >{{ showResponseError }}</span
          >
        </div>

        <div class="inputHeight" style="height: 8%">
          <DynamicInput
            type="text"
            placeholder="First name"
            :isError="isFirstNameError"
            :iconImg="
              require(`@/assets/${
                isFirstNameError ? 'redUser.svg' : 'user.svg'
              }`)
            "
            v-model="firstName"
          />
        </div>

        <div style="height: 4%; margin-top: 1%">
          <span class="errorText" style="color: #f20e17; font-size: 13px">{{
            firstNameErrorMsg
          }}</span>
        </div>

        <div class="inputHeight" style="height: 8%">
          <DynamicInput
            type="text"
            placeholder="Last name"
            :isError="isLastNameError"
            :iconImg="
              require(`@/assets/${
                isLastNameError ? 'redUser.svg' : 'user.svg'
              }`)
            "
            v-model="lastName"
          />
        </div>

        <div style="height: 4%; margin-top: 1%">
          <span class="errorText" style="color: #f20e17; font-size: 13px">{{
            lastNameErrorMsg
          }}</span>
        </div>

        <div class="inputHeight" style="height: 8%">
          <DynamicInput
            type="text"
            placeholder="Email"
            :isError="isEmailError"
            :iconImg="
              require(`@/assets/${isEmailError ? 'redEmail.svg' : 'email.svg'}`)
            "
            v-model="email"
          />
        </div>

        <div style="height: 4%; margin-top: 1%">
          <span
            v-if="emailErrorMsg == 'already exits'"
            style="color: #f20e17; font-size: 13px"
            >⚠ It seems you already have an account with Pro Trader.
            <router-link :to="{ name: 'login' }" style="color: #f20e17">
              <span style="text-decoration: underline; cursor: pointer"
                >Login here</span
              >
            </router-link>
            or
            <router-link :to="{ name: 'ResetPassword' }" style="color: #f20e17">
              <span style="text-decoration: underline; cursor: pointer"
                >reset your password</span
              >
            </router-link>
          </span>
          <span
            v-else
            class="errorText"
            style="color: #f20e17; font-size: 13px"
            >{{ emailErrorMsg }}</span
          >
        </div>

        <div
          class="inputHeight"
          style="height: 8%; margin: 2% 0px 1% 0px"
          :style="{ marginTop: emailErrorMsg == 'already exits' ? '4%' : '2%' }"
        >
          <DynamicInput
            :type="showPassword ? 'text' : 'password'"
            placeholder="Password"
            :iconImg="
              require(`@/assets/${
                isPasswordError
                  ? 'redEye.svg'
                  : showPassword
                  ? 'openEye.svg'
                  : 'closeEye.svg'
              }`)
            "
            v-model="password"
            :isError="isPasswordError"
            @enter-pressed="handleCreateAccount"
            @password-visibility-toggled="handlePasswordVisibilityToggle"
          />
        </div>

        <div class="strongPassword h-[10%]">
          <div style="height: 20%; display: flex; align-items: center">
            <div
              class="strongPasswordLine"
              :style="{
                height: '12%',
                background: weakPassword === 0 ? '#F20E17' : '#BDBDBD',
                width: '31%',
              }"
            ></div>
            <div
              class="strongPasswordLine"
              :style="{
                height: '12%',
                background: mediumPassword === 1 ? '#e2e522' : '#BDBDBD',
                width: '31%',
                margin: '0 2% 0 2%',
              }"
            ></div>
            <div
              class="strongPasswordLine"
              :style="{
                height: '12%',
                background: strongPassword === 2 ? '#4ece31' : '#BDBDBD',
                width: '31%',
              }"
            ></div>
          </div>
          <div style="height: 4%; margin-top: 1%">
            <span
              v-if="passwordErrorMsg"
              style="color: #f20e17; font-size: 13px"
              class="errorText"
              >{{ showWarningIcon ? '⚠' : '' }} {{ passwordErrorMsg }}</span
            >
          </div>
          <div style="height: 4%">
            <span
              v-if="strongPassword"
              style="color: #333333; font-size: 13px"
              class="errorText"
              >Your Password is strong. Good Job!</span
            >
          </div>
        </div>

        <div class="iUnderstandContainer" style="padding-bottom: 2%">
          <div style="display: flex">
            <div class="form-group">
              <input
                type="checkbox"
                v-model="isUnderstood"
                class="checkInput focus:outline-none focus:ring-0"
              />
            </div>
            <div
              class="iUnderstandText"
              style="margin-left: 10px; font-size: 13px"
              :class="isUnderstood ? 'text-appBlue' : 'text-[#828282]'"
            >
              I understand ProTrading Scans does not provide personalised
              professional financial advice
            </div>
          </div>
          <div class="errorText" style="color: #f20e17" v-if="requiredError">
            * Required fields
          </div>
        </div>
        <div style="height: 8%" class="btnStyle inputHeight">
          <button
            :class="
              isUnderstood
                ? 'bg-appBlue hover:bg-blueHover'
                : 'hover:bg-lightGrayHover bg-[#E0E0E0]'
            "
            @click="handleCreateAccount"
            :style="{
              color: isUnderstood ? 'white' : '#828282',
              cursor: isLoading ? 'not-allowed' : 'pointer',
              pointerEvents: isLoading ? 'none' : 'auto',
            }"
            style="
              height: 100%;
              width: 100%;
              border-radius: 5px;
              font-size: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            "
          >
            <span v-if="isLoading" style="margin-right: 10px">
              <Loader customClass="w-8 h-8" />
            </span>
            <span>
              {{ isLoading ? 'Creating an Account' : 'Create an Account' }}
            </span>
            <div v-if="isUnderstood" style="margin-left: 8%">
              <img src="../assets/rightarrow.svg" />
            </div>
          </button>
        </div>
        <div
          class="iUnderstandText font-[300]"
          style="color: #828282; margin-top: 2%"
        >
          By creating an account, you agree to our
          <span style="text-decoration: underline">T&Cs</span> and have read and
          acknowledge the
          <span style="text-decoration: underline"
            >Global Privacy Statement</span
          >.
        </div>
      </div>
    </FormLayout>
  </div>
</template>

<script>
import DynamicInput from '../component/DynamicInput.vue'
import Header from '../component/Header.vue'
import Footer from '../component/Footer.vue'
import ResendEmail from '../component/ResendEmail.vue'
import Loader from '../component/Loading.vue'
import SmallFooter from '../component/SmallScreenFooter.vue'
import { createAccount,resendEmail } from '../services/user'
import FormLayout from '../component/FormLayout.vue'

export default {
  name: 'SignupPage',
  data() {
    return {
      isUnderstood: false,
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      emailErrorMsg: '',
      firstNameErrorMsg: '',
      lastNameErrorMsg: '',
      passwordErrorMsg: '',
      isEmailError: false,
      isFirstNameError: false,
      isLastNameError: false,
      isPasswordError: false,
      weakPassword: null,
      mediumPassword: null,
      strongPassword: null,
      requiredError: false,
      showResendEmail: false,
      showPassword: false,
      showResponseError: '',
      isLoading: false,
      isResponseMessage: '',
      isResendLoading: false,
      emailRegix: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
      passwordRegix:
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{10,}$/,
      authorizePage: false,
      showWarningIcon: false,
      priceId: null,
    }
  },
  components: {
    DynamicInput,
    Header,
    Footer,
    ResendEmail,
    Loader,
    SmallFooter,
    FormLayout,
  },

  methods: {
    async handleCreateAccount() {
      if (
        !this.firstName &&
        !this.lastName &&
        !this.email &&
        !this.password &&
        !this.isUnderstood
      ) {
        this.isEmailError = true
        this.emailErrorMsg = '*Required Field'
        this.isFirstNameError = true
        this.firstNameErrorMsg = '*Required Field'
        this.isLastNameError = true
        this.lastNameErrorMsg = '*Required Field'
        this.isPasswordError = true
        this.passwordErrorMsg = '*Required Field'
        this.requiredError = true
        return
      }
      if (!this.firstName) {
        this.isFirstNameError = true
        this.firstNameErrorMsg = '*Required Field'
        return
      }
      if (!this.lastName) {
        this.isLastNameError = true
        this.lastNameErrorMsg = '*Required Field'
        return
      }
      if (!this.email) {
        this.isEmailError = true
        this.emailErrorMsg = '*Required Field'
        return
      }
      if (!this.password) {
        this.isPasswordError = true
        this.passwordErrorMsg = '*Required Field'
        return
      }
      if (!this.isUnderstood) {
        this.requiredError = true
        return
      }

      if (this.email) {
        if (!this.emailRegix.test(this.email)) {
          this.isEmailError = true
          this.emailErrorMsg = 'Invalid Email'
          return
        }
      }
      if (this.password) {
        if (!this.passwordRegix.test(this.password)) {
          this.isPasswordError = true
          this.strongPassword = null
          this.showWarningIcon = true
          this.passwordErrorMsg =
            'The password must be more than 9 characters including uppercase,lowercase,numbers and special characters.'
          return
        }
      }

      this.isLoading = true
      const payload = {
        email: this.email,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
      }
      console.log('payload', payload)
      const res = await createAccount(payload)
      console.log('signup', res)
      if (res?.data?.success) {
        this.showResendEmail = true
      } else {
        if (res?.data?.message == 'User Alredy Exists!') {
          this.isEmailError = true
          this.emailErrorMsg = 'already exits'
        } else {
          this.showResponseError = res?.data?.message
          setTimeout(() => {
            this.showResponseError = ''
          }, 5000)
        }
      }
      this.isLoading = false
    },
    handlePasswordVisibilityToggle() {
      this.showPassword = !this.showPassword
    },
    async handleResendEmail() {
      this.isResendLoading = true
      const payload = {
        email: this.email,
        password: this.password,
      }
      console.log("payload",payload)
      const res = await resendEmail(payload)
      if (res?.data?.success) {
        this.isResponseMessage = res?.data?.message
      } else {
        this.isResponseMessage = res?.data?.message
      }
      setTimeout(() => {
        this.isResponseMessage = ''
      }, 5000)
      this.isResendLoading = false
    },

    calculatePasswordStrength(pass) {
      if (pass?.length > 0) {
        if (pass?.length <= 3) {
          this.weakPassword = 0
          ;(this.mediumPassword = null), (this.strongPassword = null)
        } else if (pass?.length <= 8) {
          this.weakPassword = 0
          ;(this.mediumPassword = 1), (this.strongPassword = null)
        } else if (pass?.length > 9) {
          if (this.passwordRegix.test(pass)) {
            this.weakPassword = 0
            this.mediumPassword = 1
            this.strongPassword = 2
          }
        }
      } else {
        this.weakPassword = null
        this.mediumPassword = null
        this.strongPassword = null
      }
    },
  },
  computed: {},
  mounted() {},

  watch: {
    password(newPassword) {
      this.calculatePasswordStrength(newPassword)
      this.isPasswordError = false
      this.passwordErrorMsg = ''
      this.showWarningIcon = false
    },
    email() {
      this.isEmailError = false
      this.emailErrorMsg = ''
    },
    firstName() {
      this.isFirstNameError = false
      this.firstNameErrorMsg = ''
    },
    lastName() {
      this.isLastNameError = false
      this.lastNameErrorMsg = ''
    },
    isUnderstood() {
      this.requiredError = false
    },
  },
}
</script>

<style>
/* .scroll-container {
  overflow: auto !important;
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.scroll-container::-webkit-scrollbar {
  display: none; 
} */

.join-us-container {
  /* text-align: center; */
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.user-profile {
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin-left: -30px;
  z-index: 1;
}

.user-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile:nth-child(2) {
  margin-left: -20px;
}

.user-profile:nth-child(3) {
  margin-left: -20px;
}

.join-us-text {
  font-size: 20px;
  color: #333333;
  margin-left: 20px;
  /* margin-top: 20px; */
}
</style>
