<template>
  <div
    class="w-full !h-full flex justify-between gap-3 -border-t -border-[#E2E8F0] dark:border-[#2D2D2D] !items-center"
  >
    <div>
      <span
        v-if="!hideSave"
        :class="infoBtnTitle == 'Add to favourite' ? 'block' : 'hidden'"
      >
        <ButtonComp
          :title="infoBtnTitle"
          colorType="green"
          @press="handleAddToFavourite"
        />
      </span>
    </div>
    <div class="flex items-center gap-3">
      <span v-if="!hideClose" :class="firstTitle == '' ? 'hidden' : 'block'">
        <ButtonComp :title="firstTitle" @press="closeModal" />
      </span>
      <span v-if="!hideSave && !isLoading">
        <ButtonComp
          :title="secondTitle"
          :colorType="colorType"
          :hideSave="hideSave"
          @press="handleSave"
          :isLoading="isLoading"
        />
      </span>
      <span v-if="isLoading">
        <ButtonComp
          :title="thirdTitle"
          :colorType="colorType"
          :hideSave="hideSave"
          @press="stopScan"
          :isLoading="isLoading"
        />
      </span>
    </div>
  </div>
</template>

<script>
import ButtonComp from './ButtonComp.vue'

export default {
  name: 'ModalFooter',

  props: [
    'infoBtnTitle',
    'firstTitle',
    'secondTitle',
    'thirdTitle',
    'isLoading',
    'colorType',
    'hideSave',
    'hideClose',
  ],
  components: {
    ButtonComp,
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    handleSave() {
      this.$emit('save')
    },
    stopScan(){
      this.$emit('stopScan')
    },
    handleAddToFavourite() {
      this.$emit('addToFav')
    },
  },
}
</script>
