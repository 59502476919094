<script>
import { mapGetters } from 'vuex';
import {Overlay} from '../../@trading-vue-js/src/index'
import greenArrow from '../../assets/arrows-png/green-arrow.png' 
import redArrow from '../../assets/arrows-png/red-rrow.png'
export default {
  name: 'ArrowData',
  mixins: [Overlay],
  data() {
    return {}
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters(['getArrow']),
    sett() {
      return this.$props.settings
    },
    line_width() {
      return this.sett.lineWidth || 1.5
    },
    color() {
      return this.sett.color || '#42b28a'
    }
  },
  watch:{
    getArrow(val) {
      console.log("value form macd",val);
      // this.$forceUpdate(); // Force re-render when getArrow changes
      // window.charts.resetChart()
    },
  },
  methods: {
    meta_info() {
      return { author: 'C451', version: '1.1.2' }
    },
    draw(ctx) {
      // console.log("ctx")
      ctx.lineWidth = this.line_width
      ctx.strokeStyle = this.color
      
      const layout = this.$props.layout
      const data = this.$props?.data
      // console.log("ArrowData",data?.length)
      ctx.lineWidth = 0.75
      this.$props.data.forEach((p, i) => {
        ctx.beginPath()
        let x = layout.t2screen(p[0]) // x - Mapping
        let y = layout.c_magnet(p[0]).h - 20  // y - Mapping

        let isUpward = p[1]  === 1

        /* code for writing a right arrow */

        if(isUpward){
          y += 50;
        }else{
          y -= 50
        }

        if (isUpward) {
        y += 50; // Assuming arrow image height is 20 pixels
        } else {
        y += 20; // Assuming arrow image height is 20 pixels
        }

        let arrowImgSrc;
        if(this.getArrow){
          arrowImgSrc = isUpward ?  greenArrow : redArrow;
        }
        let arrowImg = new Image();
        arrowImg.src = arrowImgSrc;

        ctx.drawImage(arrowImg, x, y)
''
      
        if (this.hint) this.hint.draw(ctx)
      })
      
    },
    draw_label(ctx, x, y, p, fillStyle) {
      ctx.fillStyle = fillStyle
      ctx.textAlign = 'center'
      // ctx.font = this.new_font
      ctx.fillText(p, x, y)
    },
    use_for() { return ['ArrowData'] },
    
  },

  // <!-- this is another methods -->
//   methods: {
//     draw(ctx) {
//         const layout = this.$props.layout;
//         const data = this.$props.data;

//         this.$props.data.forEach((p, i) => {
//             const x = layout.t2screen(p[0]); // x - Mapping
//             const y = layout.c_magnet(p[0]).h - 20; // y - Mapping
//             const isUpward = p[1] === 1;
//             const circleColor = isUpward ? '#34c745' : '#ea363d';

//             ctx.beginPath();
//             ctx.arc(x, y, 5, 0, Math.PI * 2); // Draw a circle
//             ctx.fillStyle = circleColor;
//             ctx.fill();
//         });
//     },
// },

  mounted() {
    //console.log("Spline Mounted")
  }

}
</script>