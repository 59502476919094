

export default class Crosshair {

    constructor(comp) {

        this.comp = comp
        this.$p = comp.$props
        this.data = this.$p.sub
        this._visible = false
        this.locked = false
        this.layout = this.$p.layout
        this.enableCrosshair = this.$p.enableCrosshair

    }

    draw(ctx) {
        // Update reference to the grid
        this.layout = this.$p.layout

        const cursor = this.comp.$props.cursor
        const candleData = this.$p.cursor.values[0]?.ohlcv;

        if (!this.visible && cursor.mode === 'explore') return

        this.x = this.$p.cursor.x
        this.y = this.$p.cursor.y

        const isClosingPriceAbove = candleData[4] >  candleData[1];
        
        let yCoord;
    
        if (isClosingPriceAbove) {
            const candleLow = candleData[4]; // High price of the candle
            yCoord = this.$p.layout.$2screen(candleLow); // Convert low price to screen coordinates
        } 

        if(!isClosingPriceAbove) {
            const candleHigh = candleData[4]; // low price of the candle
            yCoord = this.$p.layout.$2screen(candleHigh); // Convert high price to screen coordinates
        }
    
        ctx.save()
        ctx.strokeStyle = this.$p.colors.cross
        ctx.beginPath()
        ctx.setLineDash([3])

        // H
        if(this.comp?.common?.magnet && this.$p.cursor.grid_id === this.layout.id){
            ctx.moveTo(0,yCoord);
            ctx.lineTo(this.layout.width - 0.5, yCoord);
        }
        else if (this.$p.cursor.grid_id === this.layout.id) {
            ctx.moveTo(0, this.y)
            ctx.lineTo(this.layout.width - 0.5, this.y)
        }

        // V
        ctx.moveTo(this.x, 0)
        ctx.lineTo(this.x, this.layout.height)

        if(this.enableCrosshair){
            ctx.stroke()
        }
        
        ctx.restore()

    }

    hide() {
        this.visible = false
        this.x = undefined
        this.y = undefined
    }

    get visible() {
        return this._visible
    }

    set visible(val) {
        this._visible = val
    }

}
