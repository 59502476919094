<script>
import {Overlay, Tool} from '../../@trading-vue-js/src/index'
import Pin from './primitives/pin.js'
import FixedPinX from './primitives/fixedPinX.js'
import Seg from './primitives/seg.js'
import Ray from './primitives/ray.js'
import {mapActions, mapGetters} from 'vuex'
import ToolSavingMixins from 'Tools/ToolSavingMixins'
import {chartEvents} from "../../chartEvents";

const HIGH = 2
const LOW = 3

export default {
  name: 'TrailingStopTool',
  mixins: [Overlay, Tool, ToolSavingMixins],

  methods: {
    meta_info() {
      return {author: 'LakshanPerera', version: '1.0.0'}
    },
    tool() {
      return {
        // Descriptor for the tool
        group: 'Measurements',
        type: 'PL',
        hint: 'Price Range',
        data: [], // Default data
        settings: {
          price: true, time: true, showTrailingStop: false,
          isOnChart: false,
          isDialogBoxOpened: false,
          entryPrice: 0,
          stopPrice: 0,
          trailingType: '',
          stepPrice: 0.01,
          positionSize: 1000,
          riskValueInCash: 500,
          riskValueInPct: 10,
          atrPeriod: 14,
          atrMultiplier: 5,
        }, // Default settings
      }
    },
    // Called after overlay mounted
    init() {
      let uuidRef = this.$props?.settings?.$uuid;
      // console.log("uuidRef",uuidRef)
      this.localMap =  this.keys.map
      delete this.keys.map.Backspace
      delete this.keys.map.Delete
      // this.$emit('object-selected', false)

      //--- dispatch action to show the dialog box
      
      let text = JSON.stringify(this.$props?.settings);
      let modalSettings = JSON.parse(text)
      
      this.pins.push(
          new Pin(this, 'p1', {
            hidden: this.shift,
          })
      )
      
      // Second one is following mouse until it clicks
      
      let mousePosition = this.mouse.y$;
      let stopPrice;
      let fixedTime = this.pins[0].t; 
      if (mousePosition) {
        
        let updatedSettings = {
          entryPrice: parseFloat(mousePosition.toFixed(3)),
          isDialogBoxOpened: true,
          isOnChart: true
        }
        stopPrice=parseFloat((
            updatedSettings.entryPrice -
            updatedSettings.entryPrice * 0.01
        ).toFixed(3))
        updatedSettings.stopPrice = stopPrice
        this.$emit("change-settings", updatedSettings);
        modalSettings = {...modalSettings, ...updatedSettings}
        chartEvents.$emit('updateTrailingStopModal', modalSettings)
        
      }else{
        stopPrice = parseFloat(modalSettings.p2?.[1]);
        
      }

      let fixedX = stopPrice;
      
      this.pins.push(
          new FixedPinX(this, 'p2', {
            state: 'settled',
            hidden: this.shift,
            isFixedPin: true,
            fixedX: fixedX,
            fixedTime: fixedTime,
          })
      )

      let stepValue = parseFloat(modalSettings.stepPrice);
      
      // console.log("TrailingStopTool", text,mousePosition,stopPrice)
      this.stepValue = stepValue


      // First pin is settled at the mouse position
      this.pins[0].on('settled', () => {
        console.log("point 0 settle")
        // this.$emit('change-settings', {
        //   entryPrice: this.pins[0].y$.toFixed(3)
        // })
        let props = {
          stopPrice: parseFloat(this.stopPrice),
          stepPrice: this.stepPrice,
          entryPrice: parseFloat(this.pins[0].y$.toFixed(3))
        }
        this.updatePointsData(props)
      })
      this.pins[1].on('settled', () => {
        console.log("point 1 settle")
        let props = {
          stopPrice: parseFloat(this.pins[1].y$.toFixed(3)),
          stepPrice: this.stepPrice,
          entryPrice: parseFloat(this.entryPrice)
        }
        this.updatePointsData(props)
        // this.$emit('change-settings', {          
        //   stopPrice: this.pins[1].y$.toFixed(3)
        // })        
      })
      this.pins[1].y$ = stopPrice
      this.pins[1].y = this.$props.layout.$2screen(this.pins[1].y$)
      this.pins[1].x = fixedX
      this.pins[1].t = fixedTime

      this.pins[1].update_settings('p2', this.$props.cursor.t, this.pins[1].y$)
      // end drawing mode
      this.set_state('finished')
      this.$emit('drawing-mode-off')
      // Deselect the tool in shiftMode
      /*if (this.shift) {
        this._$emit('custom-event', {
          event: 'object-selected',
          args: [],
        })
      }*/

      this.tGap =
          this.$props.layout.candles[1].raw[0] -
          this.$props.layout.candles[0].raw[0]
      // this.pins[0].update()
      // this.pins[1].update()
      this.registerMouseEvent(this)
    },
    draw(ctx) {
      if (!this.p1 || !this.p2) return
      const layout = this.$props.layout
      ctx.lineWidth = this.line_width
      ctx.strokeStyle = this.color
      // Background
      ctx.globalAlpha = 1
      // let x1 = layout.t2screen(this.p1[0]);
      // let y1 = layout.$2screen(this.p1[1]);
      // let x2 = layout.t2screen(this.p2[0]);
      // let y2 = layout.$2screen(this.p2[1]);

      // ctx.fillStyle = "#23a776";
      // ctx.globalAlpha = 0.1;

      // ctx.fillRect(x1, y1, x2 - x1, y2 - y1);

      ctx.beginPath()
      ctx.strokeStyle = '#23a776'
      this.pins[1].t = this.p1[0]
      new Ray(this, ctx).draw(this.p1, [this.p1[0] + this.tGap, this.p1[1]])
      ctx.stroke()

      // ctx.beginPath();
      // ctx.strokeStyle = "#e54150";
      // new Ray(this, ctx).draw(
      //   [this.p1[0], this.p2[1]],
      //   [this.p1[0] + this.tGap, this.p2[1]]
      // );
      // ctx.stroke();

      ctx.globalAlpha = 0
      new Seg(this, ctx).draw(this.p1, this.p2)
      ctx.stroke()
      ctx.globalAlpha = 1

      ctx.beginPath()
      ctx.strokeStyle = 'red'
      const entryPrice = this.p1[1]
      let lastHighestPoint = 0,
          lastSLPosition = this.p2[1],
          lastPosition = null
      let first = true,
          slChanged = false
      for (const candle of this.$props.layout.candles) {
        if (candle.raw[0] < this.p1[0]) continue

        if (first) {
          ctx.moveTo(candle.x, layout.$2screen(lastSLPosition))
          ctx.lineTo(candle.x + candle.w, layout.$2screen(lastSLPosition))
          lastSLPosition += this.stepValue
          first = false
          continue
        }

        if (candle.raw[HIGH] > lastHighestPoint) {
          lastHighestPoint = candle.raw[HIGH]
          lastSLPosition += this.stepValue
        }
        if (!slChanged && lastSLPosition > entryPrice && lastPosition) {
          ctx.stroke()
          ctx.beginPath()
          ctx.strokeStyle = 'green'
          ctx.moveTo(lastPosition.x, lastPosition.y)
        }
        ctx.lineTo(candle.x, layout.$2screen(lastSLPosition))
        ctx.lineTo(candle.x + candle.w, layout.$2screen(lastSLPosition))
        lastPosition = {
          x: candle.x + candle.w,
          y: layout.$2screen(lastSLPosition),
        }

        //--- check for SL hits
        if (candle.raw[LOW] < lastSLPosition) break
      }
      ctx.stroke()

      this.render_pins(ctx)
    },
    use_for() {
      return ['TrailingStopTool']
    },
    data_colors() {
      return [this.color]
    },
    updatePointsData(props){
      
        
        let {stopPrice,stepPrice,entryPrice} = props
        stepPrice = parseFloat(stepPrice)
        stopPrice = parseFloat(stopPrice)
        entryPrice = parseFloat(entryPrice)
        console.log("UpdateTrailingStopSettings", {stopPrice,stepPrice,entryPrice})
        this.stepValue = stepPrice
        if (!this.pins[0]) return
        this.pins[0].y$ = entryPrice
        this.pins[0].y = this.$props.layout.$2screen(this.pins[0].y$)
        let timeStamp = this.$props.layout.ti_map.i2t(this.p1[0])


        if (!this.pins[1] || !stopPrice) return
        this.pins[1].y$ = stopPrice
        this.pins[1].y = this.$props.layout.$2screen(stopPrice)
        // this.pins[1].update_settings(
        //     'p2',
        //     this.p1[0],
        //     parseFloat(props.stopPrice)
        // )
        this.$emit('change-settings', {
          p1: [this.p1[0], this.pins[0].y$, timeStamp],
          p2: [this.p1[0], stopPrice, timeStamp],
          stepPrice: stepPrice,
          stopPrice: stopPrice,
          entryPrice: entryPrice,
        })
        this.saveSettings(this)
      
    }
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters([
      'themePrimaryColor',
      'themeSecondaryColor',
      'themeFontColor',
    ]),
    sett() {
      return this.$props.settings
    },
    p1() {
      return this.$props.settings.p1
    },
    p2() {
      return this.$props.settings.p2
    },
    line_width() {
      return this.sett.lineWidth || 2
    },
    color() {
      return this.sett.color || this.$props.colors.cross
    },
    back_color() {
      return this.sett.backColor || '#9b9ba316'
    },
    value_back() {
      return this.sett.valueBack || '#9b9ba316'
    },
    value_color() {
      return this.sett.valueColor || this.$props.colors.text
    },
    isDialogBoxOpened() {
      return this.sett.isDialogBoxOpened || false
    },
    stopPrice() {
      return this.sett.stopPrice || 0
    },
    entryPrice() {
      return this.sett.entryPrice || 0
    },
    trailingType() {
      return this.sett.trailingType || ''
    },
    stepPrice() {
      return this.sett.stepPrice || 0.01
    },
    positionSize() {
      return this.sett.positionSize || 1000
    },
    riskValueInCash() {
      return this.sett.riskValueInCash || 500
    },
    riskValueInPct() {
      return this.sett.riskValueInPct || 10
    },
    atrPeriod() {
      return this.sett.atrPeriod || 14
    },
    atrMultiplier() {
      return this.sett.atrMultiplier || 5
    },
    prec() {
      return this.sett.precision || 2
    },
    new_font() {
      return '12px ' + this.$props.font.split('px').pop()
    },
    price() {
      return 'price' in this.sett ? this.sett.price : true
    },
    time() {
      return 'time' in this.sett ? this.sett.time : false
    },
    shift() {
      return this.sett.shiftMode
    },
  },
  data() {
    return {
      localMap:null
    }
  },
  mounted() {
    let event = 'UpdateTrailingStopSettings' + this.sett?.$uuid;
    // let event = 'UpdateTrailingStopSettings';
    chartEvents.$on(event, this.updatePointsData)
  },
  beforeDestroy() {
    let event = 'UpdateTrailingStopSettings' + this.sett?.$uuid;
    // let event = 'UpdateTrailingStopSettings';
    chartEvents.$off(event)
  },
  created() {
    this.updateSettingsState(this)
  }
}
</script>
