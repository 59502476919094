<script>
// Renedrer for ohlcbar + volume (optional)
// It can be used as the main chart or an indicator
import XOhlcBar from './primitives/xohlcbar.js'
import XVolbar from './primitives/xvolbar.js'
import { Overlay, layout_cnv } from '../../@trading-vue-js/src/index'
import priceline from './priceline.js'
import {mapGetters} from "vuex";
// import Volbar from "@/overlays/chartTypes/primitives/volbar";
export default {
  name: 'UpAndDown',
  mixins: [Overlay,priceline],
  methods: {
    meta_info() {
      return {
        author: 'LakshanPerera',
        version: '1.3.0',
        desc: 'Bar Chart'
      }
    },
    init() {
 this.priceInit()
//this.price = new Price(this) // TODO:
    },
    draw(ctx) {
      //console.log("XOhlcBar.draw() called. this.$props.data.length = " + this.$props.data.length)
      // render as main chart:
      if (this.$props.sub === this.$props.data) {
        var cnv = {
          ohlcbars: this.$props.layout.candles,
          volume: this.$props.layout.volume
        }
        // Else, as offchart / onchart indicator:
      } else {
        cnv = layout_cnv(this)
      }
      

      for (let index = 1; index < cnv.ohlcbars.length; index++) {
        const prevUp = cnv.ohlcbars[Math.max(index - 1, 0)]['raw'][2]
        const prevDn = cnv.ohlcbars[Math.max(index - 1, 0)]['raw'][3]

        const currClose = cnv.ohlcbars[index]['raw'][4]

        new XOhlcBar(
          this,
          ctx,
          cnv.ohlcbars[index],
          currClose > prevUp
            ? this.upBarColor
            : currClose < prevDn
            ? this.downBarColor
            : '#3e3e3f'
        )
      }
      if (this.price_line) this.price.draw(ctx);
    },
    use_for() {
      return ['UpAndDown']
    },
    // When added as offchart overlay
    y_range() {
      return [
        Math.max(...this.$props.sub.map(x => x[2])),
        Math.min(...this.$props.sub.map(x => x[3]))
      ]
    }
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters(['getGeneralUserSettings']),
    sett() {
      return this.$props.settings
    },
    show_volume() {
      return false
    },
    // price_line() {
    //   return 'priceLine' in this.sett ? this.sett.priceLine : true
    // },
    colorCandleUp() {
      return this.sett.colorCandleUp || this.$props.colors.candleUp;
    },
    colorCandleDw() {
      return this.sett.colorCandleDw || this.$props.colors.candleDw;
    },
    upVolBarColor() {
      return this.sett.upBarColor || '#23a77655'
    },
    downVolBarColor() {
      return this.sett.downBarColor || '#e5415055'
    },
    volBarWidth() {
      return this.sett.barWidth || 2
    },
    upBarColor() {
      let upBarColorStr = '#23a776'
      // if(this.getGeneralUserSettings){
      //   if(this.getGeneralUserSettings.chart['upColor'] && this.getGeneralUserSettings.chart['upColor']['value']) {
      //     upBarColorStr = this.getGeneralUserSettings.chart['upColor']['value']
      //   }
      // }
      return upBarColorStr
    },
    downBarColor() {
      let downBarColorStr = "#e54150";
      // console.log("Bar Change",this.getGeneralUserSettings.chart['barChange'] && this.getGeneralUserSettings.chart['barChange']['value'])
      // if(this.getGeneralUserSettings){
      //     if(this.getGeneralUserSettings.chart['downColor'] && this.getGeneralUserSettings.chart['downColor']['value']){
      //     downBarColorStr =  this.getGeneralUserSettings.chart['downColor']['value']
      //     }
      // }
      return downBarColorStr
    },
    barWidth() {
      return this.sett.barWidth || 1
    },
    hlcOnly() {
      return this.sett.hlcOnly || false
    },
    lineWidth() {
      return this.sett.lineWidth || 1
    },
    colorVolUp() {
      return this.sett.colorVolUp || this.$props.colors.volUp;
    },
    colorVolDw() {
      return this.sett.colorVolDw || this.$props.colors.volDw;
    },
    isArrow() {
      return "isArrow" in this.sett ? this.sett.isArrow : false;
    },
    decimalPlace() {
      // return this.sett?.decimalPlace || 2;
      return "decimalPlace" in this.sett ? this.sett.decimalPlace : 2;
    },
  },
  data() {
    return { 
      // price: {}
       }
  },
  watch: {
    isArrow: {
      handler: function (value) {
        // console.log("candles isArrows", value, this.price);
        this.price = new Price(this);
        this.HighestCandle = new HighestPriceLine(this);
        this.LowestCandle = new LowestPriceLine(this);
      },
    },
  },
}
</script>
