<template>
  <div
    v-if="showWelcome"
    style="
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 40px 40px 40px 80px;
    "
    class="page-background mobileMainContainer"
  >
    <div class="w-full h-full">
      <Welcome :token="currentToken" />
    </div>
  </div>
  <div v-else class="w-full h-full bg-slate-200">
    <FormLayout>
      <div class="w-full h-[80%] -bg-slate-400 flex flex-col justify-center">
        <div
          class="smallResendEmail"
          style="
            height: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
        >
          <div
            class="formSevenDayTrial"
            style="
              border: 1px solid #f20e17;
              font-size: 18px;
              color: #f20e17;
              width: fit-content;
              border-radius: 5px;
              padding: 5px 15px 5px 15px;
              font-weight: 500;
              display: none;
              margin: 20px 0px 20px 0px;
            "
          >
            3-MONTHS FREE TRIAL
          </div>
          <div v-if="isInvalidToken">
            <span class="largeText" style="font-size: 44px"
              >Oops, It seems your link is
              <span class="text-appBlue">Expired</span></span
            >
          </div>

          <span v-if="isVerifiedToken">
            <div>
              <span class="largeText" style="font-size: 44px"
                >Your email is <span class="text-appBlue">verified</span></span
              >
            </div>
          </span>
        </div>
      </div>
    </FormLayout>
  </div>
</template>

<script>
// import {loginUser, registerUser} from '../services/user'

import DynamicInput from '../component/DynamicInput.vue'
import ResendEmail from '../component/ResendEmail.vue'
import Loader from '../component/Loading.vue'
import { verifyToken } from '../services/user'
import Welcome from './Welcome.vue'
import FormLayout from '../component/FormLayout.vue'

export default {
  name: 'EmailVerify',
  components: {
    DynamicInput,
    ResendEmail,
    Loader,
    Welcome,
    FormLayout,
  },
  data() {
    return {
      isVerifiedToken: false,
      isLoading: false,
      isInvalidToken: false,
      showWelcome: false,
      currentToken: null,
    }
  },
  methods: {
    async verfifyToken() {
      let token = this.$route.query.Token
      let payload = {
        token: token,
      }
      if (token) {
        const res = await verifyToken(payload)
        console.log("verify",res)
        if (res?.data?.success) {
          this.currentToken = res?.data?.payload?.token
          this.isVerifiedToken = true
        } else {
          this.isInvalidToken = true
        }
      } else {
        this.isInvalidToken = true
      }
    },
  },
  computed: {},
  watch: {
    isVerifiedToken(val) {
      if (val) {
        setTimeout(() => {
          this.isVerifiedToken = false
          this.showWelcome = true
        }, 2000)
      }
    },
  },

  mounted() {
    this.verfifyToken()
  },
}
</script>

<style>
.join-us-container {
  /* text-align: center; */
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.user-profile {
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin-left: -30px;
  z-index: 1;
}

.user-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile:nth-child(2) {
  margin-left: -20px;
}

.user-profile:nth-child(3) {
  margin-left: -20px;
}

.join-us-text {
  font-size: 20px;
  color: #333333;
  margin-left: 20px;
  /* margin-top: 20px; */
}

.graySelect {
  outline: none;
  padding-left: 16px !important;
  padding-right: 20px !important;
  font-size: 16px;
  border-radius: 6px;
  appearance: none; /* Remove default appearance */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  /* background: url('../assets/dropdownArrow.svg') no-repeat;  */
  background-position: right 18px center;
  padding-right: 20px; /* Add some right padding to avoid overlapping with the arrow */
}
.redSelect {
  outline: none;
  padding-left: 16px !important;
  padding-right: 20px !important;
  font-size: 16px;
  border-radius: 6px;
  appearance: none; /* Remove default appearance */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  /* background: url('../assets/reddropdownArrow.svg') no-repeat; */
  background-position: right 18px center;
  padding-right: 20px; /* Add some right padding to avoid overlapping with the arrow */
}
.graySelect:hover {
  border: 1px solid #bdbdbd !important;
}
.redSelect:hover {
  border: 1px solid #bdbdbd !important;
}
</style>
