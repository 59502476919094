<!-- BaseModal.vue -->
<template>
  <div
    class="relative flex flex-col xxs:w-[750px] lg:w-[55vw] 2lg:w-[60vw] h-[80vh]"
  >
    <!-- Header updated -->

    <!-- <div
      v-if="isLoading"
      class="absolute backdrop-blur-sm w-full h-full flex items-center justify-center"
      style="z-index: 9999"
    >
      <Loader></Loader>
    </div> -->

    <!-- <div
      class="w-full h-[6%] flex flex-row justify-between items-center xxs:px-2 md:px-6 py-3 border-b dark:border-[#2D2D2D]"
    > -->

    <div
      class="w-full h-[6rem] px-4 flex flex-row justify-between items-center space-x-3"
    >
      <div class="w-auto flex flex-row space-x-3">
        <div class="text-[#6D6D6D] text-[18px]">Scanners</div>
        <div>
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289L7.34315 0.928932C6.95262 0.538408 6.31946 0.538408 5.92893 0.928932C5.53841 1.31946 5.53841 1.95262 5.92893 2.34315L11.5858 8L5.92893 13.6569C5.53841 14.0474 5.53841 14.6805 5.92893 15.0711C6.31946 15.4616 6.95262 15.4616 7.34315 15.0711L13.7071 8.70711ZM0 9H13V7H0V9Z"
              fill="#110024"
            />
          </svg>
        </div>
        <div
          class="text-[#000000] dark:text-[#F6F6F6] font-sans font-semibold text-[14px]"
        >
          {{ selectedScanner.name }}
        </div>
      </div>

      <span
        @click="handleClose"
        class="cursor-pointer p-2 rounded-md hover:bg-[#F0F3FA] dark:bg-[#FFFFFF1A] dark:hover:bg-[#2D2D2D]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6 dark:text-white"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </span>
    </div>

    <!-- icon dark:bg-[#FFFFFF1A] -->
    <!-- icon dark:text-[#B0B0B0] -->
    <!-- btn-bg dark:hover:bg-[#232323] -->
    <!-- btn-bg-light dark:hover:bg-[#F6F6F6] -->
    <!-- txt dark:text-[#F6F6F6] -->
    <!-- dark:!border-[#2D2D2D] -->
    <!-- brandicon dark:bg-[#676868] -->

    <!-- Search Bar -->

    <div
      class="w-full h-[8%] border-t border-b border-[#D1D1D1] dark:bg-[#FFFFFF1A] px-5 items-center flex space-x-2 dark:border-[#232323]"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          fill="none"
        >
          <path
            stroke="#a3a6af"
            d="M12.4 12.5a7 7 0 1 0-4.9 2 7 7 0 0 0 4.9-2zm0 0l5.101 5"
          ></path>
        </svg>
      </span>
      <span class="w-full">
        <input
          type="text"
          v-model="searchTerm"
          placeholder="Search lists"
          class="bg-transparent text-[#181818] border-none dark:text-[#BDBDBD] placeholder:text-[#949cb2] placeholder:dark:text-[#BDBDBD] -font-[600] placeholder:text-[16px] text-[16px] focus:ring-0 ring-0 focus:outline-none w-full"
        />
      </span>
    </div>

    <!-- Mian Content -->
    <div class="w-full h-[72%] -bg-slate-400 flex">
      <!-- Left Bar  -->
      <div
        class="xxs:w-[150px] md:w-[25%] -bg-slate-300 !h-full mt-1 flex flex-col -rounded-[10px] border-r border-gray dark:!border-[#2D2D2D]"
      >
        <!-- <div
          class="py-[10px] xxs:px-3 md:px-4 flex flex-row justify-start items-center bg-appBlue xxs:text-[12px] text-[#ffffff] dark:text-[#F6F6F6] rounded-[12px] mt-2"
        >
          Scanner Types
        </div> -->

        <!-- Scanner Types -->

        <div class="flex-1 -bg-slate-600 py-3 overflow-y-auto no-scrollbar">
          <div
            @click="handleScanner(item)"
            v-for="(item, index) in scannerData"
            :key="index"
            :class="
              selectedScanner.name === item.name  
                ? '!bg-[#E6EFF5] -!border-appBlue text-white'
                : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] cursor-pointer !border-[transparent]'
            "
            class="flex cursor-pointer flex-row justify-start items-center hover:bg-[#f6f6f6] xxs:px-2 md:px-6 py-[12px] xxs:space-x-1 md:space-x-3"
          >
            <div
              :class="
                selectedScanner.name === item.name
                  ? '!bg-appBlue !text-white'
                  : 'bg-white text-black'
              "
              class="xxs:w-[18px] xxs:h-[18px] md:w-[30px] md:h-[30px] flex justify-center items-center border-[1px] border-black rounded-full dark:!bg-[#676868] dark:!text-[#F6F6F6] xxs:text-[10px] -ring-[1.1px] ring-[#7c7c7c] text-[#AE0000]"
            >
              {{ item.id }}
            </div>
            <div
              class="xxs:text-[9px] md:text-[12px] font-medium font-sans text-[#6B6B6B] dark:!text-[#6C707A]"
            >
              {{ item.name }}
            </div>
          </div>
        </div>

        <!-- Favourites -->

        <div class="!w-full flex flex-col -bg-slate-500">
          <div
            class="w-full py-[10px] px-4 flex flex-row justify-start items-center -bg-appBlue text-[#001A2C] font-bold text-[18px] -rounded-[12px] mt-2"
          >
            My Favourite(s)
          </div>

          <!-- <div
            class="flex cursor-pointer flex-row mt-2 border border-gray-400 dark:!border-[#2D2D2D] dark:hover:bg-[#2D2D2D] dark:bg-[#FFFFFF1A] -border !border-transparent justify-start items-center hover:bg-[#f6f6f6] xxs:px-2 md:px-3 py-[10px] rounded-[10px] xxs:space-x-1 md:space-x-3"
          >
            <div
              class="xxs:w-[20px] xxs:h-[20px] md:w-[30px] md:h-[30px] flex justify-center items-center rounded-full -text-white dark:!text-[#F6F6F6] ring-[1.1px] ring-[#7c7c7c] text-[#AE0000]"
            >
              <img
                :src="require('../../src/assets/scanner_svgs/star.svg')"
                alt=""
              />
            </div>
            <div class="dark:!text-[#F6F6F6] xxs:text-[10px] -md:text-[12px]">
              Bollinger Squeeze
            </div>
          </div> -->
          <div
            class="-border cursor-default w-full h-[4rem] -bg-slate-300 space-y-1 overflow-y-auto no-scrollbar"
          >
            <div
              @click="handleScanner(item)"
              v-for="(item, index) in favlist"
              :key="index"
              class="h-[50px] flex flex-row justify-between items-center hover:bg-[#f6f6f6] xxs:px-2 md:px-6 -rounded-[10px] xxs:space-x-1 md:space-x-3"
            >
              <div class="w-auto flex items-center gap-3">
                <div
                  :class="
                    selectedScanner.name === item.name
                      ? '!bg-appBlue !text-white'
                      : 'bg-white text-black'
                  "
                  class="xxs:w-[18px] xxs:h-[18px] md:w-[30px] md:h-[30px] flex justify-center items-center border-[1px] border-black rounded-full dark:!bg-[#676868] dark:!text-[#F6F6F6] xxs:text-[10px] -ring-[1.1px] ring-[#7c7c7c] text-[#AE0000]"
                >
                  {{ item.id }}
                </div>
                <div
                  class="xxs:text-[9px] md:text-[12px] font-medium font-sans text-[#6B6B6B] dark:!text-[#6C707A]"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="w-auto flex gap-2">
                <div
                  class="w-[20px] h-[20px] cursor-pointer -bg-white flex justify-center items-center"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_6176_225557)">
                      <path
                        d="M10.64 3.20039L5.86995 7.97037C5.39495 8.44537 3.98494 8.66537 3.66994 8.35037C3.35494 8.03537 3.56994 6.62537 4.04494 6.15037L8.81995 1.37538C8.9377 1.24691 9.08025 1.14364 9.23905 1.07179C9.3978 0.999937 9.5695 0.960987 9.74375 0.957317C9.91795 0.953652 10.0912 0.985322 10.2528 1.05043C10.4145 1.11553 10.5613 1.21273 10.6843 1.33613C10.8074 1.45953 10.9042 1.60658 10.9688 1.76841C11.0335 1.93025 11.0647 2.1035 11.0606 2.27772C11.0564 2.45195 11.017 2.62354 10.9447 2.78212C10.8724 2.94069 10.7688 3.08299 10.64 3.20039Z"
                        stroke="#B2B2B2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.5 2H3C2.46956 2 1.96089 2.21071 1.58581 2.58578C1.21074 2.96086 1 3.46956 1 4V9C1 9.53045 1.21074 10.0391 1.58581 10.4142C1.96089 10.7893 2.46956 11 3 11H8.5C9.605 11 10 10.1 10 9V6.5"
                        stroke="#B2B2B2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6176_225557">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div
                  class="w-[20px] h-[20px] cursor-pointer -bg-white flex justify-center items-center"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.01754 12.2176C4.80665 12.4284 4.80665 12.7703 5.01754 12.9812C5.22842 13.1921 5.57033 13.1921 5.78121 12.9812L5.01754 12.2176ZM9.38119 9.38119C9.59208 9.1703 9.59208 8.82844 9.38119 8.61756C9.1703 8.40667 8.82844 8.40667 8.61756 8.61756L9.38119 9.38119ZM8.61756 8.61756C8.40667 8.82844 8.40667 9.1703 8.61756 9.38119C8.82844 9.59208 9.1703 9.59208 9.38119 9.38119L8.61756 8.61756ZM12.9812 5.78121C13.1921 5.57033 13.1921 5.22842 12.9812 5.01754C12.7703 4.80665 12.4284 4.80665 12.2176 5.01754L12.9812 5.78121ZM9.38119 8.61756C9.1703 8.40667 8.82844 8.40667 8.61756 8.61756C8.40667 8.82844 8.40667 9.1703 8.61756 9.38119L9.38119 8.61756ZM12.2176 12.9812C12.4284 13.1921 12.7703 13.1921 12.9812 12.9812C13.1921 12.7703 13.1921 12.4284 12.9812 12.2176L12.2176 12.9812ZM8.61756 9.38119C8.82844 9.59208 9.1703 9.59208 9.38119 9.38119C9.59208 9.1703 9.59208 8.82844 9.38119 8.61756L8.61756 9.38119ZM5.78121 5.01754C5.57033 4.80665 5.22842 4.80665 5.01754 5.01754C4.80665 5.22842 4.80665 5.57033 5.01754 5.78121L5.78121 5.01754ZM5.78121 12.9812L9.38119 9.38119L8.61756 8.61756L5.01754 12.2176L5.78121 12.9812ZM9.38119 9.38119L12.9812 5.78121L12.2176 5.01754L8.61756 8.61756L9.38119 9.38119ZM8.61756 9.38119L12.2176 12.9812L12.9812 12.2176L9.38119 8.61756L8.61756 9.38119ZM9.38119 8.61756L5.78121 5.01754L5.01754 5.78121L8.61756 9.38119L9.38119 8.61756Z"
                      fill="#B2B2B2"
                    />
                  </svg>
                </div>
                <div
                  class="w-[25px] h-[25px] cursor-pointer -bg-white flex justify-center items-center"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.99967 14.6673C4.85697 14.6673 3.28563 14.6673 2.30932 13.691C1.33301 12.7147 1.33301 11.1433 1.33301 8.00065C1.33301 4.85795 1.33301 3.2866 2.30932 2.3103C3.28563 1.33398 4.85697 1.33398 7.99967 1.33398C11.1423 1.33398 12.7137 1.33398 13.69 2.3103C14.6663 3.2866 14.6663 4.85795 14.6663 8.00065C14.6663 11.1433 14.6663 12.7147 13.69 13.691C12.7137 14.6673 11.1423 14.6673 7.99967 14.6673ZM7.99967 11.834C8.27581 11.834 8.49967 11.6101 8.49967 11.334V7.33398C8.49967 7.05785 8.27581 6.83398 7.99967 6.83398C7.72354 6.83398 7.49967 7.05785 7.49967 7.33398V11.334C7.49967 11.6101 7.72354 11.834 7.99967 11.834ZM7.99967 4.66732C8.36787 4.66732 8.66634 4.9658 8.66634 5.33398C8.66634 5.70217 8.36787 6.00065 7.99967 6.00065C7.63147 6.00065 7.33301 5.70217 7.33301 5.33398C7.33301 4.9658 7.63147 4.66732 7.99967 4.66732Z"
                      fill="#BCBCBC"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Right Bar  -->
      <div
        class="w-[72%] relative h-full px-8 py-8 rounded-[10px] overflow-y-auto no-scrollbar space-y-4"
      >
        <div class="flex flex-col">
          <div class="flex flex-row gap-[8px]">
            <div
              @click="handleButton(button)"
              :class="
                selectedButton === button.name
                  ? 'bg-[#001A2C] text-white'
                  : 'bg-[#E6EFF5] text-[#3d3d3d]'
              "
              class="px-[14px] py-[8px] rounded-[22px] cursor-pointer"
              v-for="(button, index) in selectedScanner.lists"
              :key="index"
            >
              {{ button.name }}
            </div>
          </div>
          <div class="w-full h-[5rem] flex justify-between items-center">
            <div>1 item selected.</div>
            <div v-if="selectedButton">
              <Minimize
                @clearAllEmit="clearAll(selectedlist, selectedlist.id)"
                @selectAllEmit="selectAll(selectedlist, selectedlist.id)"
                Select="Select All"
                Clear="Clear All"
              />
            </div>
          </div>
        </div>

        <div v-for="(list, i) in selectedScanner.lists" :key="i" class="w-full">
          <!-- <div class="h-fit" v-if="selectedButton ===  list.name">
            <Minimize
              @clearAllEmit="clearAll(list, i + 1)"
              @selectAllEmit="selectAll(list, i + 1)"
              @hide-Show-Svg="hideShowSvg(list)"
              :isMinimizeProp="list.isToggle"
              rounded="rounded-[8px]"
              :title="list.name"
              Select="Select All"
              Clear="Clear All"
            />
          </div> -->

          <div
            class="w-full h-full"
            v-if="list.name == 'Scan Type' && selectedButton == list.name"
          >
            <ScanType @selectedScannnerName="selectedScannner" @index-changed="selectedIndex" />
          </div>

          <!-- Charting Patterns -->

          <div
            v-if="list.isToggle"
            class="w-full h-full flex flex-row gap-4 px-2 flex-wrap"
          >

            <div
              v-if="
                list.name === 'Charting Patterns' && selectedButton == list.name
              "
              @click="selectedScannerHandler(data, list.id)"
              v-for="(data, ind) in list.data"
              :key="ind"
              :class="
                data.isSelected
                  ? '!bg-[#E6EFF5] -!border-appBlue'
                  : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
              "
              class="2lg:!w-[18rem] -bg-slate-600 xxs:w-full h-[3.5rem] -rounded-[8px] border dark:!text-[#6C707A] flex !shrink-0 justift-start items-center cursor-pointer flex-row flex-wrap px-3 gap-2"
            >
              <span v-if="data.isSelected">
                <div v-html="getSvg('selected')"></div>
              </span>
              <span v-if="!data.isSelected">
                <div v-html="getSvg('checkbox')"></div>
              </span>

              <div v-html="getSvg(data?.icon, data.isSelected)"></div>
              <div
                class="text-[12px] font-medium font-sans text-[#6B6B6B] dark:!text-[#6C707A]"
              >
                {{ data.name }}
              </div>
            </div>

            <!-- PTS 144 -->
            <div
              v-if="
                list.name === 'Pro Reversal Scan' && selectedButton == list.name
              "
              @click="selectedScannerHandler(data, list.id)"
              v-for="(data, ind) in list.data"
              :key="ind"
              :class="
                data.isSelected
                  ? '!bg-[#E6EFF5] -!border-appBlue'
                  : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
              "
              class="2lg:!w-[18rem] -bg-slate-600 xxs:w-full h-[3.5rem] -rounded-[8px] border dark:!text-[#6C707A] flex !shrink-0 justift-start items-center cursor-pointer flex-row flex-wrap px-3 gap-2"
            >
              <span v-if="data.isSelected">
                <div v-html="getSvg('selected')"></div>
              </span>
              <span v-if="!data.isSelected">
                <div v-html="getSvg('checkbox')"></div>
              </span>

              <div v-html="getSvg(data?.icon, data.isSelected)"></div>
              <div
                class="text-[12px] font-medium font-sans text-[#6B6B6B] dark:!text-[#6C707A]"
              >
                {{ data.name }}
              </div>
            </div>

              <!-- PTS 145 -->
              <div
              v-if="
                list.name === 'Pro Swing Scan' && selectedButton == list.name
              "
              @click="selectedScannerHandler(data, list.id)"
              v-for="(data, ind) in list.data"
              :key="ind"
              :class="
                data.isSelected
                  ? '!bg-[#E6EFF5] -!border-appBlue'
                  : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
              "
              class="2lg:!w-[18rem] -bg-slate-600 xxs:w-full h-[3.5rem] -rounded-[8px] border dark:!text-[#6C707A] flex !shrink-0 justift-start items-center cursor-pointer flex-row flex-wrap px-3 gap-2"
            >
              <span v-if="data.isSelected">
                <div v-html="getSvg('selected')"></div>
              </span>
              <span v-if="!data.isSelected">
                <div v-html="getSvg('checkbox')"></div>
              </span>

              <div v-html="getSvg(data?.icon, data.isSelected)"></div>
              <div
                class="text-[12px] font-medium font-sans text-[#6B6B6B] dark:!text-[#6C707A]"
              >
                {{ data.name }}
              </div>
            </div>

            <div
              v-if="
                list.name === 'Pro Trade Scan' && selectedButton == list.name
              "
              @click="selectedScannerHandler(data, list.id)"
              v-for="(data, ind) in list.data"
              :key="ind"
              :class="
                data.isSelected
                  ? '!bg-[#E6EFF5] -!border-appBlue'
                  : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
              "
              class="2lg:!w-[18rem] -bg-slate-600 xxs:w-full h-[3.5rem] -rounded-[8px] border dark:!text-[#6C707A] flex !shrink-0 justift-start items-center cursor-pointer flex-row flex-wrap px-3 gap-2"
            >
              <span v-if="data.isSelected">
                <div v-html="getSvg('selected')"></div>
              </span>
              <span v-if="!data.isSelected">
                <div v-html="getSvg('checkbox')"></div>
              </span>

              <div v-html="getSvg(data?.icon, data.isSelected)"></div>
              <div
                class="text-[12px] font-medium font-sans text-[#6B6B6B] dark:!text-[#6C707A]"
              >
                {{ data.name }}
              </div>
            </div>

            <div
              v-if="
                list.name === 'Pro Down Trade Scan' && selectedButton == list.name
              "
              @click="selectedScannerHandler(data, list.id)"
              v-for="(data, ind) in list.data"
              :key="ind"
              :class="
                data.isSelected
                  ? '!bg-[#E6EFF5] -!border-appBlue'
                  : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
              "
              class="2lg:!w-[18rem] -bg-slate-600 xxs:w-full h-[3.5rem] -rounded-[8px] border dark:!text-[#6C707A] flex !shrink-0 justift-start items-center cursor-pointer flex-row flex-wrap px-3 gap-2"
            >
              <span v-if="data.isSelected">
                <div v-html="getSvg('selected')"></div>
              </span>
              <span v-if="!data.isSelected">
                <div v-html="getSvg('checkbox')"></div>
              </span>

              <div v-html="getSvg(data?.icon, data.isSelected)"></div>
              <div
                class="text-[12px] font-medium font-sans text-[#6B6B6B] dark:!text-[#6C707A]"
              >
                {{ data.name }}
              </div>
            </div>

            <div
              v-if="
                list.name === 'Pro Divergence Scan' && selectedButton == list.name
              "
              @click="selectedScannerHandler(data, list.id)"
              v-for="(data, ind) in list.data"
              :key="ind"
              :class="
                data.isSelected
                  ? '!bg-[#E6EFF5] -!border-appBlue'
                  : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
              "
              class="2lg:!w-[18rem] -bg-slate-600 xxs:w-full h-[3.5rem] -rounded-[8px] border dark:!text-[#6C707A] flex !shrink-0 justift-start items-center cursor-pointer flex-row flex-wrap px-3 gap-2"
            >
              <span v-if="data.isSelected">
                <div v-html="getSvg('selected')"></div>
              </span>
              <span v-if="!data.isSelected">
                <div v-html="getSvg('checkbox')"></div>
              </span>

              <div v-html="getSvg(data?.icon, data.isSelected)"></div>
              <div
                class="text-[12px] font-medium font-sans text-[#6B6B6B] dark:!text-[#6C707A]"
              >
                {{ data.name }}
              </div>
            </div>

            <div
              v-if="
                list.name === 'Run Pro Breakout' && selectedButton == list.name
              "
              @click="selectedScannerHandler(data, list.id)"
              v-for="(data, ind) in list.data"
              :key="ind"
              :class="
                data.isSelected
                  ? '!bg-[#E6EFF5] -!border-appBlue'
                  : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
              "
              class="2lg:!w-[18rem] -bg-slate-600 xxs:w-full h-[3.5rem] -rounded-[8px] border dark:!text-[#6C707A] flex !shrink-0 justift-start items-center cursor-pointer flex-row flex-wrap px-3 gap-2"
            >
              <span v-if="data.isSelected">
                <div v-html="getSvg('selected')"></div>
              </span>
              <span v-if="!data.isSelected">
                <div v-html="getSvg('checkbox')"></div>
              </span>

              <div v-html="getSvg(data?.icon, data.isSelected)"></div>
              <div
                class="text-[12px] font-medium font-sans text-[#6B6B6B] dark:!text-[#6C707A]"
              >
                {{ data.name }}
              </div>
            </div>

            <!--Activity -->
            <div
              v-if="list.name == 'Activity' && selectedButton == list.name"
              class="w-full space-y-4 h-full"
            >
              <div v-for="(val, ind) in list.data" :key="ind" class="w-full">
                <div
                  :class="
                    val?.isSelected
                      ? '!bg-[#E6EFF5] -!border-appBlue'
                      : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D]  hover:bg-[#F6F6F6] !border-[transparent]'
                  "
                  class="w-[90%] flex gap-2 border-[0.5px] justify-start dark:!text-[#6C707A] cursor-pointer items-center -rounded-[8px] px-[12px]"
                >
                  <div
                    v-for="(inp, inpId) in val.inputs"
                    :key="inpId"
                    class="flex w-fit"
                  >
                    <div
                      v-if="inp?.label"
                      :class="inp.type == '' ? 'py-2' : 'py-0'"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-medium leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label }}
                    </div>
                    <div
                      v-if="inp.type == 'number'"
                      class="w-[8rem] relative flex flex-row dark:text-[#6C707A]"
                    >
                      <Input :inp="inp" :val="val" />
                    </div>
                    <div
                      v-if="inp.type == 'radio'"
                      class="py-2 flex justify-center items-center"
                    >
                      <input
                        type="radio"
                        :name="'activity-radio'"
                        :value="val.name"
                        v-model="list.parentSelectedRadioButton"
                        class="radio-no-outline text-[#7A7A7A] border-[1px] !border-appBlue focus:outline-none"
                        style="outline: none"
                      />
                      <label v-if="inp.radioLabel" :for="inp.id">{{
                        inp.radioLabel
                      }}</label>
                      <!-- <RadioButton :val="val" :inp="inp" :list="list" /> -->
                    </div>

                    <div
                      v-if="inp?.label2"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-meduim leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label2 }}
                    </div>
                    <span v-if="inp.type == 'option'" class="w-[7rem]">
                      <Option :inp="inp" :val="val" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <!-- CandlesStick Pattern -->
            <div
              v-if="list.name === 'Candlestick' && selectedButton == list.name"
              @click="selectedScannerHandler(data, list.id)"
              v-for="(data, ind) in list.data"
              :key="ind"
              :class="
                data.isSelected
                  ? 'bg-[#E6EFF5] -!border-appBlue'
                  : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] cursor-pointer !border-[transparent]'
              "
              class="w-[31%] h-[3.5rem] -rounded-[8px] border flex dark:text-[#6C707A] justift-start items-center flex-row flex-wrap px-3 gap-2"
            >
              <span v-if="data.isSelected">
                <div v-html="getSvg('selected')"></div>
              </span>
              <span v-if="!data.isSelected">
                <div v-html="getSvg('checkbox')"></div>
              </span>

              <!-- <div v-html="getSvg(data?.icon,data.isSelected)"></div> -->
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                  />
                </svg>
              </div>
              <div
                class="text-[12px] font-medium font-sans text-[#6B6B6B] dark:text-[#6C707A]"
              >
                {{ data.name }}
              </div>
            </div>

            <!-- Indicators Code -->
            <div
              v-if="
                list.name == 'Technical Indicators' &&
                selectedButton == list.name
              "
              class="w-full space-y-4 h-full"
            >
              <div v-for="(val, ind) in list.data" :key="ind" class="w-full">
                <div
                  :class="
                    val?.isSelected
                      ? 'bg-[#E6EFF5] -!border-appBlue'
                      : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
                  "
                  class="w-[90%] flex flex-wrap gap-2 border-[0.5px] justify-start dark:text-[#6C707A] cursor-pointer items-center -rounded-[8px] px-[12px]"
                >
                  <div
                    v-for="(inp, inpId) in val.inputs"
                    :key="inpId"
                    class="flex w-fit py-2"
                  >
                    <div
                      @click="eventSelected(val, list.id)"
                      v-if="inp.type == 'checkbox'"
                      class="flex items-center w-fit"
                    >
                      <span v-if="val.isSelected">
                        <div v-html="getSvg('selected')"></div>
                      </span>
                      <span v-if="!val.isSelected">
                        <div v-html="getSvg('checkbox')"></div>
                      </span>
                    </div>

                    <div
                      :class="inp.type == '' ? 'py-2' : 'py-0'"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-medium leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label }}
                    </div>

                    <div
                      v-if="inp.type == 'number'"
                      class="radio-no-outline w-[8rem] relative flex flex-row"
                    >
                      <Input :inp="inp" :val="val" />
                    </div>

                    <div
                      v-if="inp.type == 'radio'"
                      class="py-2 flex justify-center items-center"
                    >
                      <input
                        type="radio"
                        :id="list.id"
                        :value="inp.value"
                        v-model="val.selectedRadio"
                        class="radio-no-outline text-[#7A7A7A] border-[1px] !border-appBlue focus:outline-none"
                        style="outline: none"
                      />
                      <label :for="inp.id">{{ inp.radioLabel }}</label>
                    </div>
                    <div
                      v-if="inp?.label2"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-meduim leading-[15px] text-[#323232]"
                    >
                      {{ inp?.label2 }}
                    </div>
                    <span v-if="inp.type == 'option'" class="w-[7rem]">
                      <Option :inp="inp" :val="val" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Darvas Box -->
            <div
              v-if="list.name == 'Darvas Box' && selectedButton == list.name"
              class="w-full space-y-4 h-full"
            >
              <!-- <ChartData :list="list.data" /> -->
              <div v-for="(val, ind) in list.data" :key="ind" class="w-full">
                <div
                  :class="
                    val?.isSelected
                      ? 'bg-[#E6EFF5] -!border-appBlue'
                      : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
                  "
                  class="w-[90%] flex gap-2 border-[0.5px] justify-start cursor-pointer dark:text-[#6C707A] items-center -rounded-[8px] px-[12px]"
                >
                  <div
                    v-for="(inp, inpId) in val.inputs"
                    :key="inpId"
                    class="flex w-fit py-2"
                  >
                    <div
                      @click="eventSelected(val, list.id)"
                      v-if="inp.type == 'checkbox'"
                      class="flex items-center w-fit"
                    >
                      <span v-if="val.isSelected">
                        <div v-html="getSvg('selected')"></div>
                      </span>
                      <span v-if="!val.isSelected">
                        <div v-html="getSvg('checkbox')"></div>
                      </span>
                    </div>
                    <div
                      :class="inp.type == '' ? 'py-2' : 'py-0'"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-medium leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label }}
                    </div>
                    <div
                      v-if="inp.type == 'number'"
                      class="w-[8rem] relative flex flex-row"
                    >
                      <Input :inp="inp" :val="val" />
                    </div>
                    <div
                      v-if="inp.type == 'radio'"
                      class="py-2 flex justify-center items-center"
                    >
                      <input
                        type="radio"
                        :id="list.id"
                        :value="inp.value"
                        v-model="val.selectedRadio"
                        class="radio-no-outline text-[#7A7A7A] dark:text-[#6C707A] border-[1px] dark:border-[#2D2D2D] !border-appBlue focus:outline-none"
                        style="outline: none"
                      />
                      <label :for="inp.id">{{ inp.radioLabel }}</label>
                    </div>
                    <div
                      v-if="inp?.label2"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-meduim leading-[15px] text-[#323232]"
                    >
                      {{ inp?.label2 }}
                    </div>
                    <span v-if="inp.type == 'option'" class="w-[7rem]">
                      <Option :inp="inp" :val="val" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Fundamental -->
            <div v-if="list.name == 'Measures' && selectedButton == list.name">
              <div v-for="(val, ind) in list.data" :key="ind" class="w-full">
                <div
                  :class="
                    val?.isSelected
                      ? 'bg-[#E6EFF5] -!border-appBlue'
                      : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
                  "
                  class="w-[100%] flex !gap-2 mt-1 border-[0.5px] justify-start dark:text-[#6C707A] items-center cursor-pointer -rounded-[8px] px-[12px]"
                >
                  <div
                    v-for="(inp, inpId) in val.inputs"
                    :key="inpId"
                    class="flex w-fit py-2"
                  >
                    <div
                      @click="eventSelected(val, list.id)"
                      v-if="inp.type == 'checkbox'"
                      class="flex items-center w-fit"
                    >
                      <span v-if="val.isSelected">
                        <div v-html="getSvg('selected')"></div>
                      </span>
                      <span v-if="!val.isSelected">
                        <div v-html="getSvg('checkbox')"></div>
                      </span>
                    </div>
                    <div
                      :class="inp.type == '' ? 'py-2' : 'py-0'"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-medium leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label }}
                    </div>
                    <div
                      v-if="inp.type == 'number'"
                      class="w-[8rem] relative flex flex-row"
                    >
                      <Input :inp="inp" :val="val" />
                    </div>
                    <div
                      v-if="inp.type == 'radio'"
                      class="py-2 flex justify-center items-center"
                    >
                      <input
                        type="radio"
                        :id="list.id"
                        :value="inp.value"
                        v-model="val.selectedRadio"
                        class="radio-no-outline text-[#7A7A7A] border-[1px] !border-appBlue focus:outline-none"
                        style="outline: none"
                      />
                      <label :for="inp.id">{{ inp.radioLabel }}</label>
                    </div>
                    <div
                      v-if="inp?.label2"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-meduim leading-[15px] text-[#323232]"
                    >
                      {{ inp?.label2 }}
                    </div>
                    <span v-if="inp.type == 'option'" class="w-[7rem]">
                      <Option :inp="inp" :val="val" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                list.name == 'Capitalisation' && selectedButton == list.name
              "
            >
              <div v-for="(val, ind) in list.data" :key="ind" class="w-full">
                <div
                  :class="
                    val?.isSelected
                      ? 'bg-[#E6EFF5] -!border-appBlue'
                      : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
                  "
                  class="w-[100%] flex !gap-2 mt-1 border-[0.5px] justify-start dark:text-[#6C707A] items-center cursor-pointer -rounded-[8px] px-[12px]"
                >
                  <div
                    v-for="(inp, inpId) in val.inputs"
                    :key="inpId"
                    class="flex w-fit py-2"
                  >
                    <div
                      @click="eventSelected(val, list.id)"
                      v-if="inp.type == 'checkbox'"
                      class="flex items-center w-fit"
                    >
                      <span v-if="val.isSelected">
                        <div v-html="getSvg('selected')"></div>
                      </span>
                      <span v-if="!val.isSelected">
                        <div v-html="getSvg('checkbox')"></div>
                      </span>
                    </div>
                    <div
                      :class="inp.type == '' ? 'py-2' : 'py-0'"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-medium leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label }}
                    </div>
                    <div
                      v-if="inp.type == 'number'"
                      class="w-[8rem] relative flex flex-row"
                    >
                      <Input :inp="inp" :val="val" />
                    </div>
                    <div
                      v-if="inp.type == 'radio'"
                      class="py-2 flex justify-center items-center"
                    >
                      <input
                        type="radio"
                        :id="list.id"
                        :value="inp.value"
                        v-model="val.selectedRadio"
                        class="radio-no-outline text-[#7A7A7A] border-[1px] !border-appBlue focus:outline-none"
                        style="outline: none"
                      />
                      <label :for="inp.id">{{ inp.radioLabel }}</label>
                    </div>
                    <div
                      v-if="inp?.label2"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-meduim leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label2 }}
                    </div>
                    <span v-if="inp.type == 'option'" class="w-[7rem]">
                      <Option :inp="inp" :val="val" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="list.name == 'Returns' && selectedButton == list.name">
              <div v-for="(val, ind) in list.data" :key="ind" class="w-full">
                <div
                  :class="
                    val?.isSelected
                      ? 'bg-[#E6EFF5] -!border-appBlue'
                      : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
                  "
                  class="w-[100%] flex gap-2 border-[0.5px] justify-start items-center dark:text-[#6C707A] cursor-pointer -rounded-[8px] px-[12px]"
                >
                  <div
                    v-for="(inp, inpId) in val.inputs"
                    :key="inpId"
                    class="flex w-fit py-2"
                  >
                    <div
                      @click="eventSelected(val, list.id)"
                      v-if="inp.type == 'checkbox'"
                      class="flex items-center w-fit"
                    >
                      <span v-if="val.isSelected">
                        <div v-html="getSvg('selected')"></div>
                      </span>
                      <span v-if="!val.isSelected">
                        <div v-html="getSvg('checkbox')"></div>
                      </span>
                    </div>
                    <div
                      :class="inp.type == '' ? 'py-2' : 'py-0'"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-medium leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label }}
                    </div>
                    <div
                      v-if="inp.type == 'number'"
                      class="w-[8rem] relative flex flex-row"
                    >
                      <Input :inp="inp" :val="val" />
                    </div>
                    <div
                      v-if="inp.type == 'radio'"
                      class="py-2 flex justify-center items-center"
                    >
                      <input
                        type="radio"
                        :id="list.id"
                        :value="inp.value"
                        v-model="val.selectedRadio"
                        class="radio-no-outline text-[#7A7A7A] border-[1px] dark:text-[#6C707A] !border-appBlue focus:outline-none"
                        style="outline: none"
                      />
                      <label :for="inp.id">{{ inp.radioLabel }}</label>
                    </div>
                    <div
                      v-if="inp?.label2"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-meduim leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label2 }}
                    </div>
                    <span v-if="inp.type == 'option'" class="w-[7rem]">
                      <Option :inp="inp" :val="val" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <!-- For Capitulation Days -->
            <div
              v-if="
                list.name == 'Capitulation Days' && selectedButton == list.name
              "
            >
              <div v-for="(val, ind) in list.data" :key="ind" class="w-full">
                <div
                  :class="
                    val?.isSelected
                      ? 'bg-[#E6EFF5] -!border-appBlue'
                      : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
                  "
                  class="w-[90%] flex gap-2 border-[0.5px] justify-start items-center cursor-pointer -rounded-[8px] px-[12px]"
                >
                  <div
                    v-for="(inp, inpId) in val.inputs"
                    :key="inpId"
                    class="flex w-fit py-2"
                  >
                    <div
                      @click="eventSelected(val, list.id)"
                      v-if="inp.type == 'checkbox'"
                      class="flex items-center w-fit"
                    >
                      <span v-if="val.isSelected">
                        <div v-html="getSvg('selected')"></div>
                      </span>
                      <span v-if="!val.isSelected">
                        <div v-html="getSvg('checkbox')"></div>
                      </span>
                    </div>
                    <div
                      :class="inp.type == '' ? 'py-2' : 'py-0'"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-medium leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label }}
                    </div>
                    <div
                      v-if="inp.type == 'number'"
                      class="w-[8rem] relative flex flex-row"
                    >
                      <Input :inp="inp" :val="val" />
                    </div>
                    <div
                      v-if="inp.type == 'radio'"
                      class="py-2 flex justify-center items-center"
                    >
                      <input
                        type="radio"
                        :id="list.id"
                        :value="inp.value"
                        v-model="val.selectedRadio"
                        class="radio-no-outline text-[#7A7A7A] dark:text-[#6C707A] border-[1px] !border-appBlue focus:outline-none"
                        style="outline: none"
                      />
                      <label :for="inp.id">{{ inp.radioLabel }}</label>
                    </div>
                    <div
                      v-if="inp?.label2"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-meduim leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label2 }}
                    </div>
                    <span v-if="inp.type == 'option'" class="w-[7rem]">
                      <Option :inp="inp" :val="val" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Bollinger Squeeze -->
            <div
              v-if="
                list.name == 'Bollinger Squeeze' && selectedButton == list.name
              "
              class="space-y-2"
            >
              <div v-for="(val, ind) in list.data" :key="ind" class="w-full">
                <div
                  :class="
                    val?.isSelected
                      ? 'bg-[#E6EFF5] -!border-appBlue'
                      : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
                  "
                  class="w-[90%] flex gap-2 border-[0.5px] justify-start items-center dark:text-[#6C707A] cursor-pointer -rounded-[8px] px-[12px]"
                >
                  <div
                    v-for="(inp, inpId) in val.inputs"
                    :key="inpId"
                    class="flex w-fit py-2"
                  >
                    <div
                      @click="eventSelected(val, list.id)"
                      v-if="inp.type == 'checkbox'"
                      class="flex items-center w-fit"
                    >
                      <span v-if="val.isSelected">
                        <div v-html="getSvg('selected')"></div>
                      </span>
                      <span v-if="!val.isSelected">
                        <div v-html="getSvg('checkbox')"></div>
                      </span>
                    </div>
                    <div
                      :class="inp.type == '' ? 'py-2' : 'py-0'"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-medium leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label }}
                    </div>
                    <div
                      v-if="inp.type == 'number'"
                      class="w-[8rem] relative flex flex-row"
                    >
                      <Input :inp="inp" :val="val" />
                    </div>
                    <div
                      v-if="inp.type == 'radio'"
                      class="py-2 flex justify-center items-center"
                    >
                      <input
                        type="radio"
                        :id="list.id"
                        :value="inp.value"
                        v-model="val.selectedRadio"
                        class="radio-no-outline text-[#7A7A7A] dark:text-[#6C707A] border-[1px] !border-appBlue focus:outline-none"
                        style="outline: none"
                      />
                      <label :for="inp.id">{{ inp.radioLabel }}</label>
                    </div>
                    <div
                      v-if="inp?.label2"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-meduim leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label2 }}
                    </div>
                    <span v-if="inp.type == 'option'" class="w-[7rem]">
                      <Option :inp="inp" :val="val" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="list.name == 'Big Candle' && selectedButton == list.name"
            >
              <div v-for="(val, ind) in list.data" :key="ind" class="w-full">
                <div
                  :class="
                    val?.isSelected
                      ? '!bg-[#E6EFF5] -!border-appBlue'
                      : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
                  "
                  class="w-[90%] flex gap-2 mt-1 border-[0.5px] justify-start items-center dark:text-[#6C707A] cursor-pointer -rounded-[8px] px-[12px]"
                >
                  <div
                    v-for="(inp, inpId) in val.inputs"
                    :key="inpId"
                    class="flex w-fit py-2"
                  >
                    <div
                      @click="eventSelected(val, list.id)"
                      v-if="inp.type == 'checkbox'"
                      class="flex items-center w-fit"
                    >
                      <span v-if="val.isSelected">
                        <div v-html="getSvg('selected')"></div>
                      </span>
                      <span v-if="!val.isSelected">
                        <div v-html="getSvg('checkbox')"></div>
                      </span>
                    </div>
                    <div
                      :class="inp.type == '' ? 'py-2' : 'py-0'"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-medium leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label }}
                    </div>
                    <div
                      v-if="inp.type == 'number'"
                      class="w-[8rem] relative flex flex-row"
                    >
                      <Input :inp="inp" :val="val" />
                    </div>
                    <div
                      v-if="inp.type == 'radio'"
                      class="py-2 flex justify-center items-center"
                    >
                      <input
                        type="radio"
                        :id="list.id"
                        :value="inp.value"
                        v-model="val.selectedRadio"
                        class="radio-no-outline text-[#7A7A7A] dark:text-[#6C707A] border-[1px] !border-appBlue focus:outline-none"
                        style="outline: none"
                      />
                      <label :for="inp.id">{{ inp.radioLabel }}</label>
                    </div>
                    <div
                      v-if="inp?.label2"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-meduim leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label2 }}
                    </div>
                    <span v-if="inp.type == 'option'" class="w-[7rem]">
                      <Option :inp="inp" :val="val" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="list.name == 'Heiken Ashi' && selectedButton == list.name"
            >
              <div v-for="(val, ind) in list.data" :key="ind" class="w-full">
                <div
                  :class="
                    val?.isSelected
                      ? '!bg-[#E6EFF5] !border-appBlue'
                      : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
                  "
                  class="w-[90%] flex gap-2 mt-1 border-[0.5px] justify-start dark:text-[#6C707A] items-center cursor-pointer rounded-[8px] px-[12px]"
                >
                  <div
                    v-for="(inp, inpId) in val.inputs"
                    :key="inpId"
                    class="flex w-fit py-2"
                  >
                    <div
                      @click="eventSelected(val, list.id)"
                      v-if="inp.type == 'checkbox'"
                      class="flex items-center w-fit"
                    >
                      <span v-if="val.isSelected">
                        <div v-html="getSvg('selected')"></div>
                      </span>
                      <span v-if="!val.isSelected">
                        <div v-html="getSvg('checkbox')"></div>
                      </span>
                    </div>
                    <div
                      v-if="inp?.label"
                      :class="inp.type == '' ? 'py-2' : 'py-0'"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-medium leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label }}
                    </div>
                    <div
                      v-if="inp.type == 'number'"
                      class="w-[8rem] relative flex flex-row"
                    >
                      <Input :inp="inp" :val="val" />
                    </div>
                    <div
                      v-if="inp.type == 'radio'"
                      class="py-2 flex justify-center items-center"
                    >
                      <input
                        type="radio"
                        :id="list.id"
                        :value="inp.value"
                        v-model="val.selectedRadio"
                        class="radio-no-outline text-[#7A7A7A] dark:text-[#6C707A] border-[1px] !border-appBlue focus:outline-none"
                        style="outline: none"
                      />
                      <label :for="inp.id">{{ inp.radioLabel }}</label>
                    </div>
                    <div
                      v-if="inp?.label2"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-meduim leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label2 }}
                    </div>
                    <span v-if="inp.type == 'option'" class="w-[7rem]">
                      <Option :inp="inp" :val="val" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Event code -->

            <div
              v-if="list.name == 'Events' && selectedButton == list.name"
              class="w-full space-y-4 h-full"
            >
              <div v-for="(val, ind) in list.data" :key="ind" class="w-full">
                <div
                  :class="
                    val?.isSelected
                      ? 'bg-[#E6EFF5] -!border-appBlue'
                      : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
                  "
                  class="w-[90%] flex gap-2 mt-1 border-[0.5px] justify-start items-center cursor-pointer -rounded-[8px] px-[12px]"
                >
                  <div
                    v-for="(inp, inpId) in val.inputs"
                    :key="inpId"
                    class="flex w-fit py-2"
                  >
                    <div
                      @click="eventSelected(val, list.id)"
                      v-if="inp.type == 'checkbox'"
                      class="flex items-center w-fit"
                    >
                      <span v-if="val.isSelected">
                        <div v-html="getSvg('selected')"></div>
                      </span>
                      <span v-if="!val.isSelected">
                        <div v-html="getSvg('checkbox')"></div>
                      </span>
                    </div>
                    <div
                      v-if="inp?.label"
                      :class="inp.type == '' ? 'py-2' : 'py-0'"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-medium leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label }}
                    </div>
                    <div
                      v-if="inp.type == 'number'"
                      class="w-[8rem] relative flex flex-row -bg-white"
                    >
                      <Input :inp="inp" :val="val" />
                    </div>
                    <div
                      v-if="inp.type == 'radio'"
                      class="py-2 flex justify-center items-center"
                    >
                      <input
                        type="radio"
                        :id="list.id"
                        :value="inp.value"
                        v-model="val.selectedRadio"
                        class="radio-no-outline text-[#7A7A7A] border-[1px] !border-appBlue dark:!text-[#6C707A] dark:border-[#2D2D2D] focus:outline-none"
                        style="outline: none"
                      />
                      <label class="dark:!text-[#6C707A]" :for="inp.id">{{
                        inp.radioLabel
                      }}</label>
                    </div>

                    <div
                      v-if="inp?.label2"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-meduim leading-[15px] text-[#323232] dark:!text-[#6C707A]"
                    >
                      {{ inp?.label2 }}
                    </div>
                    <span v-if="inp.type == 'option'" class="w-[7rem]">
                      <Option :inp="inp" :val="val" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- filters code -->
            <div
              v-if="list.name == 'Filter' && selectedButton == list.name"
              class="w-full space-y-4 h-full"
            >
              <div v-for="(val, ind) in list.data" :key="ind" class="w-full">
                <div
                  :class="
                    val?.isSelected
                      ? 'bg-[#E6EFF5] -!border-appBlue'
                      : 'hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-[#F6F6F6] !border-[transparent]'
                  "
                  class="w-[90%] flex gap-2 mt-1 border-[0.5px] dark:text-[#6C707A] justify-start cursor-pointer items-center -rounded-[8px] px-[12px]"
                >
                  <div
                    v-for="(inp, inpId) in val.inputs"
                    :key="inpId"
                    class="flex w-fit px-0 py-2"
                  >
                    <div
                      @click="eventSelected(val, list.id)"
                      v-if="inp.type == 'checkbox'"
                      class="flex items-center w-fit"
                    >
                      <span v-if="val.isSelected">
                        <div v-html="getSvg('selected')"></div>
                      </span>
                      <span v-if="!val.isSelected">
                        <div v-html="getSvg('checkbox')"></div>
                      </span>
                    </div>
                    <div
                      v-if="inp?.label"
                      :class="val.isSelected ? '' : ''"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-medium leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label }}
                    </div>
                    <div
                      v-if="inp.type == 'number'"
                      class="w-[8rem] relative flex flex-row"
                    >
                      <Input :inp="inp" :val="val" />
                    </div>
                    <div
                      v-if="inp.type == 'radio'"
                      class="py-2 flex justify-center items-center"
                    >
                      <input
                        type="radio"
                        :name="'radio-' + list.id"
                        v-model="selectedRadio"
                        :value="inp.default"
                        class="radio-no-outline text-[#7A7A7A] dark:text-[#6C707A] border-[1px] !border-appBlue focus:outline-none"
                        style="outline: none"
                      />
                    </div>

                    <div
                      v-if="inp?.label2"
                      class="flex flex-row justify-start items-center text-[12px] px-2 font-meduim leading-[15px] dark:text-[#6C707A] text-[#323232]"
                    >
                      {{ inp?.label2 }}
                    </div>
                    <span
                      v-if="inp.type == 'option'"
                      class="w-[7rem] dark:text-[#6C707A]"
                    >
                      <Option :inp="inp" :val="val" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer -->
    <div
      v-if="isLoading"
      class="absolute backdrop-blur-md w-full h-[90%] flex items-center justify-center"
    >
      <ProgressBar :progress="progress"></ProgressBar>
    </div>
    <div
      class="w-full h-[60px] -bg-slate-500 flex flex-end justify-between mt-2 items-center dark:!border-[#2D2D2D] border-t xxs:px-2 md:px-6 py-3"
    >
      <div
        class="cursor-pointer text-[#F6F6F6] bg-[#397F16] hover:bg-[#397F16] !border-none border dark:!border-[#2D2D2D] border-[#D1D1D1] px-[24px] py-[8px] rounded-[10px]"
      >
        Add to favourite
      </div>
      <!-- @click="onSaveHandler " -->
      <div class="flex flex-start items-center gap-3">
        <div
          @click="handleClose"
          class="cursor-pointer border hover:bg-[#F6F6F6] font-bold border-[#D1D1D1] dark:bg-[#FFFFFF1A] dark:!border-[#2D2D2D] dark:hover:bg-[#2D2D2D] text-[#292929] dark:text-[#F6F6F6] px-[24px] py-[8px] rounded-[10px]"
        >
          Close
        </div>
        <div
          v-if="!isLoading"
          @click="onSaveHandler"
          class="cursor-pointer text-[#F6F6F6] bg-appBlue hover:bg-blueHover !border-none border dark:!border-[#2D2D2D] border-[#D1D1D1] px-[24px] py-[8px] rounded-[10px]"
        >
          Scan
        </div>
        <div
          v-if="isLoading"
          @click="stopScan"
          class="cursor-pointer text-[#F6F6F6] bg-appBlue hover:bg-blueHover !border-none border dark:!border-[#2D2D2D] border-[#D1D1D1] px-[24px] py-[8px] rounded-[10px]"
        >
          StopScan
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  DEFAULT_DATA_SETTINGS,
  DEFAULT_SETTINGS_DARVAS,
  DEFAULT_SETTINGS_FUNDAMENTALS,
  DEFAULT_CAPITULATION,
  DEFAULT_BOLLINGER_SQUEEZE,
  DEFAULT_BIG_CANDLE,
  DEFAULT_HEIKEN_ASHI,
  DEFAULT_ACTIVITY_SETTINGS,
} from '../settings/USER_SETTINGS_DEFAULT.js'
import { mapActions, mapGetters } from 'vuex'
import { scannerConfig } from '../utils/scanner'
import Minimize from './Minimize.vue'
import ScanType from './ScanType.vue'
import { getSvgByName } from '../assets/leftsidebarsvgs/Utils/SvgUtils'
import ScannerRender from './ScannerRender.vue'
import ModalHeader from './ModalHeader.vue'
import Input from './Input.vue'
import Option from './Option.vue'
import ProgressBar from './ProgressBar.vue'
import {
  proTradeScan,
  proDownTradeScan,
  proDivergenceScan,
  runProBreakout,
  proSwingScan,
  proReversalScan,
  patternsScan,
  candlestickScan,
  indicatorsScan,
  activityScan,
  darvasScan,
  fundementalScan,
} from '../services/scans'
import {
  capitulation,
  bollingerSqueeze,
  bigCandleScan,
  heikenAshiScan,
} from '../services/gurus'
import Loader from './Loading'

export default {
  name: 'Scanner',
  data() {
    return {
      searchTerm: "",
      scannerData: scannerConfig,
      selectedScanner: JSON.parse(JSON.stringify(scannerConfig[0])),
      isMinimize: false,
      activitySettings: JSON.parse(JSON.stringify(DEFAULT_ACTIVITY_SETTINGS)),
      dataSettings: JSON.parse(JSON.stringify(DEFAULT_DATA_SETTINGS)),
      darvasSettings: JSON.parse(JSON.stringify(DEFAULT_SETTINGS_DARVAS)),
      fundamentalSettings: JSON.parse(
        JSON.stringify(DEFAULT_SETTINGS_FUNDAMENTALS)
      ),
      capitulationSetting: JSON.parse(JSON.stringify(DEFAULT_CAPITULATION)),
      bollingerSqueezeSetting: JSON.parse(
        JSON.stringify(DEFAULT_BOLLINGER_SQUEEZE)
      ),
      bigCandleSettings: JSON.parse(JSON.stringify(DEFAULT_BIG_CANDLE)),
      heikenAshiSetting: JSON.parse(JSON.stringify(DEFAULT_HEIKEN_ASHI)),
      scanResult: null,
      isLoading: false,
      setSettings: null,
      setFilters: null,
      setEvents: null,
      progress: 0,
      progressCout: 0,
      buttons: [
        { label: 'Events', name: 'events' },
        { label: 'Filters', name: 'filters' },
        { label: 'Scan Type', name: 'scanType' },
      ],
      favlist: [
        {
          id: 1,
          name: 'My Far 1',
        },
        {
          id: 2,
          name: 'My Far 2',
        },
        {
          id: 3,
          name: 'My Far 3',
        },
      ],
      selectedButton: 'Scan Type',
      selectedlist: scannerConfig[0].lists[0],
      scanListsResponse: null,
      symbolWithName: [],
      selectedMarketName: null,
    }
  },
  props: {
    type: {
      type: Array,
      default: [],
    },
  },
  components: {
    Minimize,
    ScannerRender,
    Input,
    Option,
    Loader,
    ScanType,
    ProgressBar
  },
  /** @returns {strings} */
  computed: {
    ...mapGetters([
      'getTheme',
      'getUserId',
      'dataSettingsForScan',
      'getScanList',
      'getClientToken',
      'getCount',
      'getTotalCount',
      'getSymbolCountLength'
    ]),
  },
  methods: {
    ...mapActions(['updatedScanResult', 'updateScanList', 'cancelScans']),
    increaseProgress() {
      for (let i = 0; i < this.getSymbolCountLength; i++) {  
        setTimeout(() => {
          this.isLoading = true
          if (this.progress < 100) {
            this.progress += this.getSymbolCountLength[i]
          }
          // console.log('progress', this.progress, this.isLoading)
        }, i * 1000)
      }
    },

    selectedIndex(payload){
      this.dataSettings.type = payload
      // console.log(" ",payload,this.dataSettingss);
      // this.selectedMarketName = payload;
      // this.dataSettings.type = this.selectedMarketName;
    },
    selectedScannner(selectedScannerName) {
      console.log('selectedScannerName', selectedScannerName, this.getScanList,this.selectedMarketName)
      const f = this.getScanList?.find(
        (item) => item?.name == selectedScannerName
      )
      f.scanList.map((item) => this.symbolWithName.push(item.symbol))

      
      // console.log("f==>",result);
      // f.map((item)=>symbolWithName.push(item.symbol) )
      this.dataSettings.type = this.selectedMarketName;
      this.dataSettings.scanList = this.symbolWithName
      this.dataSettings.isScan = true
      
      console.log('symbolWithName==>', this.symbolWithName)
      console.log('datasettings', this.dataSettings)
    },
    stopScan() {
      this.cancelScans()
    },
    handleButton(button) {
      this.selectedButton = button.name
      this.selectedlist = button
      localStorage.setItem('selectedlist', JSON.stringify(button))
      console.log('button', this.selectedlist, button)
    },
    onSaveHandler() {
      const settings = this.getSettingsLikePt()
      const events = this.getEventsLikePt()
      const filters = this.getFilterLikePt()

      // this.isLoading = true

      if (this.selectedScanner.name == 'Charting Patterns') {
        this.patternsScanSubmit(
          settings,
          filters,
          this.dataSettings,
          events,
          this.getUserId
        )
      } else if (this.selectedScanner.name == 'Candlestick') {
        console.log('candlestick==>')
        this.candlestickScanSubHandler(
          settings,
          filters,
          this.dataSettings,
          this.getUserId
        )
      } else if (this.selectedScanner.name == 'Technical Indicators') {
        const settingsThis = this.technicalIndicator()
        this.technicalIndicatorHandler(
          settingsThis,
          filters,
          this.dataSettings,
          events,
          this.getUserId
        )
      } else if (this.selectedScanner.name == 'Activity') {
        this.activity(filters, this.dataSettings, this.getUserId)
      } else if (this.selectedScanner.name === 'Darvas Box') {
        this.darvasBox(filters, this.dataSettings)
      } else if (this.selectedScanner.name === 'Fundamental') {
        this.fundamental(filters)
      } else if (this.selectedScanner.name === 'Capitulation Days') {
        this.capitulationFunction()
      } else if (this.selectedScanner.name === 'Bollinger Squeeze') {
        this.bollingerSqueeze()
      } else if (this.selectedScanner.name == 'Big Candle') {
        this.bigCandle()
      } else if (this.selectedScanner.name == 'Heiken Ashi') {
        this.heikenAshi()
      }else if(this.selectedScanner.name == 'Pro Reversal Scan'){
        console.log("PTS 144===>");
        this.proReversalScan(
          settings,
          filters,
          this.dataSettings,
          events,
          this.getUserId
        )
      }else if(this.selectedScanner.name === 'Pro Swing Scan'){
        this.proSwingScan(
          settings,
          filters,
          this.dataSettings,
          events,
          this.getUserId
        )
      }else if(this.selectedScanner.name == 'Pro Trade Scan'){
        this.proTradeScan(
          settings,
          filters,
          this.dataSettings,
          events,
          this.getUserId
        )
      }else if(this.selectedScanner.name == 'Pro Down Trade Scan'){
        this.proDownTradeScan(
          settings,
          filters,
          this.dataSettings,
          events,
          this.getUserId
        )
      }else if(this.selectedScanner.name == 'Pro Divergence Scan'){
        this.proDivergenceScan(settings,
          filters,
          this.dataSettings,
          events,
          this.getUserId)
      }else if(this.selectedScanner.name == 'Run Pro Breakout'){
        this.runProBreakout(settings,
          filters,
          this.dataSettings,
          events,
          this.getUserId)
      }
      console.log("getCount==>",this.getCount)
      this.increaseProgress()
      localStorage.setItem(
        'selectedScanner',
        JSON.stringify(this.selectedScanner)
      )
    },
    async runProBreakout(settings,filters,dataSettings,events,userId){
      console.log("runProBreakout==>",filters,dataSettings,events,userId);
            const response = await runProBreakout(settings,filters,dataSettings,events,userId)
            console.log("response==>",response);
      // this.isLoading = true
      try {
        if (response) {
          console.log('response==>', response)
          this.isLoading = false
          this.scanResult = response.data.data.results
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          console.log('scan result==>', this.scanResult)
          this.updatedScanResult(this.scanResult)
          this.handleClose()
        }
      } catch (error) {
        console.log('error==>', error)
        this.isLoading = false
      }
    },
    async proDivergenceScan(settings,filters,dataSettings,events,userId){
      console.log("proDownTradeScan==>",filters,dataSettings,events,userId);
            const response = await proDivergenceScan(settings,filters,dataSettings,events,userId)
            console.log("response==>",response);
      // this.isLoading = true
      try {
        if (response) {
          console.log('response==>', response)
          this.isLoading = false
          this.scanResult = response.data.data.results
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          console.log('scan result==>', this.scanResult)
          this.updatedScanResult(this.scanResult)
          this.handleClose()
        }
      } catch (error) {
        console.log('error==>', error)
        this.isLoading = false
      }
    },
    async proDownTradeScan(settings,filters,dataSettings,events,userId){
      console.log("proDownTradeScan==>",filters,dataSettings,events,userId);
            const response = await proDownTradeScan(settings,filters,dataSettings,events,userId)
            console.log("response==>",response);
      // this.isLoading = true
      try {
        if (response) {
          console.log('response==>', response)
          this.isLoading = false
          this.scanResult = response.data.data.results
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          console.log('scan result==>', this.scanResult)
          this.updatedScanResult(this.scanResult)
          this.handleClose()
        }
      } catch (error) {
        console.log('error==>', error)
        this.isLoading = false
      }
    },
    async proTradeScan(settings,filters,dataSettings,events,userId){
      console.log("proTradeScan==>",filters,dataSettings,events,userId);
            const response = await proTradeScan(settings,filters,dataSettings,events,userId)
            console.log("response==>",response);
      // this.isLoading = true
      try {
        if (response) {
          console.log('response==>', response)
          this.isLoading = false
          this.scanResult = response.data.data.results
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          console.log('scan result==>', this.scanResult)
          this.updatedScanResult(this.scanResult)
          this.handleClose()
        }
      } catch (error) {
        console.log('error==>', error)
        this.isLoading = false
      }
    },
    async proSwingScan(settings,filters,dataSettings,events,userId){
      console.log("proSwingScan==>",filters,dataSettings,events,userId);
            const response = await proSwingScan(settings,filters,dataSettings,events,userId)
            console.log("response==>",response);
      // this.isLoading = true
      try {
        if (response) {
          console.log('response==>', response)
          this.isLoading = false
          this.scanResult = response.data.data.results
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          console.log('scan result==>', this.scanResult)
          this.updatedScanResult(this.scanResult)
          this.handleClose()
        }
      } catch (error) {
        console.log('error==>', error)
        this.isLoading = false
      }
    },
    async proReversalScan(settings,filters,dataSettings,events,userId){
      console.log("proReversalScan==>",filters,dataSettings,events,userId);
            const response = await proReversalScan(settings,filters,dataSettings,events,userId)
            console.log("response==>",response);
      // this.isLoading = true
      try {
        if (response) {
          console.log('response==>', response)
          this.isLoading = false
          this.scanResult = response.data.data.results
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          console.log('scan result==>', this.scanResult)
          this.updatedScanResult(this.scanResult)
          this.handleClose()
        }
      } catch (error) {
        console.log('error==>', error)
        this.isLoading = false
      }
    },
    async darvasBoxScanHandler(filters, dataSettings) {
      const response = await darvasScan(
        this.darvasSettings,
        filters,
        dataSettings,
        this.getUserId
      )
      this.isLoading = true
      try {
        if (response) {
          this.isLoading = false
          this.scanResult = response.data.data.results
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          this.updatedScanResult(this.scanResult)
          this.handleClose()
        }
      } catch (error) {
        this.isLoading = false
        console.log('error==>', error)
      }
    },
    async technicalIndicatorHandler(
      settings,
      filters,
      dataSettings,
      events,
      userId
    ) {
      console.log('indicator==>', [
        settings,
        filters,
        dataSettings,
        events,
        userId,
      ])
      const response = await indicatorsScan(
        settings,
        filters,
        dataSettings,
        events,
        userId
      )
      this.isLoading = true
      try {
        if (response) {
          this.isLoading = false
          console.log('response==>', response)
          this.scanResult = response.data.data.results
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          // console.log("scanResult==>",this.scanResult)
          this.updatedScanResult(this.scanResult)
          this.handleClose()
        }
      } catch (error) {
        this.isLoading = false
        console.log('error==>', error)
      }
    },
    async candlestickScanSubHandler(
      settings,
      filterSettings,
      dataSettings,
      userId
    ) {
      const response = await candlestickScan(
        settings,
        filterSettings,
        dataSettings,
        userId,
        this.getClientToken
      )
      this.isLoading = true
      try {
        if (response) {
          this.isLoading = false
          this.scanResult = response.data.data.results
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          console.log('scanResult==>', this.scanResult)
          this.updatedScanResult(this.scanResult)
          this.handleClose()
        }
      } catch (error) {
        this.isLoading = false
        console.log('error==>', error)
      }
    },
    async patternsScanSubmit(
      settings,
      filters,
      dataSettings,
      events,
      getUserId
    ) {
      const response = await patternsScan(
        settings,
        filters,
        dataSettings,
        events,
        getUserId
      )
      this.isLoading = true
      try {
        if (response) {
          console.log('response==>', response)
          this.isLoading = false
          this.scanResult = response.data.data.results
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          console.log('scan result==>', this.scanResult)
          this.updatedScanResult(this.scanResult)
          this.handleClose()
        }
      } catch (error) {
        console.log('error==>', error)
        this.isLoading = false
      }
    },
    async activityScanSubmit(filters, dataSettings, userId) {
      const response = await activityScan(
        this.activitySettings,
        filters,
        dataSettings,
        userId
      )
      // this.isLoading = true
      try {
        if (response) {
          console.log(
            'response==>',
            response,
            'scanListsResponse==>',
            this.scanListsResponse
          )
          // this.isLoading = false
          this.scanResult = response.data.data.results
          this.scanListsResponse = response.data.data.results
          // console.log("response==>",response)
          // console.log("scanResult==>",this.scanResult)
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          this.updatedScanResult(this.scanResult)
          // this.handleClose()
        }
      } catch (error) {
        this.isLoading = false
        console.log('error==>', error)
      }
    },
    async fundementalScanHandler(filters) {
      const response = await fundementalScan(
        this.fundamentalSettings,
        filters,
        this.dataSettings,
        this.getUserId
      )
      this.isLoading = true
      try {
        if (response) {
          console.log('response==>', response)
          this.isLoading = false
          this.scanResult = response.data.data.results
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          this.updatedScanResult(this.scanResult)
          this.handleClose()
        }
      } catch (error) {
        this.isLoading = false
        console.log('error==>', error)
      }
    },
    async capitulationHandler(settings) {
      const response = await capitulation(
        settings,
        this.dataSettings,
        this.getUserId
      )
      this.isLoading = true
      try {
        if (response) {
          console.log('response==>', response)
          this.isLoading = false
          this.scanResult = response.data.data.results
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          this.updatedScanResult(this.scanResult)
          this.handleClose()
        }
      } catch (error) {
        this.isLoading = false
        console.log('error==>', error)
      }
    },
    async bollingerSqueezeHandler(settings) {
      const response = await bollingerSqueeze(
        settings,
        this.dataSettings,
        this.getUserId
      )
      this.isLoading = true
      try {
        if (response) {
          console.log('response==>', response)
          this.isLoading = false
          this.scanResult = response.data.data.results
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          this.updatedScanResult(this.scanResult)
          this.handleClose()
        }
      } catch (error) {
        this.isLoading = false
        console.log('error==>', error)
      }
    },
    async bigCandleHandler(settings) {
      const response = await bigCandleScan(
        settings,
        this.dataSettings,
        this.getUserId
      )
      this.isLoading = true
      try {
        if (response) {
          console.log('response==>', response)
          this.isLoading = false
          this.scanResult = response.data.data.results
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          this.updatedScanResult(this.scanResult)
          this.handleClose()
        }
      } catch (error) {
        this.isLoading = false
        console.log('error==>', error)
      }
    },
    async heikenAshiSettingHandler(settings) {
      const response = await heikenAshiScan(
        settings,
        this.dataSettings,
        this.getUserId
      )
      this.isLoading = true
      try {
        if (response) {
          console.log('response==>', response)
          this.isLoading = false
          this.scanResult = response.data.data.results
          this.updateScanList({
            name: `Time - D - ${this.formatTime()}`,
            scanList: this.scanResult,
          })
          this.updatedScanResult(this.scanResult)
          this.handleClose()
        }
      } catch (error) {
        this.isLoading = false
        console.log('error==>', error)
      }
    },
    heikenAshi() {
      const heikenAshi = this.selectedScanner.lists.find(
        (item) => item.name == 'Heiken Ashi'
      )
      heikenAshi.data.map((val) => {
        val.inputs.map((inp) => {
          this.heikenAshiSetting[inp.key] = Number(inp.default)
        })
      })

      const updatedObject = {
        ...this.heikenAshiSetting,
        dataSettings: this.dataSettings,
      }

      this.heikenAshiSetting = updatedObject

      this.heikenAshiSettingHandler(this.heikenAshiSetting)
    },
    bigCandle() {
      const bigCandle = this.selectedScanner.lists.find(
        (item) => item.name == 'Big Candle'
      )

      bigCandle.data.map((val) => {
        val.inputs.map((inp) => {
          this.bigCandleSettings[inp.key] = Number(inp.default)
        })
      })

      const updatedObject = {
        ...this.bigCandleSettings,
        dataSettings: this.dataSettings,
      }

      this.bigCandleSettings = updatedObject

      this.bigCandleHandler(this.bigCandleSettings)
    },
    bollingerSqueeze() {
      const bollinger = this.selectedScanner.lists.find(
        (item) => item.name == 'Bollinger Squeeze'
      )
      bollinger.data.map((val) => {
        val.inputs.map((inp) => {
          this.bollingerSqueezeSetting[val.key] = Number(inp.default)
        })
      })
      const updatedObject = {
        ...this.bollingerSqueezeSetting,
        dataSettings: this.dataSettings,
      }
      this.bollingerSqueezeSetting = updatedObject

      this.bollingerSqueezeHandler(this.bollingerSqueezeSetting)
    },
    capitulationFunction() {
      const capi = this.selectedScanner.lists.find(
        (item) => item.name === 'Capitulation Days'
      )
      capi.data.map((val) => {
        val.inputs.map((inp) => {
          if (inp.name === 'volumeMultiplier') {
            this.capitulationSetting.volumeMultiplier = Number(inp.default)
          }
          if (inp.name === 'lastVolumeAvgPeriod') {
            this.capitulationSetting.lastVolumeAvgPeriod = Number(inp.default)
          }
        })
      })
      const updatedObject = {
        ...this.capitulationSetting,
        dataSettings: this.dataSettings,
      }
      this.capitulationSetting = updatedObject

      this.capitulationHandler(this.capitulationSetting)
    },
    fundamental(filters) {
      const fundamnetalSts = this.selectedScanner.lists.find(
        (item) => item.name === 'Measures'
      )
      fundamnetalSts.data.map((val) => {
        val.inputs.map((inp) => {
          if (fundamnetalSts.key === 'Measures') {
            this.fundamentalSettings[val.key].isActive = val.isSelected
            this.fundamentalSettings[val.key].type = val.selectedRadio
            if (this.fundamentalSettings[val.key].value && inp.default) {
              this.fundamentalSettings[val.key].value = inp.default
            }
          }
        })
      })

      const capitalisation = this.selectedScanner.lists.find(
        (item) => item.name === 'Capitalisation'
      )

      capitalisation.data.map((val) => {
        val.inputs.map((inp) => {
          if (capitalisation.key === 'capitalisation') {
            this.fundamentalSettings[capitalisation.key][val.key].isActive =
              val.isSelected
            if (this.fundamentalSettings[capitalisation.key][val.key].typ) {
              this.fundamentalSettings[capitalisation.key][val.key].type =
                val.selectedRadio
            }
            if (
              this.fundamentalSettings[capitalisation.key][val.key].value &&
              inp.default
            ) {
              this.fundamentalSettings[capitalisation.key][val.key].value =
                inp.default
            }
          }
        })
      })

      const returns = this.selectedScanner.lists.find(
        (item) => item.name === 'Returns'
      )

      returns.data.map((val) => {
        val.inputs.map((inp) => {
          if (returns.key === 'returns') {
            this.fundamentalSettings['returns'].isActive = val.isSelected
            if (this.fundamentalSettings['returns'].typ) {
              this.fundamentalSettings['returns'].type = val.selectedRadio
            }
            if (this.fundamentalSettings['returns'].value && inp.default) {
              this.fundamentalSettings['returns'].value = inp.default
            }
            if (
              this.fundamentalSettings['returns'].value &&
              inp.selectedOption
            ) {
              this.fundamentalSettings['returns'].month = inp.selectedOption
            }
          }
        })
      })

      this.fundementalScanHandler(filters)
    },
    darvasBox(filters, dataSettings) {
      const darvaxBox = this.selectedScanner.lists.find(
        (item) => item.name === 'Darvas Box'
      )
      darvaxBox.data.map((val) => {
        val.inputs.map((inp) => {
          if (inp.isActive === 'isActive') {
            this.darvasSettings[val.key].isActive = val.isSelected
          }
          if (val.key == 'scanType') {
            this.darvasSettings.scanType = inp.selectedOption
          }
          if (val.key == 'scanPeriod') {
            this.darvasSettings.scanPeriod = Number(inp.default)
          }
          if (val.key == 'topConfirmationPeriod') {
            this.darvasSettings.topConfirmationPeriod = Number(inp.default)
          }
          if (val.key == 'bottomConfirmationPeriod') {
            this.darvasSettings.bottomConfirmationPeriod = Number(inp.default)
          }
          if (val.key == 'percentageRiskBuffer') {
            this.darvasSettings.percentageRiskBuffer = Number(inp.default)
          }
          if (val.key === 'limitTotalRisk') {
            this.darvasSettings[val.key].valuePct = Number(inp.default)
          }
          if (val.key === 'limitTotalRisk') {
            this.darvasSettings[val.key].valuePct = Number(inp.default)
          }
          if (val.key === 'volumeIncreasedOver') {
            if (inp.name === 'percent') {
              this.darvasSettings[val.key].percent = Number(inp.default)
            }
            if (inp.name === 'previousBars')
              this.darvasSettings[val.key].previousBars = Number(inp.default)
          }
          if (val.key === 'inBox') {
            this.darvasSettings[val.key].type = val.selectedRadio
          }
          if (val.key === 'within') {
            this.darvasSettings[val.key].type = val.selectedRadio
            if (inp.name == 'pricePoints') {
              this.darvasSettings[val.key].pricePoints = Number(inp.default)
            }
          }
          if (val.key == 'restrictBoxToMaxBox') {
            this.darvasSettings[val.key].maxBoxes = Number(inp.default)
          }
          if (val.key == 'restrictCurrentBoxWidth') {
            if (inp.name == 'start') {
              this.darvasSettings[val.key].start = Number(inp.default)
            }
            if (inp.name == 'end') {
              this.darvasSettings[val.key].end = Number(inp.default)
            }
          }
        })
      })
      this.darvasBoxScanHandler(filters, dataSettings)
      console.log('darvasSettings==>', this.darvasSettings)
    },
    activity(filters, dataSettings, userId) {
      const activity = this.selectedScanner.lists.find(
        (item) => item.name === 'Activity'
      )
      activity.data.map((val) => {
        val.inputs.map((inp) => {
          this.activitySettings.mode = activity.parentSelectedRadioButton
          if (inp.name == 'priceRangeEnd') {
            this.activitySettings.priceRangeEnd = Number(inp.default)
          }
          if (inp.name == 'priceRangeStart') {
            this.activitySettings.priceRangeStart = Number(inp.default)
          }
          if (val.name == 'top') {
            this.activitySettings.topMethod = inp.selectedOption
          }
        })
      })

      this.activityScanSubmit(filters, dataSettings, userId)
    },
    technicalIndicator() {
      let settings = {}
      this.selectedScanner.lists.map((list) => {
        if (list.name == 'Teahnical Indicators') {
          list.data.map((val) => {
            const everyObjectHaveAKey = val.key
            settings[everyObjectHaveAKey] = { isActive: false }

            val.inputs.map((inp) => {
              if (inp.isActive == 'isActive') {
                settings[everyObjectHaveAKey][inp.isActive] = val.isSelected
              }
              if (inp.period == 'period') {
                settings[everyObjectHaveAKey][inp.period] = inp.default
              }
              if (val.breakMode == 'breakMode') {
                settings[everyObjectHaveAKey][val.breakMode] = val.selectedRadio
              }
              if (val.mode == 'mode') {
                settings[everyObjectHaveAKey][val.mode] = val.selectedRadio
              }
              if (inp.kPercent == 'kPercent') {
                settings[everyObjectHaveAKey][inp.kPercent] = inp.default
              }
              if (inp.dPercent == 'dPercent') {
                settings[everyObjectHaveAKey][inp.dPercent] = inp.default
              }
            })
          })
        }
        return list
      })

      settings.crossesAndAboveBelow = settings.crossesAndAboveBelow = {
        ...settings.crossesAndAboveBelow,
        left: 'close',
        leftPeriod: 14,
        operatorType: 'above',
        right: 'ma',
        rightPeriod: 14,
      }

      return settings
    },
    getFilterLikePt() {
      const filters = {}
      this.selectedScanner.lists.map((item) => {
        if (item.name == 'Filter') {
          item.data.map((val) => {
            const key = val.key
            if (val.key == 'isExcludeIntraDayData') {
              filters[key] = val.isSelected
            } else if (val.key == 'isIgnoreResultsWithNoRangeForLastDay') {
              filters[key] = val.isSelected
            } else {
              filters[key] = { isActive: false }
            }

            val.inputs.map((input) => {
              if (input.isActive === 'isActive') {
                if (typeof filters[key] === 'object') {
                  filters[key][input.isActive] = val.isSelected
                }
              }
              if (input.key) {
                filters[key][input.key] = input.default
              }
            })
          })
        }
      })
      this.setFilters = filters
      return filters
    },
    getEventsLikePt() {
      const events = {}

      this.selectedScanner.lists.map((list) => {
        if (list.name === 'Events') {
          list.data.map((val) => {
            const valKey = val.key

            if (valKey === 'matchCombination') {
              // Directly assign the boolean value for matchCombination
              events[valKey] = val.isSelected
            } else {
              // Ensure events[valKey] is an object
              events[valKey] = {}
            }

            val.inputs.map((input) => {
              if (input.isActive === 'isActive') {
                // Check if events[valKey] is an object before assigning properties
                if (typeof events[valKey] === 'object') {
                  events[valKey][input.isActive] = val.isSelected
                }
              }
              if (input.period === 'period') {
                if (typeof events[valKey] === 'object') {
                  events[valKey][input.period] = input.default
                }
              }

              if (input.key === 'direction') {
                if (typeof events[valKey] === 'object') {
                  events[valKey][input.key] = input.selectedOption
                }
              }
              if (val.key === 'tciBreak') {
                if (typeof events[valKey] === 'object') {
                  events[valKey][val.direction] = val.selectedRadio
                }
              }
              if (val.key === 'tciTrend') {
                if (typeof events[valKey] === 'object') {
                  events[valKey][val.direction] = val.selectedRadio
                }
              }
              if (val.key === 'volumeMore') {
                if (typeof events[valKey] === 'object') {
                  events[valKey][input.key] = input.default
                }
              }
              if (val.key === 'gapDown') {
                if (typeof events[valKey] === 'object') {
                  events[valKey][input.cents] = input.default
                }
              }
              if (val.key === 'gapUp') {
                if (typeof events[valKey] === 'object') {
                  events[valKey][input.cents] = input.default
                }
              }
              if (val.key === 'obv') {
                if (typeof events[valKey] === 'object') {
                  events[valKey][input.key] = input.default
                }
              }
              if (val.key === 'macd') {
                if (typeof events[valKey] === 'object') {
                  if (input.name == 'fastPeriod') {
                    events[valKey][input.key] = input.default
                  }
                  if (input.name == 'signalPeriod') {
                    events[valKey][input.key] = input.default
                  }
                  if (input.name == 'slowPeriod') {
                    events[valKey][input.key] = input.default
                  }
                }
              }
              if (val.key === 'break') {
                if (typeof events[valKey] === 'object') {
                  events[valKey][input.payloadType] = input.selectedOption
                }
              }
              if (val.key === 'prebreak') {
                if (typeof events[valKey] === 'object') {
                  events[valKey][input.key] = input.default
                  events[valKey][input.payloadType] = input.selectedOption
                }
              }
            })
            return val
          })
        }
      })

      for (let key in events) {
        if (events[key].hasOwnProperty('undefined')) {
          delete events[key]['undefined']
        }
      }
      this.setEvents = events
      return events
    },
    getSettingsLikePt() {
      const arr = this.selectedScanner.lists
      let settings = {}
      const dataForApi = arr.map((item) => {
        if (item.name === 'Charting Patterns') {
          return item.data.map((val) => {
            return (settings[val.key] = val.isSelected)
          })
        } else if (item.name === 'Candlestick') {
          return item.data.map((val) => {
            return (settings[val.key] = val.isSelected)
          })
        }
        return item
      })
      this.setSettings = settings
      return settings
    },
    eventSelected(data, listId) {
      // console.log("data==>",data,listId);
      const arr = this.selectedScanner.lists
      let updatedArr = arr.map((val) => {
        if (val.id === listId) {
          return {
            ...val,
            data: val.data.map((value) => {
              if (data.id === value.id) {
                return {
                  ...value,
                  isSelected: !value.isSelected,
                }
              }
              return value
            }),
          }
        }
        return val
      })
      console.log('arr', arr)

      this.selectedScanner = {
        ...this.selectedScanner,
        lists: updatedArr,
      }
    },
    handleSvgCondional(data, listId) {
      //   console.log('hello borther!', data, listId)
      this.selectedScannerHandler(data, listId)
    },
    clearAll(list, index) {
      console.log('detail with clearAll', list, index)
      const arr = this.selectedScanner.lists
      let updatedArr = arr.map((val) => {
        if (val.id === index) {
          return {
            ...val,
            data: val.data.map((v) => {
              if (v.hasOwnProperty('isSelected')) {
                return {
                  ...v,
                  isSelected: false,
                }
              }
              return v
            }),
          }
        }
        return val
      })

      this.selectedScanner = {
        ...this.selectedScanner,
        lists: updatedArr,
      }
    },
    selectAll(list, index) {
      console.log(
        'this.selectedScanner.lists before==>',
        this.selectedScanner.lists
      )

      const updatedArr = this.selectedScanner.lists.map((val) => {
        if (val.id === index) {
          return {
            ...val,
            data: val.data.map((v) => {
              // Only add isSelected if it already exists
              if (v.hasOwnProperty('isSelected')) {
                console.log('inside v==>', v)

                return {
                  ...v,
                  isSelected: true,
                }
              }
              return v
            }),
          }
        }
        return val
      })

      this.selectedScanner = {
        ...this.selectedScanner,
        lists: updatedArr,
      }

      console.log(
        'this.selectedScanner.lists after==>',
        this.selectedScanner.lists
      )
    },
    hideShowSvg(list) {
      this.toggleList(list)
    },
    getSvg(name, payload) {
      return getSvgByName(name, payload)
    },
    handleClose() {
      console.log('close==>')
      this.$emit('handleCloseEmit')
    },
    handleScanner(payload) {
      console.log('selectedbutton before', this.selectedButton, payload)
      if (payload.name == 'Fundamental') {
        console.log('fundamental==>')
        this.selectedScanner = payload
        this.selectedButton = 'Measures'
      } else {
        this.selectedButton = payload.name
        localStorage.setItem('selectedButtonName', JSON.stringify(payload.name))
        console.log('selectedbutton after', this.selectedButton, payload)
        this.selectedScanner = payload
      }
    },
    toggleList(item) {
      let arr = this.selectedScanner?.lists
      let updatedArr = arr?.map((val) => {
        if (val?.id == item?.id) {
          return {
            ...val,
            isToggle: !val?.isToggle,
          }
        }
        return val
      })
      this.selectedScanner = {
        ...this.selectedScanner,
        lists: updatedArr,
      }
    },
    selectedScannerHandler(param, index) {
      const arr = this.selectedScanner.lists
      let updated = arr.map((item) => {
        if (item.id === index) {
          return {
            ...item,
            data: item.data.map((d) => {
              if (param.name === d.name) {
                const newIsSelected = !d.isSelected
                return {
                  ...d,
                  isSelected: newIsSelected,
                  // [d.key]: newIsSelected,
                }
              }
              return d
            }),
          }
        }
        return item
      })

      this.selectedScanner = {
        ...this.selectedScanner,
        lists: updated,
      }
    },
    formatTime() {
      const date = new Date()
      let hours = date.getHours().toString().padStart(2, '0')
      let minutes = date.getMinutes().toString().padStart(2, '0')
      let seconds = date.getSeconds().toString().padStart(2, '0')

      return `${hours}:${minutes}:${seconds}`
    },
  },
  mounted() {
    let selectedData = JSON.parse(localStorage.getItem('selectedScanner'))
    this.selectedScanner = selectedData
    this.handleButton()
    this.selectedlist = this.selectedScanner[0].lists[0]
  },
  watch: {
    progress(newVal) {
      // console.log('Progress changed:', newVal)
    },
  },
}
</script>

<style scoped>
input[type='radio'] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #110024;
  border-radius: 50%;
  margin-right: 10px;
  background-color: transparent;
  position: relative;
  top: 0px;
}

@media screen and (max-width: 450px) {
  input[type='radio'] {
    width: 10px;
    height: 10px;
    top: 2px;
  }
}
input[type='radio']:checked::before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  background-color: #110024;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: appear 0.3s;
}

@keyframes appear {
  0% {
    transform: translate(-50%, -50%) scale(0);
    background-color: #110024;
  }
  45% {
    transform: translate(-50%, -50%) scale(1.6);
    background-color: #110024;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.7);
    background-color: #110024;
  }
  55% {
    transform: translate(-50%, -50%) scale(1.6);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    background-color: #110024;
  }
}

.radio-no-outline:focus {
  outline: none;
  box-shadow: none;
}
</style>
