<template>
  <div class="w-full h-full flex justify-between px-8 bg-white">
    <div class="flex items-center gap-2">
      <span>
        <svg
          width="28"
          height="20"
          viewBox="0 0 24 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.3671 1.78109C23.5591 1.62109 23.6528 1.41797 23.6528 1.17172C23.6528 0.925469 23.5591 0.722344 23.3671 0.562344C23.1751 0.402344 22.9313 0.324219 22.6358 0.324219H1.25183C0.956331 0.324219 0.712582 0.402344 0.520582 0.562344C0.328581 0.722344 0.234831 0.925469 0.234831 1.17172C0.234831 1.41797 0.328581 1.62109 0.520582 1.78109C0.712582 1.94109 0.956331 2.01922 1.25183 2.01922H22.6358C22.9313 2.01922 23.1751 1.94109 23.3671 1.78109ZM0.520582 7.35172C0.328581 7.51172 0.234831 7.71484 0.234831 7.96109C0.234831 8.20734 0.328581 8.41047 0.520582 8.57047C0.712582 8.73047 0.956331 8.80859 1.25183 8.80859H22.6358C22.9313 8.80859 23.1751 8.73047 23.3671 8.57047C23.5591 8.41047 23.6528 8.20734 23.6528 7.96109C23.6528 7.71484 23.5591 7.51172 23.3671 7.35172C23.1751 7.19172 22.9313 7.11359 22.6358 7.11359H1.25183C0.956331 7.11359 0.712582 7.19172 0.520582 7.35172ZM0.520582 14.1367C0.328581 14.2967 0.234831 14.4998 0.234831 14.7461C0.234831 14.9923 0.328581 15.1955 0.520582 15.3555C0.712582 15.5155 0.956331 15.5936 1.25183 15.5936H22.6358C22.9313 15.5936 23.1751 15.5155 23.3671 15.3555C23.5591 15.1955 23.6528 14.9923 23.6528 14.7461C23.6528 14.4998 23.5591 14.2967 23.3671 14.1367C23.1751 13.9767 22.9313 13.8986 22.6358 13.8986H1.25183C0.956331 13.8986 0.712582 13.9805 0.520582 14.1367Z"
            fill="black"
          />
        </svg>
      </span>
      <span class="text-[18px] font-[700]">Menu</span>
    </div>
    <div
      class="cursor-pointer flex justify-center items-center"
      @click="ShowNavDropDownClose"
    >
      <BackgroundHover>
        <span>
          <svg
            width="30"
            height="30"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.25 6.75L6.75 19.25"
              stroke="#BCBCBC"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.75 6.75L19.25 19.25"
              stroke="#BCBCBC"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </BackgroundHover>
    </div>
  </div>
</template>
<script>
import BackgroundHover from '../../component/BackgroundHover.vue'
export default {
  name: 'App',
  data() {
    return {}
  },
  components: {
    BackgroundHover,
  },
  methods: {
    ShowNavDropDownClose() {
      this.$emit('ShowNavDropDownClose')
    },
  },
  computed: {},
  mounted() {},
  watch: {},
}
</script>
<style scoped></style>
