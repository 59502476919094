<template>
  <div class="flex flex-col w-ful h-full">
    <div class="flex flex-row gap-[8px]">
      <div
        @click="handleButton(index, button?.name)"
        :class="
          selectedButton === index
            ? 'bg-[#001A2C] text-white'
            : 'bg-[#e2e2e2] text-[#3d3d3d]'
        "
        class="px-[24px] flex justify-between items-center py-[8px] gap-2 rounded-[22px] cursor-pointer"
        v-for="(button, index) in scannerNameList?.tabs"
        :key="index"
      >
        <span>{{ button?.name }}</span>
        <span
          class="flex items-center justify-center w-6 h-6 rounded-full text-sm"
          :class="
            selectedButton === index
              ? 'bg-white text-gray-900'
              : 'bg-gray-800 text-white'
          "
        >
          {{ getTotal(button?.name) ?? 1 }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ButtonTabs',

  data() {
    return {
      selectedButton: 0,
      scannerType: 'ChartingPatterns',
    }
  },
  props: {
    buttonsProps: {
      type: Array,
      default: [],
    },
    id: {
      type: String,
      default: '',
    },
    // selectedButtonTypeName: String,
    scannerNameList: {
      type: Object,
      default: {},
    },
  },
  methods: {
    handleButton(index, btnName) {
      // const { name, index } = button
      this.selectedButton = index
      this.$emit('selected-button', btnName)
      console.log('name===>', button, this.scannerNameList)
    },

    getTotal(name) {
      if (name == 'Charting Patterns') {
        let obj = this.getPatternSettings(this.id)
        console.log('activity object====>',obj);
        let len = Object.keys(obj).filter((key) => obj[key] == true)?.length
        return len
      }
      if (name == 'Candlestick') {
        let obj = this.getPatternSettings(this.id)
        let len = Object.keys(obj).filter((key) => obj[key] == true)?.length
        return len
      }
      if (name == 'Activity') {
        let obj = this.getPatternSettings(this.id)
        console.log('activity object====>',obj);
        return
        const countTrueValues = (obj) =>
          Object.entries(obj).reduce((count, [key, value]) => {
            if (typeof value === 'object') {
              return count + (value?.isActive === true ? 1 : 0) // Check if isActive is true
            } else {
              return count + (value === true ? 1 : 0) // Count root-level true values
            }
          }, 0)

        const trueCount = countTrueValues(obj)
        return trueCount
      }
      if (name == 'Technical Indicators') {
        let obj = this.getPatternSettings(this.id)
        const countTrueValues = (obj) =>
          Object.entries(obj).reduce((count, [key, value]) => {
            if (typeof value === 'object') {
              return count + (value?.isActive === true ? 1 : 0) // Check if isActive is true
            } else {
              return count + (value === true ? 1 : 0) // Count root-level true values
            }
          }, 0)

        const trueCount = countTrueValues(obj)
        return trueCount
      }
      if (name == 'Events') {
        let obj = this.getEventsSettings(this.id)
        console.log('Events object====>',obj);
        const countTrueValues = (obj) =>
          Object.entries(obj).reduce((count, [key, value]) => {
            if (typeof value === 'object') {
              return count + (value?.isActive === true ? 1 : 0) // Check if isActive is true
            } else {
              return count + (value === true ? 1 : 0) // Count root-level true values
            }
          }, 0)

        const trueCount = countTrueValues(obj)
        return trueCount
      }
      if (name == 'Filters') {
        let obj = this.getFilterSettings(this.id)
        const countTrueValues = (obj) =>
          Object.entries(obj).reduce((count, [key, value]) => {
            if (typeof value === 'object') {
              return count + (value?.isActive === true ? 1 : 0) // Check if isActive is true
            } else {
              return count + (value === true ? 1 : 0) // Count root-level true values
            }
          }, 0)

        const trueCount = countTrueValues(obj)
        return trueCount
      }
    },
  },
  computed: {
    ...mapGetters([
      'getPatternSettings',
      'getEventsSettings',
      'getFilterSettings',
    ]),
  },

  watch: {},

  mounted() {},
}
</script>

<style></style>
