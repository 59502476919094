<script>

import { Overlay } from '../../@trading-vue-js/src/index'

export default {
    name: 'SMAX',
    mixins: [Overlay],
    methods: {
      meta_info() {
        return {
          author: 'StdSquad', version: '1.0.0',
          desc: 'Simple Moving Average',
          preset: {
            name: 'SMA',
            side: 'onchart',
            settings: {
              lineWidth: 1,
              color: '#000cff',
              lineType: 'solid'
            }
          }
        }
      },
      /*draw(ctx) {
        ctx.lineWidth = this.line_width
        ctx.strokeStyle = this.color
        ctx.beginPath()

        const layout = this.$props.layout
        const i = this.data_index
        const data = this.$props.data

        if (!this.skip_nan) {
          for (var k = 0, n = data.length; k < n; k++) {
            let p = data[k]
            let x = layout.t2screen(p[0])
            let y = layout.$2screen(p[i])
            ctx.lineTo(x, y)
          }
        } else {
          var skip = false
          for (var k = 0, n = data.length; k < n; k++) {
            let p = data[k]
            let x = layout.t2screen(p[0])
            let y = layout.$2screen(p[i])
            if (p[i] == null || y !== y) {
              skip = true
            } else {
              if (skip) ctx.moveTo(x, y)
              ctx.lineTo(x, y)
              skip = false
            }
          }
        }
        ctx.stroke()
      },*/
      use_for() {
        return ['SMAX']
      },
      calc() {
        return {
          props: {
            period: {def: 20, text: 'Days'}
          },
          conf: { renderer: 'SplineExt' },
          update: `
          // console.log("calc close",close,period)
                    return sma(close, period)[0]
                `
        }
      }
    }
}
</script>