<template>
  <div class="w-full h-full">
    <div
      v-if="selectedRight === 'watchlist' || selectedRight === 'scanner'"
      class="w-full h-auto -bg-black"
    >
      <div
        v-for="(item, index) in symbols"
        :key="index"
        class="w-full h-[4rem] flex border-b items-center justify-between cursor-default hover:bg-[#f1f1f1] -bg-slate-400 px-3"
      >
        <!-- Icon Brand Name -->
        <div class="w-[30%] flex justify-start items-center gap-2">
          <!-- Brand Icon -->
          <div
            class="w-[2.5rem] h-[2.5rem] rounded-full text-white text-[18px] bg-[red] flex justify-center items-center"
          >
            {{ item.name[0] }}
          </div>
          <!-- Brand Name -->
          <div class="w-[70%] h-full flex flex-col -!bg-black">
            <span>{{ item.name }}</span>
            <span>{{ item.longName }}</span>
          </div>
        </div>

        <!-- Candles -->
        <div
          v-if="selectedRight === 'scanner'"
          class="w-[25%] h-[50%] px-4 py-2 rounded-xl bg-[#EFEFEF] flex justify-center items-center"
        >
          Doji
        </div>

        <!-- Price -->
        <div class="w-[45%] flex flex-col items-end justify-center">
          <span class="text-[#131722] text-[14px] font-[600]">{{
            item.price
          }}</span>
          <span class="text-[green] text-[14px] font-[600]">
            {{ item.change }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RightBarHead from './RightBarHead.vue'
export default {
  name: 'App',
  data() {
    return {
      showBtn: false,
      symbols: [
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
      ],
    }
  },
  components: {
    RightBarHead,
  },
  props: {
    selectedRight: String,
  },
  methods: {},
  computed: {},
  mounted() {},
  watch: {},
}
</script>
<style scoped></style>
