<template>
  <div class="w-full h-full pb-0 -bg-red-400">
    <div v-if="scanResults.length > 0" class="w-full h-full -bg-black">
      <div
        v-for="(item, index) in scanResults[scanResults?.length - 1]?.results"
        :key="index"
        class="w-full h-[4rem] flex border-b items-center justify-between cursor-default hover:bg-appBtnHover -bg-slate-400 xxs:px-2 xs:px-3"
      >
        <!-- Icon Brand Name -->
        <div class="w-[30%] flex justify-start items-center gap-2">
          <!-- Brand Icon -->
          <div
            class="min-w-[2.5rem] min-h-[2.5rem] max-w-[2.5rem] max-h-[2.5rem] rounded-full text-white text-[18px] bg-[red] flex justify-center items-center"
          >
            <TickerIcon :ticker="item.symbol" />
          </div>
          <!-- Brand Name -->
          <div
            class="xxs:min-w-[3.5rem] xs:min-w-[4.5rem] -bg-slate-400 h-full flex flex-col -!bg-black"
          >
            <span
              @click="handleSymbolPress(item.symbol)"
              class="xxs:text-[11px] cursor-pointer hover:underline xs:text-[14px]"
              >{{ item.symbol }}</span
            >
            <span class="xxs:text-[10px] xs:text-[12px]">Tesla Inc</span>
          </div>
        </div>

        <!-- Candles -->
        <div
          class="-w-[25%] w-auto h-[50%] xxs:px-3 xs:px-4 py-2 rounded-xl bg-[#dbd6d6] flex justify-center items-center"
        >
          {{ item.patternName || "Not Found" }}
        </div>

        <!-- Price -->
        <div class="flex flex-col items-end justify-center">
          <span
            class="text-[#131722] xxs:!text-[11px] qm:text-[14px] font-[400]"
            >{{ item.candleData.close }}
          </span>
          <span class="text-[green] xxs:!text-[11px] qm:text-[14px] font-[400]">
            +9.66 (4.59%)
          </span>
        </div>
      </div>
    </div>
    <!-- @click="handleRun" -->
    <div
      v-else
      class="flex h-[20rem] w-full justify-center items-center text-center"
    >
      <div>
        <NoData title="Run scanners to see results." />
      </div>
      <div>
        <span
          @click="handleRun"
          class="underline cursor-pointer text-[1.5rem] text-appBlue font-medium hover:text-darkBlue pl-2"
          >Run</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { chartEvents } from '../../../chartEvents'
import TickerIcon from '../../TickerIcon.vue'
import NoData from '../../NoData.vue'
export default {
  name: 'App',
  data() {
    return {
      showBtn: false,
      symbols: [
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
        {
          name: 'TSLA',
          longName: 'Tesla Inc',
          price: '$220.32',
          change: '+9.66 (4.59%)',
        },
      ],
    }
  },
  components: {
    TickerIcon,
    NoData,
  },
  props: {
    selectedRight: String,
  },
  methods: {
    handleSymbolPress(sym) {
      chartEvents.$emit('load-symbol', sym)
    },

    handleRun() {
      chartEvents.$emit('handleRun')
      console.log('handleRun scanner result')
    },
  },
  computed: {
    ...mapGetters(['scanResults']),

    scannerSymbols() {
      return this.getScannerResult
    },
  },
  mounted() {
    console.log('scannerSymbols', this.scanResults, this.getScannerResult)
  },
  watch: {},
}
</script>
<style scoped></style>
