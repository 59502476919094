<template>
    <div>
        <div v-for="(item,index) in list" :key="index">
           <h1>Hello Dimensionals {{ item?.id }}</h1>
        </div>
    </div>
    
</template>

<script>
  export default {
    name:"chart-data-components",
    data() {
        return {
        }
    },
    props: {
        list: Array
    },
    components:{
    },
    computed: {
    },
    methods:{
    },
    mounted() {
        console.log("Recently list==>",this.list);
    },

    watch:{
    }
  };
  </script>