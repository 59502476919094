<template>
  <div
    class="smallResendEmail"
    style="
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10rem;
    "
  >
    <div style="width: 35%" class="resend-container flex flex-col gap-4">
      <div style="display: flex; flex-direction: column">
        <div>
          <span class="errorText" style="color: #300003; font-size: 14px">
            Didn't Receive?</span
          >
          <span
            @click="handleResend()"
            class="errorText text-appBlue"
            :style="
              isResendLoading
                ? 'pointer-events: none;color:gray'
                : 'pointer-events: auto;'
            "
            style="text-decoration: underline; cursor: pointer"
            > Resend Email</span
          >
        </div>
        <div
          class="formSevenDayTrial"
          style="
            border: 1px solid #f20e17;
            font-size: 18px;
            color: #f20e17;
            width: fit-content;
            border-radius: 5px;
            padding: 5px 15px 5px 15px;
            font-weight: 500;
            display: none;
            margin: 20px 0px 20px 0px;
          "
        >
          3-MONTHS FREE TRIAL
        </div>
        <div
          class="mediumText"
          v-if="isResponseMessage"
          style="font-size: 16px; margin-top: 2px; color: #300003"
        >
          {{ isResponseMessage }}
        </div>
      </div>
      <div style="margin: 1% 0 1% 0">
        <span
          class="mobileLargeText leading-[50px]"
          style="color: #300003; font-size: 50px; font-weight: bold"
          >Welcome to ProTrading Scans, Please
          <span class="text-appBlue">verify</span> your email</span
        >
      </div>
      <div style="color: #300003; font-size: 16px" class="emailSentDiv">
        We’ve sent you an email with a verification link, please check your
        inbox.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResendEmail',
  props: {
    isResendLoading: {
      type: {
        type: Boolean,
        default: false,
      },
    },
    isResponseMessage: {
      type: {
        type: String,
        default: '',
      },
    },
  },
  data() {
    return {}
  },
  methods: {
    handleResend() {
      this.$emit('resendEmail')
    },
  },
}
</script>

<style scoped></style>
