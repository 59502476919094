<template>
  <!-- bg hover #2D2D2D -->
  <!-- bg normal #1A1A1A -->
  <!-- title text #e9ecf8 -->
  <!-- subTitle text #858a97 -->
  <!-- price text #34c244 -->
  <!-- border #404248 -->
  <!-- text #6C707A -->

  <div class="w-full h-[100%] bg-white dark:!bg-[#1A1A1A] relative">
    <div
      v-if="isWatchlist"
      class="w-full -border dark:!border-[#1A1A1A] h-[100%] duration-500"
    >
      <div
        class="flex w-full py-2 justify-between items-center px-[12px] border-b border-[#DEDEDE] dark:!border-[#404248]"
      >
        <p
          class="text-[#323232] font-sans dark:text-[#dedede] text-[12px] font-medium"
        >
          You have {{ watchListData.length }} watchlists.
        </p>
        <div
          @click="handleAddWatchlist"
          class="cursor-pointer w-auto h-auto mr-[-2px]"
          :class="watchListData.length < 10 ? '' : 'hidden'"
          v-html="getSvg('addicon')"
        ></div>
      </div>
      <div
        class="w-full flex items-center gap-3 py-2.5 border-b border-[#D1D1D1] dark:!border-[#404248] px-[13px]"
      >
        <div
          class="w-[100%] h-[36px] border bg-[#F6F6F6] border-[#DEDEDE] shadow-sm dark:!bg-[#1A1A1A] dark:!border-[#404248] rounded-[10px] px-[13px] flex items-center"
        >
          <div
            class="w-1/12 h-full text-[#6D6D6D] flex justify-center items-center cursor-pointer"
            v-html="getSvg('searchicon')"
          ></div>
          <input
            class="text-[#6D6D6D] placeholder:!text-[#6D6D6D] placeholder:font-[500] bg-transparent dark:!text-[#dedede] tracking-widest text-[12px] focus:outline-none border-none focus:ring-0 font-medium w-10/12 px-3 h-[80%]"
            v-model="searchWatchlist"
            placeholder="Search watchlist"
          />
        </div>
        <div
          class="cursor-pointer w-8 h-8 mr-[-1px] flex justify-center items-center"
          v-html="getSvg('croseicon')"
        ></div>
      </div>
      <div :class="size=='half'?'h-[65%]':'h-[88%]'" class="w-full overflow-y-auto scroll-container pb-[2rem]">
        <div
          class="flex w-full pt-3 pb-2.5 justify-between dark:hover:!bg-[#2D2D2D] hover:bg-[#E4E4E460] items-center px-[13px] border-b border-[#DEDEDE] dark:!border-[#404248]"
          v-for="(item, ind) in filteredWatchlist"
          :key="ind"
        >
          <div
            class="w-[50%] h-[51px] flex flex-col leading-[18px] justify-center -bg-black"
          >
            <div class="flex gap-2 -bg-gray-400">
              <p
                @click="openSymbolListModal(item)"
                class="text-[#000000] font-sans hover:underline cursor-pointer dark:text-[#dedede] text-[15px] font-[550]"
              >
                {{ item?.watchlistName }}
              </p>
              <div
                @click="openEditWatchlistModal(item)"
                class="cursor-pointer -border hover:bg-[#e4e4e4] dark:hover:!bg-[#2D2D2D] rounded-md"
                v-html="getSvg('editicon')"
              ></div>
            </div>
            <p
              class="text-[13px] text-[#464646] font-sans font-normal dark:!text-[#b3b2b2]"
            >
              {{ item?.symbols?.length }} symbols
            </p>
            <p
              class="text-[11px] whitespace-nowrap font-sans font-normal text-[#7C7C7C]"
            >
              Last updated: {{ getRelativeTime(item?.updatedTime) }}
            </p>
          </div>
          <div class="w-auto h-[44px] flex justify-center items-center gap-2">
            <div
              @click="openDownloadSymbolModal(item)"
              title="Download symbols"
              :class="
                item?.symbols?.length == 0
                  ? 'pointer-events-none opacity-30 dark:!text-[#a4abb9] dark:!opacity-50'
                  : 'cursor-pointer'
              "
              v-html="getSvg('downloadiconb')"
            ></div>
            <div
              @click="openRemoveWatchlistModal(item)"
              class="cursor-pointer"
              title="Remove watchlist"
              v-html="getSvg('croseiconb')"
            ></div>
          </div>
        </div>
        <div
          v-if="watchListData.length < 10"
          class="w-full py-3 flex gap-4 px-[15px] justify-start dark:!border-[#404248] border-b items-center"
        >
          <div @click="handleAddWatchlist" class="cursor-pointer">
            <svg
              width="30"
              height="31"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 30.5C7.92892 30.5 4.39339 30.5 2.1967 28.3032C-1.78814e-07 26.1066 0 22.571 0 15.5C0 8.42892 -1.78814e-07 4.89339 2.1967 2.6967C4.39339 0.5 7.92892 0.5 15 0.5C22.071 0.5 25.6066 0.5 27.8032 2.6967C30 4.89339 30 8.42892 30 15.5C30 22.571 30 26.1066 27.8032 28.3032C25.6066 30.5 22.071 30.5 15 30.5ZM15 9.875C15.6213 9.875 16.125 10.3787 16.125 11V14.375H19.5C20.1213 14.375 20.625 14.8787 20.625 15.5C20.625 16.1213 20.1213 16.625 19.5 16.625H16.125V20C16.125 20.6213 15.6213 21.125 15 21.125C14.3787 21.125 13.875 20.6213 13.875 20V16.625H10.5C9.87868 16.625 9.375 16.1213 9.375 15.5C9.375 14.8787 9.87868 14.375 10.5 14.375H13.875V11C13.875 10.3787 14.3787 9.875 15 9.875Z"
                fill="#BCBCBC"
              />
            </svg>
          </div>
          <div class="flex flex-col">
            <span
              class="text-[#000000] dark:text-[#dedede] text-[17px] font-[500]"
              >Add new watchlist.</span
            >
            <span class="text-[#6D6D6D] -mt-1 text-[11px] font-normal">{{
              `You can add ${10 - watchListData.length} more watchlists.`
            }}</span>
          </div>
        </div>
        <div
          v-else
          class="w-full py-3 flex flex-col leading-6 dark:!border-[#404248] justify-center border-b items-center"
        >
          <span
            class="text-[#000000] dark:bg-[#1A1A1A] dark:text-[#F6F6F6] text-[17px] font-[500]"
            >Maximum Watchlists reached.</span
          >
          <span class="text-[#6D6D6D] -mt-1 text-[11px] font-normal"
            >You can add up to 10 Watchlists.</span
          >
        </div>
      </div>
    </div>
    <div v-else class="w-full h-[100%]">
      <div class="w-full border-b dark:!border-[#404248] flex justify-between">
        <div class="w-[100%] flex justify-between py-0.5 px-[11px]">
          <div class="flex space-x-4 items-center">
            <span @click="handleBack" class="cursor-pointer"
              ><svg
                class="dark:!text-[#6C707A] text-[#B1B0B0]"
                width="26"
                height="26"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5L6.75 1.5C3 1.5 1.5 3 1.5 6.75L1.5 11.25C1.5 15 3 16.5 6.75 16.5Z"
                  fill="currentColor"
                />
                <path
                  d="M9.94466 11.6485L7.30469 9.00101L9.94466 6.35352"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span class="text-[#000000] dark:text-[#dedede]"
              >{{ selectedWatchlist?.watchlistName }}
            </span>
          </div>
          <div class="flex gap-2 h-[3.5vh] items-center">
            <span
              class="cursor-pointer"
              title="Add symbols"
              @click="openWatchlistModal"
              ><svg
                width="25"
                height="25"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9 16.5C5.46446 16.5 3.6967 16.5 2.59835 15.4016C1.5 14.3033 1.5 12.5355 1.5 9C1.5 5.46446 1.5 3.6967 2.59835 2.59835C3.6967 1.5 5.46446 1.5 9 1.5C12.5355 1.5 14.3033 1.5 15.4016 2.59835C16.5 3.6967 16.5 5.46446 16.5 9C16.5 12.5355 16.5 14.3033 15.4016 15.4016C14.3033 16.5 12.5355 16.5 9 16.5ZM9 6.1875C9.31065 6.1875 9.5625 6.43934 9.5625 6.75V8.4375H11.25C11.5606 8.4375 11.8125 8.68935 11.8125 9C11.8125 9.31065 11.5606 9.5625 11.25 9.5625H9.5625V11.25C9.5625 11.5606 9.31065 11.8125 9 11.8125C8.68935 11.8125 8.4375 11.5606 8.4375 11.25V9.5625H6.75C6.43934 9.5625 6.1875 9.31065 6.1875 9C6.1875 8.68935 6.43934 8.4375 6.75 8.4375H8.4375V6.75C8.4375 6.43934 8.68935 6.1875 9 6.1875Z"
                  fill="#41901C"
                />
              </svg>
            </span>
            <span
              class="w-auto h-auto"
              title="Download symbols"
              :class="
                selectedWatchlistSymbol?.length == 0 ||
                selectedWatchlistSymbol?.length == 20
                  ? 'opacity-20 pointer-events-none dark:!text-[#a4abb9] dark:!opacity-50'
                  : 'cursor-pointer'
              "
              @click="openDownloadSymbolModal(null)"
              ><svg
                class="dark:!text-[#6C707A] text-[#B1B0B0]"
                width="25"
                height="25"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.5 9C1.5 5.46446 1.5 3.6967 2.59835 2.59835C3.6967 1.5 5.46446 1.5 9 1.5C12.5355 1.5 14.3033 1.5 15.4016 2.59835C16.5 3.6967 16.5 5.46446 16.5 9C16.5 12.5355 16.5 14.3033 15.4016 15.4016C14.3033 16.5 12.5355 16.5 9 16.5C5.46446 16.5 3.6967 16.5 2.59835 15.4016C1.5 14.3033 1.5 12.5355 1.5 9ZM9 4.6875C9.31065 4.6875 9.5625 4.93934 9.5625 5.25V9.14197L10.8523 7.85227C11.0719 7.6326 11.428 7.6326 11.6477 7.85227C11.8674 8.07195 11.8674 8.42805 11.6477 8.64772L9.39772 10.8977C9.29227 11.0032 9.14917 11.0625 9 11.0625C8.85082 11.0625 8.70772 11.0032 8.60227 10.8977L6.35225 8.64772C6.13258 8.42805 6.13258 8.07195 6.35225 7.85227C6.57192 7.6326 6.92808 7.6326 7.14775 7.85227L8.4375 9.14197V5.25C8.4375 4.93934 8.68935 4.6875 9 4.6875ZM6 12.1875C5.68934 12.1875 5.4375 12.4393 5.4375 12.75C5.4375 13.0606 5.68934 13.3125 6 13.3125H12C12.3106 13.3125 12.5625 13.0606 12.5625 12.75C12.5625 12.4393 12.3106 12.1875 12 12.1875H6Z"
                  fill="currentColor"
                />
              </svg>
            </span>

            <span
              title="Delete watchlist"
              @click="openRemoveWatchlistModal(null)"
              class="cursor-pointer"
              ><svg
                width="22"
                height="22"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.01754 12.2176C4.80665 12.4284 4.80665 12.7703 5.01754 12.9812C5.22842 13.1921 5.57033 13.1921 5.78121 12.9812L5.01754 12.2176ZM9.38119 9.38119C9.59208 9.1703 9.59208 8.82844 9.38119 8.61756C9.1703 8.40667 8.82844 8.40667 8.61756 8.61756L9.38119 9.38119ZM8.61756 8.61756C8.40667 8.82844 8.40667 9.1703 8.61756 9.38119C8.82844 9.59208 9.1703 9.59208 9.38119 9.38119L8.61756 8.61756ZM12.9812 5.78121C13.1921 5.57033 13.1921 5.22842 12.9812 5.01754C12.7703 4.80665 12.4284 4.80665 12.2176 5.01754L12.9812 5.78121ZM9.38119 8.61756C9.1703 8.40667 8.82844 8.40667 8.61756 8.61756C8.40667 8.82844 8.40667 9.1703 8.61756 9.38119L9.38119 8.61756ZM12.2176 12.9812C12.4284 13.1921 12.7703 13.1921 12.9812 12.9812C13.1921 12.7703 13.1921 12.4284 12.9812 12.2176L12.2176 12.9812ZM8.61756 9.38119C8.82844 9.59208 9.1703 9.59208 9.38119 9.38119C9.59208 9.1703 9.59208 8.82844 9.38119 8.61756L8.61756 9.38119ZM5.78121 5.01754C5.57033 4.80665 5.22842 4.80665 5.01754 5.01754C4.80665 5.22842 4.80665 5.57033 5.01754 5.78121L5.78121 5.01754ZM5.78121 12.9812L9.38119 9.38119L8.61756 8.61756L5.01754 12.2176L5.78121 12.9812ZM9.38119 9.38119L12.9812 5.78121L12.2176 5.01754L8.61756 8.61756L9.38119 9.38119ZM8.61756 9.38119L12.2176 12.9812L12.9812 12.2176L9.38119 8.61756L8.61756 9.38119ZM9.38119 8.61756L5.78121 5.01754L5.01754 5.78121L8.61756 9.38119L9.38119 8.61756Z"
                  fill="#7C7C7C"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div
        class="flex gap-2 px-[11px] border-b dark:!border-[#404248] items-center py-2.5"
      >
        <div
          @click="handleAddSymbolDetail"
          :class="
            isPresent
              ? 'bg-appBlue hover:bg-[#9c2c2c]'
              : 'hover:bg-[#52a42c] bg-[#41901C]'
          "
          class="w-[100%] py-2.5 flex gap-2 justify-end px-[11px] items-center cursor-pointer rounded-[6px] transition"
        >
          <span
            v-if="isPresent"
            class="text-[#FFFFFF] -border font-[400] xxs:text-[10px] md:text-[12px] mr-6 flex items-center w-[55%]"
            >Remove {{ getSymbolName }} from list</span
          >
          <span
            v-else
            class="text-[#FFFFFF] -border font-[400] text-[14px] mr-6 w-[55%]"
            >Add {{ getSymbolName }} to list</span
          >
          <span v-if="isPresent">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="size-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5 12h14"
              />
            </svg>
          </span>
          <span v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.6"
              stroke="currentColor"
              class="size-[20px] text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </span>
        </div>
        <div class="cursor-pointer">
          <svg
            width="22"
            height="22"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.01754 12.7176C4.80665 12.9284 4.80665 13.2703 5.01754 13.4812C5.22842 13.6921 5.57033 13.6921 5.78121 13.4812L5.01754 12.7176ZM9.38119 9.88119C9.59208 9.6703 9.59208 9.32844 9.38119 9.11756C9.1703 8.90667 8.82844 8.90667 8.61756 9.11756L9.38119 9.88119ZM8.61756 9.11756C8.40667 9.32844 8.40667 9.6703 8.61756 9.88119C8.82844 10.0921 9.1703 10.0921 9.38119 9.88119L8.61756 9.11756ZM12.9812 6.28121C13.1921 6.07033 13.1921 5.72842 12.9812 5.51754C12.7703 5.30665 12.4284 5.30665 12.2176 5.51754L12.9812 6.28121ZM9.38119 9.11756C9.1703 8.90667 8.82844 8.90667 8.61756 9.11756C8.40667 9.32844 8.40667 9.6703 8.61756 9.88119L9.38119 9.11756ZM12.2176 13.4812C12.4284 13.6921 12.7703 13.6921 12.9812 13.4812C13.1921 13.2703 13.1921 12.9284 12.9812 12.7176L12.2176 13.4812ZM8.61756 9.88119C8.82844 10.0921 9.1703 10.0921 9.38119 9.88119C9.59208 9.6703 9.59208 9.32844 9.38119 9.11756L8.61756 9.88119ZM5.78121 5.51754C5.57033 5.30665 5.22842 5.30665 5.01754 5.51754C4.80665 5.72842 4.80665 6.07033 5.01754 6.28121L5.78121 5.51754ZM5.78121 13.4812L9.38119 9.88119L8.61756 9.11756L5.01754 12.7176L5.78121 13.4812ZM9.38119 9.88119L12.9812 6.28121L12.2176 5.51754L8.61756 9.11756L9.38119 9.88119ZM8.61756 9.88119L12.2176 13.4812L12.9812 12.7176L9.38119 9.11756L8.61756 9.88119ZM9.38119 9.11756L5.78121 5.51754L5.01754 6.28121L8.61756 9.88119L9.38119 9.11756Z"
              fill="#7C7C7C"
            />
          </svg>
        </div>
      </div>
      <div
        class="w-full flex items-center gap-2 py-2.5 border-b dark:!border-[#404248] border-[#D1D1D1] -dark:!border-[#404248] px-[11px]"
      >
        <div
          class="w-[100%] py-2.5 border bg-[#F6F6F6] border-[#DEDEDE] shadow-sm dark:!bg-[#1A1A1A] dark:!border-[#404248] rounded-[10px] px-[13px] flex items-center"
        >
          <div
            class="w-1/12 h-full text-[#6D6D6D] flex justify-center items-center cursor-pointer"
            v-html="getSvg('searchicon')"
          ></div>
          <input
            class="text-[#6D6D6D] placeholder:!text-[#6D6D6D] bg-transparent dark:!text-[#dedede] tracking-widest text-[13px] !focus:outline-none border-none !ring-0 font-medium w-10/12 px-3 h-[80%]"
            type="text"
            v-model="searchSymbol"
            placeholder="Search in your watchlist"
          />
        </div>
        <div class="cursor-pointer -w-10">
          <svg
            width="22"
            height="22"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.01754 12.2176C4.80665 12.4284 4.80665 12.7703 5.01754 12.9812C5.22842 13.1921 5.57033 13.1921 5.78121 12.9812L5.01754 12.2176ZM9.38119 9.38119C9.59208 9.1703 9.59208 8.82844 9.38119 8.61756C9.1703 8.40667 8.82844 8.40667 8.61756 8.61756L9.38119 9.38119ZM8.61756 8.61756C8.40667 8.82844 8.40667 9.1703 8.61756 9.38119C8.82844 9.59208 9.1703 9.59208 9.38119 9.38119L8.61756 8.61756ZM12.9812 5.78121C13.1921 5.57033 13.1921 5.22842 12.9812 5.01754C12.7703 4.80665 12.4284 4.80665 12.2176 5.01754L12.9812 5.78121ZM9.38119 8.61756C9.1703 8.40667 8.82844 8.40667 8.61756 8.61756C8.40667 8.82844 8.40667 9.1703 8.61756 9.38119L9.38119 8.61756ZM12.2176 12.9812C12.4284 13.1921 12.7703 13.1921 12.9812 12.9812C13.1921 12.7703 13.1921 12.4284 12.9812 12.2176L12.2176 12.9812ZM8.61756 9.38119C8.82844 9.59208 9.1703 9.59208 9.38119 9.38119C9.59208 9.1703 9.59208 8.82844 9.38119 8.61756L8.61756 9.38119ZM5.78121 5.01754C5.57033 4.80665 5.22842 4.80665 5.01754 5.01754C4.80665 5.22842 4.80665 5.57033 5.01754 5.78121L5.78121 5.01754ZM5.78121 12.9812L9.38119 9.38119L8.61756 8.61756L5.01754 12.2176L5.78121 12.9812ZM9.38119 9.38119L12.9812 5.78121L12.2176 5.01754L8.61756 8.61756L9.38119 9.38119ZM8.61756 9.38119L12.2176 12.9812L12.9812 12.2176L9.38119 8.61756L8.61756 9.38119ZM9.38119 8.61756L5.78121 5.01754L5.01754 5.78121L8.61756 9.38119L9.38119 8.61756Z"
              fill="#7C7C7C"
            />
          </svg>
        </div>
      </div>
      <div :class="size=='half'?'h-[50%]':'h-[80%]'" class="w-full overflow-y-scroll scroll-container pb-[2rem]">
          <div
            class="flex w-full pt-3 dark:hover:!bg-[#2D2D2D] pb-2.5 hover:!bg-[#E4E4E460] items-center gap-3 border-b-[0.5px] bg-white dark:!bg-[#1A1A1A] border-[#DEDEDE] dark:!border-[#404248] px-4"
            v-for="(item, i) in filteredSymbols"
            :key="i"
          >
            <div class="w-11/12 h-full">
              <SymbolDetailComp :item="item" @handlePress="handleSymbolPress" />
            </div>
              <div
                @click="handleSymbolRemove(item)"
                class="cursor-pointer mr-[-7px]"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.01754 12.2176C4.80665 12.4284 4.80665 12.7703 5.01754 12.9812C5.22842 13.1921 5.57033 13.1921 5.78121 12.9812L5.01754 12.2176ZM9.38119 9.38119C9.59208 9.1703 9.59208 8.82844 9.38119 8.61756C9.1703 8.40667 8.82844 8.40667 8.61756 8.61756L9.38119 9.38119ZM8.61756 8.61756C8.40667 8.82844 8.40667 9.1703 8.61756 9.38119C8.82844 9.59208 9.1703 9.59208 9.38119 9.38119L8.61756 8.61756ZM12.9812 5.78121C13.1921 5.57033 13.1921 5.22842 12.9812 5.01754C12.7703 4.80665 12.4284 4.80665 12.2176 5.01754L12.9812 5.78121ZM9.38119 8.61756C9.1703 8.40667 8.82844 8.40667 8.61756 8.61756C8.40667 8.82844 8.40667 9.1703 8.61756 9.38119L9.38119 8.61756ZM12.2176 12.9812C12.4284 13.1921 12.7703 13.1921 12.9812 12.9812C13.1921 12.7703 13.1921 12.4284 12.9812 12.2176L12.2176 12.9812ZM8.61756 9.38119C8.82844 9.59208 9.1703 9.59208 9.38119 9.38119C9.59208 9.1703 9.59208 8.82844 9.38119 8.61756L8.61756 9.38119ZM5.78121 5.01754C5.57033 4.80665 5.22842 4.80665 5.01754 5.01754C4.80665 5.22842 4.80665 5.57033 5.01754 5.78121L5.78121 5.01754ZM5.78121 12.9812L9.38119 9.38119L8.61756 8.61756L5.01754 12.2176L5.78121 12.9812ZM9.38119 9.38119L12.9812 5.78121L12.2176 5.01754L8.61756 8.61756L9.38119 9.38119ZM8.61756 9.38119L12.2176 12.9812L12.9812 12.2176L9.38119 8.61756L8.61756 9.38119ZM9.38119 8.61756L5.78121 5.01754L5.01754 5.78121L8.61756 9.38119L9.38119 8.61756Z"
                    fill="#7C7C7C"
                  />
                </svg>
              </div>
            </div>

            <div
          v-if="selectedWatchlistSymbol?.length < 20"
          class="w-full py-3 flex gap-4 px-[22px] dark:!border-[#404248] dark:text-[#F6F6F6] dark:bg-[#1A1A1A] border-b justify-start items-center"
        >
          <div @click="openWatchlistModal" class="cursor-pointer">
            <svg
              width="30"
              height="31"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 30.5C7.92892 30.5 4.39339 30.5 2.1967 28.3032C-1.78814e-07 26.1066 0 22.571 0 15.5C0 8.42892 -1.78814e-07 4.89339 2.1967 2.6967C4.39339 0.5 7.92892 0.5 15 0.5C22.071 0.5 25.6066 0.5 27.8032 2.6967C30 4.89339 30 8.42892 30 15.5C30 22.571 30 26.1066 27.8032 28.3032C25.6066 30.5 22.071 30.5 15 30.5ZM15 9.875C15.6213 9.875 16.125 10.3787 16.125 11V14.375H19.5C20.1213 14.375 20.625 14.8787 20.625 15.5C20.625 16.1213 20.1213 16.625 19.5 16.625H16.125V20C16.125 20.6213 15.6213 21.125 15 21.125C14.3787 21.125 13.875 20.6213 13.875 20V16.625H10.5C9.87868 16.625 9.375 16.1213 9.375 15.5C9.375 14.8787 9.87868 14.375 10.5 14.375H13.875V11C13.875 10.3787 14.3787 9.875 15 9.875Z"
                fill="#BCBCBC"
              />
            </svg>
          </div>
          <div class="flex flex-col">
            <span
              class="text-[#000000] dark:text-[#F6F6F6] text-[17px] font-[500]"
              >Add more symbols.</span
            >
            <span class="text-[#6D6D6D] -mt-1 text-[11px] font-normal">{{
              `You can add ${
                20 - selectedWatchlistSymbol?.length
              } more Symbols.`
            }}</span>
          </div>
        </div>
        <div
          v-else
          class="w-full py-2.5 flex flex-col dark:!border-[#404248] border border-b justify-center items-center"
        >
          <span
            class="text-[#000000] dark:text-[#dedede] text-[17px] font-[500]"
            >Maximum Symbols reached.</span
          >
          <span class="text-[#6D6D6D] -mt-1 text-[11px] font-normal"
            >You can add up to 20 Symbols.</span
          >
        </div>
          </div>
       
      </div>
    </div>
  </div>
</template>
<script>
import { getSvgByName } from '../assets/rightsidebarsvgs/utils/SvgUtils'
import { mapActions, mapGetters } from 'vuex'
import {formatTimeAgo} from '../utils/chartEvent'
import SymbolDetailComp from './SymbolDetailComp'

export default {
  name: 'MyWatchList',
  data() {
    return {
      isPresent: false,
      isMinimize: false,
      isWatchlist: true,
      selectedWatchlist: null,
      selectedWatchlistSymbol: [],
      watchlistNamedata: '',
      watchListData: [],
      searchWatchlist: '',
      searchSymbol: '',
      screenWidth: window.innerWidth,
    }
  },
  components: {
    SymbolDetailComp
  },
  props:{
    size:String
  },
  methods: {
    ...mapActions(['updateWatchlistSymbolList']),
    getSvg(name) {
      return getSvgByName(name)
    },

    getTruncateText(val) {
      let maxLength = 15
      if (this.screenWidth > 500) {
        if (val?.length > maxLength && this.screenWidth < 1151) {
          return val?.substring(0, maxLength) + '..'
        } else {
          if (val?.length > 30) {
            return val?.substring(0, 30) + '..'
          } else {
            return val
          }
        }
      }
    },
    getRelativeTime(val) {
      if (val) {
        return formatTimeAgo(val)
      }
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },
    handleSymbolPress(item) {
      this.$emit('getSymbolData', item?.symbol)
    },
    addSingleSymbol() {
      if (this.getSymbolDetail) {
        let updatedSymbol = [
          ...this.selectedWatchlistSymbol,
          this.getSymbolDetail,
        ]
        let payload = {
          id: this.selectedWatchlist?.id,
          symbols: updatedSymbol,
        }
        this.updateWatchlistSymbolList(payload)
        console.log('payload', payload, this.selectedWatchlistSymbol)
      }
    },
    handleAddSymbolDetail() {
      if (this.isPresent) {
        this.handleSymbolRemove(this.getSymbolDetail)
      } else {
        this.addSingleSymbol()
      }
    },
    handleBack() {
      this.isWatchlist = true
      this.selectedWatchlist = null
      this.selectedWatchlistSymbol = []
    },
    handleSymbolRemove(item) {
      let obj = {
        id: this.selectedWatchlist?.id,
        watchlistName: this.selectedWatchlist?.watchlistName,
        symbol: item?.symbol,
      }
      this.$emit('deleteWatchlistSymbol', obj)
    },
    openSymbolListModal(item) {
      this.selectedWatchlist = item
      this.selectedWatchlistSymbol = item?.symbols
      this.checkSymbolInList()
      this.isWatchlist = false
    },
    openWatchlistModal() {
      this.$emit('openWatchlist', this.selectedWatchlist?.id)
    },
    openEditWatchlistModal(item) {
      this.$emit('editWatchlist', item)
    },
    handleAddWatchlist() {
      this.$emit('addWatchlist')
    },
    openRemoveWatchlistModal(item = null) {
      if (item) {
        this.$emit('removeWatchlist', item)
      } else {
        this.$emit('removeWatchlist', this.selectedWatchlist)
      }
      this.isWatchlist = true
    },
    openDownloadSymbolModal(item = null) {
      if (item) {
        this.$emit('downloadSymbol', item)
      } else {
        let updateData = {
          ...this.selectedWatchlist,
          symbols: this.selectedWatchlistSymbol,
        }
        this.$emit('downloadSymbol', updateData)
      }
    },
    checkSymbolInList() {
      let found = this.selectedWatchlistSymbol?.some(
        (item) => item.symbol == this.getSymbolName
      )
      this.isPresent = found
      console.log("is present",this.isPresent,found)
    },
  },
  computed: {
    ...mapGetters([
      'getAllWatchlist',
      'getSymbolName',
      'getLatestWatchlist',
      'getSymbolDetail',
    ]),

    filteredSymbols() {
      return this.selectedWatchlistSymbol?.filter((symbol) =>
        symbol?.symbol
          ?.toLowerCase()
          .includes(this.searchSymbol?.toLowerCase())
      )
    },

    filteredWatchlist() {
      // If the search query is empty, return all symbols
      if (this.searchWatchlist === '') {
        console.log('watchlist', this.watchListData)
        return this.watchListData
      }

      // Filter the selectedSymbol array based on the searchQuery
      return this.watchListData.filter((symbol) =>
        symbol.watchlistName
          .toLowerCase()
          .includes(this.searchWatchlist.toLowerCase())
      )
    },
  },

  mounted() {},
  watch: {
    getAllWatchlist(val) {
      this.watchListData = val
      if (!this.isWatchlist) {
        let findVal = this.watchListData?.find(
          (item) => item.id == this.selectedWatchlist?.id
        )
        this.selectedWatchlistSymbol = findVal?.symbols
        this.checkSymbolInList()
      }
    },
    getLatestWatchlist(item) {
      if (item) {
        this.openSymbolListModal(item)
      }
    },
  },
}
</script>
<style scoped>
.scroll-container {
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
input::placeholder {
  color: #bfc0c1;
  letter-spacing: 0.8px;
}
</style>
