<!-- FormLayout.vue -->
<template>
  <div
    style="
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 40px 40px 40px 80px;
    "
    class="page-background mobileMainContainer"
  >
    <div
      class="pageContainerParent"
      style="
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
      "
    >
      <div
        class="pageContainer"
        style="
          height: 100%;
          width: 25%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <div style="height: 4%">
          <Header></Header>
        </div>

        <div style="height: 80%">
          <slot></slot>
        </div>

        <div class="hideDetail" style="height: 5%; white-space: nowrap">
          <Footer></Footer>
        </div>
      </div>
      <div class="smallFooter" style="display: none; z-index: 0">
        <SmallFooter></SmallFooter>
      </div>

      <div style="height: 100%; width: 68%" class="hideDetail">
        <TrialInfo></TrialInfo>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
import SmallFooter from './SmallScreenFooter.vue'
import TrialInfo from './TrialInfo.vue'

export default {
  name: 'FormLayout',
  components: {
    Header,
    Footer,
    SmallFooter,
    TrialInfo,
  },
}
</script>

<style scoped>
.join-us-container {
  /* text-align: center; */
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.user-profile {
  width: 60px;
  height: 60px;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin-left: -30px;
  z-index: 1;
}

.user-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile:nth-child(2) {
  margin-left: -20px;
}

.user-profile:nth-child(3) {
  margin-left: -20px;
}

.join-us-text {
  font-size: 20px;
  color: #333333;
  margin-left: 20px;
  /* margin-top: 20px; */
}
</style>
