<template>
  <!-- :class="isScrollBarVisible ? 'scrollable-container ' : 'overflow-y-hidden'" -->
  <div
    @mouseover="MouseOverTableData"
    @mouseleave="MouseLeaveTableData"
    :class="
      isScrollBarVisible
        ? 'scrollable-container-visible'
        : 'scrollable-container-invisible'
    "
    class="!h-full -border-b overflow-x-hidden dark:border-[#1E293B] dark:!bg-[#1A1A1A] w-full !pb-[85px]"
  >
    <!-- <div class="w-full"> -->
    <table
      v-if="getSelectedWatchlist?.symbolList?.length > 0"
      :class="isWatchlistSelect == false ? 'xxs:!w-full' : 'xxs:!w-[40%]'"
      class="xs:!w-full xxs:text-[12px] md:text-[15px] dark:!bg-[#1A1A1A] bg-white text-left -rtl:text-right"
    >
      <thead
        class="z-50 !sticky top-0 dark:!bg-[#1A1A1A] bg-white py-2 -overflow-x-auto"
      >
        <tr
          class="cursor-default flex justify-between dark:border-[#2D2D2D] !border-b px-2 h-[3.5rem]"
        >
          <th
            class="w-[35%] py-2 xxs:px-1 xs:px-4 border-r-2 dark:border-[#2D2D2D] text-left font-medium dark:text-[#F6F6F6] text-gray-700"
          >
            Symbol
          </th>
          <th
            class="!w-[15%] py-2 xxs:px-1 xs:px-4 border-r-2 dark:border-[#2D2D2D] !text-left font-medium dark:text-[#F6F6F6] text-gray-700"
          >
            Last
          </th>
          <th
            class="!w-[15%] py-2 xxs:px-1 xs:px-4 border-r-2 dark:border-[#2D2D2D] !text-left font-medium dark:text-[#F6F6F6] text-gray-700"
          >
            Chg
          </th>
          <th
            class="-!w-[15%] py-2 xxs:px-1 xs:px-4 !text-left font-medium dark:text-[#F6F6F6] text-gray-700"
          >
            Chg%
          </th>
          <!-- <th class="py-2 px-4 border-b text-left font-medium text-gray-700">
            Vol
          </th>
          <th class="py-2 px-4 border-b text-left font-medium text-gray-700">
            Ext Hrs
          </th> -->
        </tr>
      </thead>
      <tbody class="w-full border-none">
        <tr
          @mouseover="isMouseOver(item.name)"
          @mouseleave="isMouseLeave"
          v-for="(item, index) in filteredSymbols"
          :key="index"
          class="hover:bg-appBtnHover flex justify-between dark:hover:bg-[#2D2D2D] cursor-default hover:border-b dark:border-[#2D2D2D] !border-b px-2 h-[3.5rem]"
        >
          <td
            class="w-[36%] xxs:gap-1 xs:gap-3 dark:text-[#F6F6F6] text-[black] !flex !justify-start !items-center xxs:text-[12px] md:text-[15px] h-[3.5rem] xxs:!px-1 xs:!px-3"
          >
            <div
              class="w-[28px] h-[28px] flex !shrink-0 justify-center items-center bg-blue-400 rounded-full"
            >
              <TickerIcon :ticker="item.name" />
            </div>
            <div
              @click="handleSymbol(item.name)"
              class="hover:underline cursor-pointer"
            >
              {{ item.name }}
            </div>
            <div class="bg-gray-400 rounded-full w-[8px] h-[8px]"></div>
          </td>
          <td
            class="!w-[15%] dark:text-[#F6F6F6] text-[black] text-[14px] h-[3.5rem] xss:px-1 xs:px-3 flex items-center"
          >
            {{ 32.2 }}
          </td>
          <td
            class="!w-[15%] dark:text-[#F6F6F6] text-[black] text-[14px] h-[3.5rem] xss:px-1 xs:px-3 flex items-center"
          >
            {{ 0.56 }}
          </td>
          <td
            :class="2.11 < 0 ? 'text-red-500' : '!text-green-400'"
            class="-!w-[15%] dark:text-[#F6F6F6] text-[black] text-[14px] h-[3.5rem] xss:px-1 xs:px-3 flex items-center"
          >
            <span>{{ 2.11 < 0 ? '' : '+' }}</span>
            {{ 2.11 }}%
            <span
              @click="openRemoveSymbolModal(item.name)"
              :class="isMouseOnSymbol == item.name ? 'display' : 'invisible'"
              class="-absolute dark:!bg-[#727272] bg-white cursor-pointer right-4"
            >
              <svg
                v-if="isDisplay"
                class="text-[#000000] dark:text-[#F6F6F6]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
                width="18"
                height="18"
              >
                <path
                  fill="currentColor"
                  d="M12 4h3v1h-1.04l-.88 9.64a1.5 1.5 0 0 1-1.5 1.36H6.42a1.5 1.5 0 0 1-1.5-1.36L4.05 5H3V4h3v-.5C6 2.67 6.67 2 7.5 2h3c.83 0 1.5.67 1.5 1.5V4ZM7.5 3a.5.5 0 0 0-.5.5V4h4v-.5a.5.5 0 0 0-.5-.5h-3ZM5.05 5l.87 9.55a.5.5 0 0 0 .5.45h5.17a.5.5 0 0 0 .5-.45L12.94 5h-7.9Z"
                ></path>
              </svg>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-else
      class="text-[1.5rem] text-appBlue font-medium h-[50%] flex items-center justify-center"
    >
      Your watchlist is empty.
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import TickerIcon from './TickerIcon'

export default {
  name: 'SymbolInfo',
  data() {
    return {
      isMouseOnSymbol: null,
      isScrollBarVisible: false,
    }
  },
  props: {
    item: Object,
    isWatchlistSelect: Boolean,
    filteredSymbols: Array,
    isDisplay: Boolean,
  },
  components: {
    TickerIcon,
  },
  methods: {
    isMouseOver(name) {
      this.isMouseOnSymbol = name

      // console.log('isMouseOnIndex', this.isMouseOnIndex)
    },

    isMouseLeave() {
      this.isMouseOnSymbol = null

      // console.log('isMouseOnIndex', this.isMouseOnIndex)
    },
    MouseOverTableData() {
      this.isScrollBarVisible = true
    },
    MouseLeaveTableData() {
      this.isScrollBarVisible = false
    },
    openRemoveSymbolModal(sym) {
      this.$emit('open-Remove-Symbol-Modal', sym)
    },
    handleSymbol(sym) {
      this.$emit('getSymbolData', sym)
    },
  },
  computed: {
    ...mapGetters(['getSelectedWatchlist']),
  },
  mounted() {},
  watch: {},
}
</script>
<style scoped>
.scrollable-container {
  max-height: 100%; /* Set the desired height */
  overflow-y: auto;
  border: 1px solid #fff; /* Optional border */
  border-radius: 4px;
}

.content {
  padding: 16px;
}

/* Scrollbar styles visible*/
.scrollable-container-visible::-webkit-scrollbar {
  width: 7px; /* Width of the scrollbar */
  background: transparent;
}

.scrollable-container-visible::-webkit-scrollbar-track {
  background: transparent !important; /* Background of the track  */
  display: none;
}

.scrollable-container-visible::-webkit-scrollbar-corner {
  background: transparent !important;
  display: none;
}

.scrollable-container-visible::-webkit-scrollbar-thumb:hover {
  background: #9198b8; /* Color of the handle on hover */
}

.scrollable-container-visible::-webkit-scrollbar-thumb {
  background: #bdc0c8;
  border-radius: 10px;
}

/* invisible */

.scrollable-container-invisible::-webkit-scrollbar {
  width: 7px; /* Width of the scrollbar */
}

.scrollable-container-invisible::-webkit-scrollbar-thumb:hover {
  background: transparent; /* Color of the handle on hover */
}

.scrollable-container-invisible::-webkit-scrollbar-thumb {
  background: transparent;
}

.scrollable-container-invisible::-webkit-scrollbar-track {
  background: transparent; /* Background of the track  */
  display: none;
}
</style>
