<template>
  <div class="!w-full h-fit relative -px-4 bg-white dark:bg-[#1A1A1A]">
    <div
      class="bg-white flex items-center dark:!bg-[#1A1A1A] z-40 top-2.5 px-4 !sticky h-[3.8rem] !w-full border-b"
    >
      <div class="w-full flex items-center justify-between -border-b">
        <div class="flex space-x-3 items-center -border-b">
          <div
            class="flex w-[2.5rem] h-[2.5rem] rounded-full bg-gray-500 text-xl text-[#fff] justify-center items-center"
          >
            <TickerIcon :ticker="getSymbolDetail?.symbol" />
          </div>
          <div
            class="text-[15px] font-[600] dark:!text-[#F6F6F6] text-appGrayDark"
          >
            {{ getSymbolDetail?.symbol }}
          </div>
        </div>

        <div ref="showRef" class="flex items-center relative space-x-2">
          <!-- <BackgroundHover>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1"
              stroke="currentColor"
              class="size-8"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z"
              />
            </svg>
          </BackgroundHover>
          <BackgroundHover>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28 28"
              width="28"
              height="28"
            >
              <path
                fill="currentColor"
                d="M15 6H7.5C6.67 6 6 6.67 6 7.5v13c0 .83.67 1.5 1.5 1.5h13c.83 0 1.5-.67 1.5-1.5V16h1v4.5a2.5 2.5 0 0 1-2.5 2.5h-13A2.5 2.5 0 0 1 5 20.5v-13A2.5 2.5 0 0 1 7.5 5H15v1Z"
              ></path>
              <path
                fill="currentColor"
                d="M22.41 5.7a2 2 0 0 0-2.82 0L11 14.3V18h3.7l8.6-8.59a2 2 0 0 0 0-2.82l-.89-.88Zm-2.12.71a1 1 0 0 1 1.42 0l.88.88a1 1 0 0 1 0 1.42l-.59.58L19.7 7l.6-.59Zm1 3.59-7 7H12v-2.3l7-7 2.3 2.3Z"
              ></path>
            </svg>
          </BackgroundHover> -->

          <BackgroundHover @press="openFavDropdown = !openFavDropdown">
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"
              />
            </svg>
          </BackgroundHover>

          <div class="absolute right-0 top-[3rem]" style="z-index: 999">
            <div
              v-if="openFavDropdown"
              :class="isLoading ? 'pointer-events-none' : ''"
              class="bg-white rounded-[4px] w-[12rem] dark:border-none shadow-lg py-2"
            >
              <span v-if="watchLists?.length > 0">
                <div
                  v-for="(item, i) in watchLists"
                  :key="i"
                  @click="handleWatchlist(item)"
                  class="flex pl-2 py-2.5 hover:bg-appBtnHover dark:hover:bg-[#3f3f3f] dark:text-white dark:bg-[#2d2d2d] cursor-pointer items-center space-x-3"
                >
                  <div
                    class="text-[13px] font-[500] flex items-center space-x-2"
                  >
                    <div
                      :class="
                        item?.symbolList?.some(
                          (val) => val.name == getSymbolDetail?.symbol
                        )
                          ? 'bg-appBtnActive border-transparent'
                          : 'bg-transparent border-black'
                      "
                      class="w-[1.5rem] h-[1.5rem] rounded-[4px] flex items-center border justify-center"
                    >
                      <div>
                        <svg
                          v-if="
                            item?.symbolList?.some(
                              (val) => val.name == getSymbolDetail?.symbol
                            )
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="4"
                          stroke="currentColor"
                          class="size-4 text-[#fff] dark:text-transparent"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m4.5 12.75 6 6 9-13.5"
                          />
                        </svg>
                      </div>
                    </div>
                    <div>
                      {{ item?.name }}
                    </div>
                  </div>
                </div>
              </span>
              <span
                v-else
                class="w-full h-[5rem] flex items-center justify-center"
              >
                No watchlist found
              </span>
            </div>
          </div>

          <BackgroundHover
            @press="openVisibilityDropdown = !openVisibilityDropdown"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28 28"
              width="28"
              height="28"
            >
              <path
                fill="currentColor"
                d="M7.5 13a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM5 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM12 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0zm9.5-1.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM19 14.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0z"
              ></path>
            </svg>
          </BackgroundHover>

          <div class="absolute right-0 top-[3rem]" style="z-index: 999">
            <div
              v-if="openVisibilityDropdown"
              class="bg-white rounded-[4px] w-[12rem] dark:border-none shadow-lg py-2"
            >
              <div
                v-for="(item, i) in contentVisibility"
                :key="i"
                @click="handleContentVisibility(item.key)"
                class="flex pl-2 py-2.5 hover:bg-appBtnHover dark:hover:bg-[#3f3f3f] dark:text-white dark:bg-[#2d2d2d] cursor-pointer items-center space-x-3"
              >
                <!-- <svg
                    v-if="selectedVisibility?.includes(item.key)"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="4"
                    stroke="currentColor"
                    class="size-4 text-[#fff]"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg> -->

                <div class="text-[13px] font-[500] flex items-center space-x-2">
                  <div
                    :class="
                      selectedVisibility?.includes(item.key)
                        ? 'bg-appBtnActive border-transparent'
                        : 'bg-transparent border-black'
                    "
                    class="w-[1.5rem] h-[1.5rem] rounded-[4px] flex items-center border justify-center"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="4"
                        stroke="currentColor"
                        class="size-4 text-[#fff] dark:text-transparent"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m4.5 12.75 6 6 9-13.5"
                        />
                      </svg>
                    </div>
                  </div>
                  <div>
                    {{ item?.value }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <span
      v-if="priceLoading"
      class="w-full h-[100vh] pt-[100px] flex justify-center px-4"
    >
      <Loader customClass="w-9 h-9" />
    </span>

    <span v-else-if="getSymbolPrice" class="space-y-4 w-full h-full !p-8">
      <div class="flex flex-col px-4">
        <div
          class="flex items-center space-x-3 text-[12px] font-[300] dark:!text-[#F6F6F6] text-appGrayDark"
        >
          <span>{{ getSymbolDetail?.companyName }}</span>
          <!-- <BackgroundHover>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
            width="18"
            height="18"
          >
            <path
              fill="currentColor"
              d="M4.5 3C3.67 3 3 3.67 3 4.5v9c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V10h-1v3.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-9c0-.28.22-.5.5-.5H8V3H4.5ZM11 4h2.3L9.14 8.15l.7.7L14 4.71V7h1V3h-4v1Z"
            ></path>
          </svg>
        </BackgroundHover> -->
          <span class="w-1 h-1 rounded-full bg-appBtnHover" />
          <span class=""> {{ getSymbolDetail?.metaData?.exchange }} </span>
        </div>
        <div
          class="flex items-center space-x-3 text-[11px] font-[300] text-[#9598a1]"
        >
          <span>{{ getSymbolDetail?.classificationInfo?.sector }}</span>
          <span class="w-1 h-1 rounded-full bg-appBtnHover" />
          <span class="">{{
            getSymbolDetail?.classificationInfo?.industry
          }}</span>
        </div>
      </div>

      <div class="flex flex-col px-4">
        <div
          class="flex items-baseline space-x-1 text-[12px] font-[300] text-appGrayDark"
        >
          <span class="text-[26px] text-black dark:!text-[#F6F6F6] font-bold">
            {{ getSymbolPrice?.price?.CurrentPrice }}
          </span>
          <span class="text-[12px] font-[500] dark:text-[#F6F6F6]">
            {{ getSymbolPrice?.profile?.currency }}
          </span>
          <span
            class="text-[16px] pl-2 font-[600]"
            :class="
              getSymbolPrice?.price?.Change < 0
                ? 'text-errorText'
                : 'text-appGreen'
            "
          >
            {{ getSymbolPrice?.price?.Change }}
          </span>
          <span
            class="text-[16px] pl-1 text-appGreen font-[600]"
            :class="
              getSymbolPrice?.price?.Ratio < 0
                ? 'text-errorText'
                : 'text-appGreen'
            "
          >
            {{ `(${getSymbolPrice?.price?.Ratio?.toFixed(2)}%)` }}
          </span>
        </div>
        <div
          class="flex items-center space-x-2 text-[11px] font-[300] text-[#9598a1] -mt-1"
        >
          <span
            >AT CLOSE ({{
              getFormattedTime(getSymbolPrice?.price?.Time)
            }})</span
          >
        </div>
      </div>

      <!-- <div class="flex flex-col">
      <div
        class="flex items-baseline space-x-1 text-[12px] font-[300] text-appGrayDark"
      >
        <span class="text-[20px] text-black dark:!text-[#F6F6F6] font-semibold">
          201.91
        </span>
        <span class="text-[16px] pl-2 text-appGreen font-[600]"> 3.07 </span>
        <span class="text-[16px] pl-1 text-appGreen font-[600]"> (1.54%) </span>
      </div>
      <div class="flex items-center space-x-2 text-[12px] font-[300] -mt-1">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14 17"
            width="14"
            height="17"
            fill="none"
          >
            <g
              fill="#FB8C00"
              fill-rule="evenodd"
              clip-path="url(#ai0qyr3iu)"
              clip-rule="evenodd"
            >
              <path
                d="M9.93 11.64A3.01 3.01 0 0 0 7 8a3 3 0 0 0-2.93 3.64 6.97 6.97 0 0 1 5.86 0zM3.12 10.44l-2.07-.56.31-1.15 2.07.55-.3 1.16zM4.92 7.68l-.96-1.91 1.08-.54L6 7.14l-1.08.54zM8 7.14l.96-1.9 1.08.53-.96 1.91L8 7.14zM10.57 9.56l2.07-.55.31 1.16-2.06.55-.32-1.16z"
              ></path>
            </g>
            <defs>
              <clipPath id="ai0qyr3iu">
                <path fill="#fff" d="M0 0h14v17H0z"></path>
              </clipPath>
            </defs>
          </svg>
        </span>
        <span class="text-[11px] text-[#fb8c00]">PRE MARKET</span>
      </div>
    </div> -->

      <!-- <div v-if="isShow('bidask')" class="flex items-center space-x-3">
      <div class="bg-[#2962ff1a] rounded-[10px] px-3 py-0.5 text-[#2962ff]">
        201.82x1
      </div>
      <div class="text-[#f23645] bg-[#f236451a] rounded-[10px] px-3 py-0.5">
        201.99x78
      </div>
    </div> -->

      <div v-if="isShow('priceRange')" class="w-full my-4 px-4">
        <div class="flex w-full items-center justify-between">
          <div class="text-[#131722] text-[12px]">194.04</div>
          <div class="text-[#9598a1] text-[11px]">DAY'S RANGE</div>
          <div class="text-[#131722] text-[12px]">200.70</div>
        </div>
        <div class="w-full relative h-[5px] bg-[#f0f3fa]">
          <div class="absolute left-[45%] w-[30%] h-[5px] bg-appGreen" />
          <div class="absolute left-[72%] w-full top-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 12 8"
              width="12"
              height="8"
              fill="none"
            >
              <path
                fill="currentColor"
                d="M10 8H2a1 1 0 0 1-.8-1.6l4-5.33a1 1 0 0 1 1.6 0l4 5.33A1 1 0 0 1 10 8z"
              ></path>
            </svg>
          </div>
        </div>
      </div>

      <div v-if="isShow('priceRange')" class="w-full px-4">
        <div class="flex w-full items-center justify-between">
          <div class="text-[#131722] text-[12px]">
            {{ getSymbolPrice?.metrics?.existingMetrics['52WeekLow'] || '-' }}
          </div>
          <div class="text-[#9598a1] text-[11px]">52WK RANGE</div>
          <div class="text-[#131722] text-[12px]">
            {{ getSymbolPrice?.metrics?.existingMetrics['52WeekHigh'] || '-' }}
          </div>
        </div>
        <div class="w-full relative h-[5px] bg-[#f0f3fa]">
          <div class="absolute left-[30%] w-[10%] h-[5px] bg-[#F21E13]" />
          <div class="absolute left-[38%] w-full top-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 12 8"
              width="12"
              height="8"
              fill="none"
            >
              <path
                fill="currentColor"
                d="M10 8H2a1 1 0 0 1-.8-1.6l4-5.33a1 1 0 0 1 1.6 0l4 5.33A1 1 0 0 1 10 8z"
              ></path>
            </svg>
          </div>
        </div>
      </div>

      <div v-if="isShow('analystsRating')">
        <div class="mt-[2rem] mb-[0.5rem] px-4 text-[13px] font-[600]">
          37 Analysts Rating
        </div>
        <div class="px-4" >
          <AnalystsRating
            :lowest="getSymbolPrice?.price?.l"
            :mean="getMean([getSymbolPrice?.price?.l,getSymbolPrice?.price?.c,getSymbolPrice?.price?.h])"
            :median="getMedian([getSymbolPrice?.price?.l,getSymbolPrice?.price?.c,getSymbolPrice?.price?.h])"
            :currentPrice="getSymbolPrice?.price?.c"
            :highest="getSymbolPrice?.price?.h"
          />
        </div>
      </div>

      <div v-if="isShow('profile')" class="px-4">
        <div class="mt-[2rem] mb-[0.5rem] text-[13px] font-[600]">Profile</div>
        <div
          v-for="(item, i) in profile"
          class="w-full flex items-center justify-between space-y-2"
        >
          <span class="text-[13px]">{{ item.key }}</span>
          <span v-if="item?.url" class="hover:text-appBtnActive">
            <a
              :href="getSymbolPrice?.profile[item.name]"
              target="_blank"
              class="text-[13px] font-[600]"
            >
              <span class="flex items-center space-x-1">
                <span>
                  {{ getUrl(getSymbolPrice?.profile[item.name]) }}
                </span>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                    width="18"
                    height="18"
                  >
                    <path
                      fill="currentColor"
                      d="M4.5 3C3.67 3 3 3.67 3 4.5v9c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V10h-1v3.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-9c0-.28.22-.5.5-.5H8V3H4.5ZM11 4h2.3L9.14 8.15l.7.7L14 4.71V7h1V3h-4v1Z"
                    ></path>
                  </svg>
                </span>
              </span>
            </a>
          </span>
          <span v-else>
            <span class="text-[13px] font-[600]">{{
              getSymbolPrice?.profile[item.name] || '-'
            }}</span>
          </span>
        </div>
      </div>

      <!-- <div
      v-if="isShow('latestNews')"
      class="px-2 py-1 w-full !my-8 rounded-[10px] dark:hover:bg-[#2D2D2D] hover:bg-[#D9CEED] bg-[#673ab726] cursor-default justify-between flex items-center"
    >
      <div class="flex items-center space-x-3">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 18"
            width="18"
            height="18"
          >
            <path
              fill="currentColor"
              d="M6.17 8.63l5-4.5.72.68L9.02 8.5H12.8l-.97.87-5 4.5-.72-.68L8.98 9.5H5.2l.97-.87z"
            ></path>
          </svg>
        </span>
        <div class="flex flex-col text-[12px]">
          <div>
            <span>2 hours ago</span>
            <span />
            <span>As Nvidia Leads</span>
          </div>
          <div>Magnificent 7 Volatility, Analyst Says...</div>
        </div>
      </div>
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 18 18"
          width="18"
          height="18"
        >
          <path
            fill="currentColor"
            d="M9.84 9 6.62 5.33l.76-.66L11.16 9l-3.78 4.33-.76-.66L9.84 9Z"
          ></path>
        </svg>
      </span>
    </div> -->

      <div v-if="isShow('keyStats')" class="flex flex-col space-y-1 px-4">
        <span class="text-[13px] font-[600] pb-2"> Key stats </span>
        <div
          class="w-full flex items-center justify-between"
          v-for="(item, i) in getKeyStats"
          :key="i"
        >
          <span class="text-[13px]">{{ item.name }}</span>
          <span class="text-[#131722] text-[14px] font-[600]">{{
            getStatesVal(getSymbolPrice?.metrics[item.key])
          }}</span>
        </div>
        <div class="flex items-center justify-center w-full mt-2">
          <div
            @click="handleKeyStats"
            class="bg-[#f0f3fa] dark:bg-[#2D2D2D] dark:hover:bg-[#434343] hover:bg-appBtnHover text-[12px] cursor-pointer rounded-[10px] px-3.5 py-1.5"
          >
            <span v-if="showFullKeyStats">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m4.5 15.75 7.5-7.5 7.5 7.5"
                />
              </svg>
            </span>
            <span v-else>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>

      <div v-if="isShow('earnings')" class="h-full w-full space-y-2 px-4">
        <div class="text-[13px] -mb-[0.2rem] font-[600]">
          Recommendation Trends
        </div>
        <div class="h-[20rem]">
          <RecommendationTrends
            :series="recommendationSeries"
            :category="category"
          />
        </div>
      </div>

      <div v-if="isShow('earnings')" class="h-full w-full !mt-[2rem] px-4 space-y-2">
        <div class="text-[13px] -mb-[4rem] font-[600]">Earnings Estimates</div>
        <div class="h-[15rem]">
          <ScatterChart :series="earningEstimateSeries" />
        </div>
        <!-- <div class="flex items-center justify-center w-full">
        <div
          class="bg-[#f0f3fa] dark:bg-[#2D2D2D] dark:hover:bg-[#434343] hover:bg-[#E0E3EB] text-[12px] cursor-pointer rounded-[10px] px-3.5 py-1"
        >
          More info
        </div>
      </div> -->
      </div>

      <div v-if="isShow('earnings')" class="h-full w-full space-y-2 px-4">
        <div class="text-[13px] -mb-[4rem] font-[600]">Earnings Surprises</div>
        <div class="h-[15rem]">
          <ScatterChart :series="earningSurpriseSeries" />
        </div>
        <!-- <div class="flex items-center justify-center w-full">
        <div
          class="bg-[#f0f3fa] dark:bg-[#2D2D2D] dark:hover:bg-[#434343] hover:bg-[#E0E3EB] text-[12px] cursor-pointer rounded-[10px] px-3.5 py-1"
        >
          More info
        </div>
      </div> -->
      </div>

      <!-- <div v-if="isShow('dividends')" class="!mt-[2rem]">
      <div class="text-[13px] font-[600]">Dividend</div>
      <div class="text-[12px] font-[600] mt-[0.8rem] mb-[0.3rem]">
        TSLA does not pay dividends
      </div>
      <div class="text-[#6a6d78] font-[400]">
        The company has not previously paid dividends and at the moment there is
        no information about whether it is going to pay them in the future.
      </div>
    </div> -->

      <!-- <div v-if="isShow('financials')" class="!mt-[2rem]">
      <div class="flex relative items-center justify-between">
        <div
          @click="openBarChartType = !openBarChartType"
          class="flex items-center px-1 space-x-2 hover:bg-[#f1f1f1] dark:hover:!bg-[#2D2D2D] dark:!bg-[#1A1A1A] rounded-[4px] py-1.5 cursor-pointer text-[12px]"
        >
          <span class="text-[13px] font-[600]">{{ barChartType?.label }}</span>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="size-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </span>
        </div>
        <div class="flex items-center space-x-4">
          <span
            class="text-[12px] hover:underline cursor-pointer font-[400]"
            @click="barChartTime = 'Annual'"
            :class="barChartTime == 'Annual' ? '!text-appBtnActive underline' : ''"
            >Annual</span
          >
          <span
            class="text-[12px] hover:underline cursor-pointer font-[400]"
            @click="barChartTime = 'Quarterly'"
            :class="
              barChartTime == 'Quarterly' ? '!text-appBtnActive underline' : ''
            "
            >Quarterly</span
          >
        </div>

          <div
            v-if="openBarChartType"
            class="absolute top-[2.5rem] left-[0.2rem]"
            style="z-index: 999"
          >
            <SimpleDropdown
              :options="barChartData"
              @handleSelect="handleChartType"
            ></SimpleDropdown>
          </div>
        </div>

      <div class="w-full -mt-[3rem] h-[15rem]">
        <LineWithBar />
      </div>
      <div class="flex items-center !mt-[0.8rem] justify-center w-full">
        <div
          class="bg-[#f0f3fa] dark:bg-[#2D2D2D] dark:hover:bg-[#434343] hover:bg-[#E0E3EB] text-[12px] cursor-pointer rounded-[10px] px-3.5 py-1"
        >
          More financials
        </div>
      </div>
    </div> -->

      <div v-if="isShow('performance')" class="px-4" >
        <div class="mt-[2rem] mb-[0.5rem] text-[13px] font-[600]">
          Performance
        </div>
        <div
          class="flex w-full items-center -justify-between gap-2.5 flex-wrap"
        >
          <div
            v-for="(item, i) in performance"
            :key="i"
            class="w-[31%] rounded-[4px] py-1"
            :class="item?.value < 0 ? 'bg-[#f7525f33]' : 'bg-[#22ab944d]'"
          >
            <div
              class="text-center font-[600] text-[13px]"
              :class="item?.value < 0 ? 'text-[#f23645]' : 'text-[#089981]'"
            >
              {{ item.value }}%
            </div>
            <div class="text-center">{{ item.timeRange }}</div>
          </div>
        </div>
      </div>

      <!-- <div v-if="isShow('technicals')" class="hidden">
      <div class="mt-[2rem] mb-[0.5rem] text-[13px] font-[600]">Technical</div>
      <div class="h-[15rem]">
        <RadialGuage title="Sell" />
      </div>
    </div> -->

      <!-- <div v-if="isShow('analysts')" class="hidden">
      <div class="mt-[2rem] mb-[0.5rem] text-[13px] font-[600]">
        Analyst Rating
      </div>
      <div class="h-[15rem]">
        <RadialGuage title="Neutral" />
      </div>
    </div> -->

      <!-- <div v-if="isShow('profile')" class="!mt-[2rem] space-y-2">
      <span class="text-[13px]">
        {{ getCompanyText() }}
      </span>
      <div class="flex items-center justify-center w-full">
        <div
          @click="handleCompanyText"
          class="bg-[#f0f3fa] dark:bg-[#2D2D2D] dark:hover:bg-[#434343] hover:bg-[#E0E3EB] text-[12px] cursor-pointer rounded-[10px] px-3.5 py-1.5"
        >
          <span v-if="showFullCompanyDetail">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m4.5 15.75 7.5-7.5 7.5 7.5"
              />
            </svg>
          </span>
          <span v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </span>
        </div>
      </div>
    </div> -->

      <!-- <div v-if="isShow('options')">
      <div class="mt-[2rem] mb-[0.5rem] text-[13px] font-[600]">
        ATM IV term structure
      </div>
      <div class="h-[15rem] -mt-[3rem]">
        <LineScatter />
      </div>
    </div> -->

      <!-- <div v-if="isShow('options')">
      <div class="mt-[2rem] mb-[0.5rem] text-[13px] font-[600]">
        Volatility curve (32 days)
      </div>
      <div class="h-[15rem] -mt-[3rem]">
        <LineChart />
      </div>
      <div class="flex items-center !mt-[0.8rem] justify-center w-full">
        <div
          class="bg-[#f0f3fa] dark:bg-[#2D2D2D] dark:hover:bg-[#434343] hover:bg-[#E0E3EB] text-[12px] cursor-pointer rounded-[10px] px-3.5 py-1"
        >
          More on options
        </div>
      </div>
    </div> -->
    </span>

    <span
      v-else
      class="w-full !h-[10rem] flex"
    >
      <NoData title="No data found" />
    </span>

    <div v-if="openRemoveSymbolModal" class="px-4">
      <BaseModal>
        <RemoveUnselectedWatchlistSymbol
          :showError="showError"
          :isLoading="isLoading"
          @close="handleCloseRemoveModal"
          @save="handleRemoveSymbol"
          :data="selectedWatchlist"
          :symbol="getSymbolDetail?.symbol"
        />
      </BaseModal>
    </div>
  </div>
</template>

<script>
import BackgroundHover from './BackgroundHover.vue'
import ScatterChart from './minicharts/ScatterChart.vue'
import { mapGetters, mapActions } from 'vuex'
import LineWithBar from './minicharts/LineWithBar'
import SimpleDropdown from './dropdowns/SimpleDropdown.vue'
// import RadialGuage from './minicharts/RadialGuage.vue'
import LineScatter from './minicharts/LineScatter.vue'
import LineChart from './minicharts/LineChart.vue'
import Loader from './Loading.vue'
import TickerIcon from './TickerIcon'
import BaseModal from './BaseModal.vue'
import RemoveUnselectedWatchlistSymbol from './RemoveUnselectedWatchlistSymbol.vue'
import moment from 'moment'
import { commaSeperatedNum } from '../utils/common'
import RecommendationTrends from './minicharts/RecommendationTrends'
import AnalystsRating from './minicharts/AnalystsRating.vue'
import NoData from './NoData.vue'

export default {
  name: 'TickerInfo',
  components: {
    BackgroundHover,
    ScatterChart,
    LineWithBar,
    SimpleDropdown,
    // RadialGuage,
    LineScatter,
    LineChart,
    TickerIcon,
    BaseModal,
    RemoveUnselectedWatchlistSymbol,
    Loader,
    RecommendationTrends,
    AnalystsRating,
    NoData
  },
  data() {
    return {
      isLoading: false,
      category: [],
      showError: '',
      recommendationSeries: [
        { name: 'Strong Buy', data: [12, 12, 14, 14, 14, 14] },
        { name: 'Buy', data: [20, 20, 20, 23, 24, 24] },
        { name: 'Hold', data: [14, 12, 14, 12, 14, 12] },
        { name: 'Sell', data: [2, 2, 2, 1, 1, 2] },
        { name: 'Strong Sell', data: [1, 2, 1, 2, 1, 1] },
      ],
      earningEstimateSeries: [
        {
          name: 'Estimate',
          data: [
            { x: "Q3 '23", y: 0.55 },
            { x: "Q4 '23", y: 0.75 },
            { x: "Q1 '24", y: 0.45 },
            { x: "Q2 '24", y: 0.65 },
            { x: "Q3 '24", y: 0.7 },
          ],
        },
      ],
      earningSurpriseSeries: [
        {
          name: 'Surprise',
          data: [
            { x: "Q3 '23", y: 0.2 },
            { x: "Q4 '23", y: 1.2 },
            { x: "Q1 '24", y: 0.8 },
            { x: "Q2 '24", y: 1.65 },
            { x: "Q3 '24", y: 1.3 },
          ],
        },
      ],
      openFavDropdown: false,
      openRemoveSymbolModal: false,
      selectedWatchlist: {},
      keyState: [
        { name: 'Next earnings report', key: 'nextEarningsReport' },
        { name: 'Volume', key: 'volume' },
        { name: 'Average Volume (30D)', key: 'averageVolume30D' },
        { name: 'Market capitilization', key: 'marketCapitalization' },
        { name: 'Dividend yield (indicated)', key: 'dividendYield' },
        { name: 'Price to earnings Ratio (TTM)', key: 'priceEarningsRatio' },
        { name: 'Basic EPS (TTM)', key: 'basicEPS' },
        { name: 'Shares float', key: 'sharesFloat' },
        { name: 'Beta (1Y)', key: 'beta1Y' },
      ],
      showFullCompanyDetail: false,
      barChartData: [
        { key: 'incomeStatement', label: 'Income statement' },
        { key: 'balanceSheet', label: 'Balance sheet' },
        { key: 'cashFlow', label: 'Cash flow' },
      ],
      barChartType: { key: 'incomeStatement', label: 'Income statement' },
      barChartTime: 'Annual',
      openBarChartType: false,
      profile: [
        { key: 'Website', name: 'weburl', url: true },
        { key: 'Employees (FY)', name: 'employeeTotal' },
        { key: 'ISIN', name: 'isin' },
        { key: 'LEI', name: 'lei' },
      ],
      showFullKeyStats: false,
      contentVisibility: [
        { key: 'priceRange', value: 'Price ranges' },
        { key: 'analystsRating', value: '37 Analysts Rating' },
        { key: 'keyStats', value: 'Key stats' },
        { key: 'earnings', value: 'Earnings' },
        { key: 'performance', value: 'Performance' },
        { key: 'analysts', value: 'Analysts' },
        { key: 'profile', value: 'Profile' },
      ],
      openVisibilityDropdown: false,
      selectedVisibility: [
        'priceRange',
        'analystsRating',
        'keyStats',
        'earnings',
        'performance',
        'analysts',
        'profile',
      ],
      companyText:
        'Tesla, Inc. engages in the design, development, manufacture, and sale of electric vehicles and energy generation and storage systems. The company operates through Automotive and Energy Generation and Storage. The Automotive segment includes the design, development, manufacture, sale, and lease of electric vehicles as well as sales of automotive regulatory credits. The Energy Generation and Storage segment is involved in the design, manufacture, installation, sale, and lease of stationary energy storage products and solar energy systems, and sale of solar energy systems incentives. The company was founded by Jeffrey B. Straubel, Elon Reeve Musk, Martin Eberhard, and Marc Tarpenning on July 1, 2003 and is headquartered in Austin, TX.',
      performance: [
        { value: -6.92, timeRange: '1W' },
        { value: -33.12, timeRange: '1M' },
        { value: 21.51, timeRange: '3M' },
        { value: 5.33, timeRange: '6M' },
        { value: -10.32, timeRange: 'YTD' },
        { value: 9.32, timeRange: '1Y' },
      ],
    }
  },

  computed: {
    ...mapGetters([
      'getSymbolDetail',
      'watchLists',
      'priceLoading',
      'getSymbolPrice',
    ]),
    getKeyStats() {
      if (this.showFullKeyStats) {
        return this.keyState
      } else {
        return this.keyState.slice(0, 4)
      }
    },
  },

  methods: {
    ...mapActions([
      'removeSymbolFromUnselectWatchlist',
      'addNewSymbolToUnselectWatchlist',
    ]),
    isShow(item) {
      return this.selectedVisibility?.includes(item)
    },
    getMean(arr=[]){
      const calculateMean = arr?.reduce((a, b) => a + b, 0) / arr?.length;
      return calculateMean
    },
    getMedian(arr=[]){
      const sortedArr = [...arr].sort((a, b) => a - b);
    const mid = Math.floor(sortedArr?.length / 2);
    
    if (sortedArr?.length % 2 === 0) {
        return (sortedArr[mid - 1] + sortedArr[mid]) / 2;
    } else {
        return sortedArr[mid];
    }
    },
    getFormattedTime(t) {
      let formattedTime = moment(t).format('MMMM Do YYYY')
      return formattedTime
    },
    getUrl(url) {
      let x = url?.split('/')[2]
      return x
    },
    updatedYearFormat(y) {
      const date = moment(y)
      return date.format("MMM 'YY")
    },
    updateSeries() {
      let earning = this.getSymbolPrice?.earnings
      let recommendation = this.getSymbolPrice?.recommendations?.slice(0, 6)
      let estimateVal = earning?.map((item) => {
        return {
          x: item.year,
          y: item.estimate,
        }
      })
      let surpriseVal = earning?.map((item) => {
        return {
          x: item.year,
          y: item.surprise,
        }
      })

      let strongBuy = recommendation?.map((item) => item.strongBuy)
      let buy = recommendation?.map((item) => item.buy)
      let hold = recommendation?.map((item) => item.hold)
      let sell = recommendation?.map((item) => item.sell)
      let strongSell = recommendation?.map((item) => item.strongSell)
      let categoryUpdate = recommendation?.map((item) =>
        this.updatedYearFormat(item.period)
      )
      this.category = categoryUpdate

      let updatedArr = this.earningEstimateSeries?.map((item) => {
        return {
          ...item,
          data: estimateVal,
        }
      })
      let updatedSurpriseArr = this.earningSurpriseSeries?.map((item) => {
        return {
          ...item,
          data: surpriseVal,
        }
      })

      let updatedRecommendations = this.recommendationSeries?.map((item) => {
        if (item.name == 'Strong Buy') {
          return {
            ...item,
            data: strongBuy,
          }
        } else if (item.name == 'Buy') {
          return {
            ...item,
            data: buy,
          }
        } else if (item.name == 'Hold') {
          return {
            ...item,
            data: hold,
          }
        } else if (item.name == 'Sell') {
          return {
            ...item,
            data: sell,
          }
        } else if (item.name == 'Strong Sell') {
          return {
            ...item,
            data: strongSell,
          }
        }
      })

      this.recommendationSeries = updatedRecommendations
      this.earningEstimateSeries = updatedArr
      this.earningSurpriseSeries = updatedSurpriseArr
    },
    handleCloseRemoveModal() {
      this.openRemoveSymbolModal = false
      this.selectedWatchlist = {}
    },
    async handleRemoveSymbol() {
      let payload = {
        symbol: this.getSymbolDetail?.symbol,
        data: this.selectedWatchlist,
      }
      this.isLoading = true
      let res = await this.removeSymbolFromUnselectWatchlist(payload)
      if (res?.data) {
        this.isLoading = false
        this.handleCloseRemoveModal()
      } else {
        this.showError = 'Error while removing symbol from watchlist'
        setTimeout(() => {
          this.showError = ''
        }, 5000)
      }
      this.isLoading = false
    },
    async addToWatchlist() {
      let newVal = {
        name: this.getSymbolDetail?.symbol,
        id: this.getSymbolDetail?.metaData?.exchange,
      }
      let updatedArr = [...this.selectedWatchlist?.symbolList, newVal]
      let payload = {
        list: updatedArr,
        data: this.selectedWatchlist,
      }
      this.isLoading = true
      let res = await this.addNewSymbolToUnselectWatchlist(payload)
      if (res) {
      } else {
      }
      this.isLoading = false
    },
    handleWatchlist(watch) {
      let isPresent = watch?.symbolList?.some(
        (item) => item.name == this.getSymbolDetail?.symbol
      )
      this.selectedWatchlist = watch
      if (isPresent) {
        this.openRemoveSymbolModal = true
      } else {
        this.addToWatchlist()
      }
    },
    handleContentVisibility(key) {
      let isPresent = this.selectedVisibility.includes(key)
      if (isPresent) {
        this.selectedVisibility = this.selectedVisibility.filter(
          (item) => item !== key
        )
      } else {
        this.selectedVisibility = [...this.selectedVisibility, key]
      }
    },
    handleChartType(val) {
      this.barChartType = val
      this.openBarChartType = false
    },
    getStatesVal(val) {
      if (typeof val == 'number') {
        return commaSeperatedNum(val)
      } else {
        return val
      }
    },
    handleCompanyText() {
      this.showFullCompanyDetail = !this.showFullCompanyDetail
    },
    handleKeyStats() {
      this.showFullKeyStats = !this.showFullKeyStats
    },
    getCompanyText() {
      return this.showFullCompanyDetail
        ? this.companyText
        : this.companyText.slice(0, 220) + '...'
    },
    handleClickOutside(event) {
      if (!this.$refs.showRef.contains(event.target)) {
        this.openVisibilityDropdown = false
        this.openFavDropdown = false
      }
    },
  },

  watch: {
    getSymbolPrice(item) {
      if (item) {
        this.updateSeries()
      }
    },
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
}
</script>

<style scoped>
.gradient-bg {
  background: linear-gradient(90deg, #fb4644 0%, #cfc51b 50%, #07fe82 100%);
}
</style>
