<template>
    <div class="w-full h-full">
      <apexchart type="bar" height="100%" width="100%" :options="chartOptions" :series="series"></apexchart>
    </div>
  </template>
  
  <script>
  
  export default {
    name:'RecommendationTrends',
    props:{
        series:Array,
        category:Array,
    },
    data() {
      return {
        // Data series for each recommendation type
        // series: [
        //   { name: "Strong Buy", data: [12, 12, 14, 14, 14, 14] },
        //   { name: "Buy", data: [20, 20, 20, 23, 24, 24] },
        //   { name: "Hold", data: [14, 12, 14, 12, 14, 12] },
        //   { name: "Sell", data: [2, 2, 2, 1, 1, 2] },
        //   { name: "Strong Sell", data: [1, 2, 1, 2, 1, 1] }
        // ],
        // Chart configuration
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: { show: false }
          },
          colors: ['#00E396', '#FEB019', '#FFB700', '#FF4560', '#D100D1'], // Colors for each category
          plotOptions: {
            bar: { horizontal: false, borderRadius: 5 }
          },
          xaxis: {
            categories: this.category,
            labels: { style: { colors: "#110024" } } // White font color for dark background
          },
          yaxis: {
            title: { text: "# Analysts", style: { color: "#110024" } }, // Label for y-axis
            labels: { style: { colors: "#110024" } }
          },
          legend: {
            position: 'bottom',
            labels: { colors: '#110024' } // White font for the legend
          },
          dataLabels: { enabled: true },
          grid: { show: false }, // Disable the grid for a cleaner look
        //   tooltip: { theme: "dark" }, // Tooltip theme for a dark background
        //   theme: { mode: "dark" } // Dark theme for the chart
        }
      };
    }
  };
  </script>
  
  <style>
  body {
    background-color: #121212; /* Dark background similar to the image */
  }
  </style>
  