<template>
    <div class="w-full h-full">
        <div class="flex items-center justify-between px-3 h-[3.7em] w-full border-b dark:border-[#2D2D2D]">
          <span class="text-[#131729] dark:text-[#F6F6F6] text-[14px] font-[600]">Training Mode</span>
      </div>

      <div class="w-full p-4 space-y-[2rem]">
        <div class="flex border h-[3rem]  items-center justify-between ">
        <div class="xxs:hidden md:flex items-center w-full space-x-2 dark:!bg-[#1A1A1A] dark:!text-[#6C707A] rounded-[4px] pl-4 transition-all px-1.5 py-[0.8rem]">
        
          <div class="w-full">
            <input
             :readonly='true'
             placeholder="Current Candle"
             :value="selectedCandle"
              type="text"
              class="border-none w-full h-full focus:outline-none  focus:ring-0 text-[#4C4C4C] dark:!text-[#6C707A] uppercase text-[13px]  focus:outline-none bg-transparent"
            />
          </div>
        </div>

        <div class="flex full h-[100%]">
        
          <div
            @click="decreaseIndex"
            :class=" (isTrainingMode ? traningModeIndex === 0 : candleIndex === 0) ?'pointer-events-none opacity-50':''"
            class="w-[3rem] cursor-pointer h-full py-2 flex items-center border-l border-r border-[#cbcbcb] dark:!border-[#6C707A] justify-center hover:bg-lightGrayHover bg-appGrayLight dark:bg-[#2d2d2d] dark:hover:bg-[#252526]"
          >
            <svg
              class="w-3 h-3 text-[#383838] dark:text-white"
              viewBox="0 0 7 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5 9L1.5 5L5.5 1"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
           @click="increaseIndex"
           :class=" (isTrainingMode ? traningModeIndex >= maxLength - 1 : candleIndex >= maxLength - 1) ?'pointer-events-none opacity-50':''"
            class="w-[3rem] text-[#383838] dark:text-white cursor-pointer h-full py-2 flex items-center justify-center bg-appGrayLight hover:bg-lightGrayHover dark:bg-[#2d2d2d] dark:hover:bg-[#252526]"
          >
            <svg
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 9L5.5 5L1.5 1"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        </div>

        <div class="w-full space-y-1">
            <div v-for="symData of ohlcvData" :key="symData.left" class="w-full flex items-center justify-between">
                <div class="text-[13px]">
                    {{ symData.left }}
                </div>
                <div class="text-[#131722] text-[14px] font-[600]">
                    {{ symData.right || '-' }}
                </div>
            </div>
        </div>


      </div>


    </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import moment from 'moment'

export default{
    name:'TrainingModeComp',


    methods:{
        ...mapActions(['updateTrainingModeState']),
        decreaseIndex(){
      if (this.isTrainingMode) {
        this.$store.commit('SET_CANDLE_INDEX_UPD_TRAINING', {
          time: null,
          index: this.candleIndexJumpSelected.index - 1,
          chartHit: false,
        })
      } else {
        this.candleIndex--
      }
        },
        increaseIndex(){
          
      if (this.isTrainingMode) {
        this.$store.commit('SET_CANDLE_INDEX_UPD_TRAINING', {
          time: null,
          index: this.candleIndexJumpSelected.index + 1,
          chartHit: false,
        })
      } else {
        this.candleIndex++
      }
        },
        setTimeStampToSplitter(timeStamp, indexBased = false) {
      if (indexBased) {
        const chartData = this.dc.get_one(`chart.data`)
        // debugger
        if (chartData.length && chartData.length >= timeStamp) {
          let candleData = chartData[timeStamp]
          if (candleData && candleData[0]) {
            timeStamp = candleData[0]
          }
        } else {
          return
        }
      }
      const splitters = this.dc.get(`onchart.Splitters`)
      if (splitters) {
        splitters.forEach((splitter) => {
          // console.log("splitter.name",splitter.name)
          if (splitter.name === 'CurrentCandle') {
            return this.dc.set(`${splitter.id}.data`, [[timeStamp]])
          }
        })
      }
    },
    },
    computed:{
    ...mapGetters([ 'trainingModeData','trainingModeDataRaw','dc','isTrainingMode','formattedSymbolData','trainingModeStartingIndex', 'candleIndexJumpSelected','trainingOffset', 'candleIndexSelected','trainingModeStartingIndexSubset',  'getSymbolData']),
    candleIndex: {
      get() {
        return this.candleIndexSelected?.index
      },
      set(value) {
        this.$store.commit('SET_CANDLE_INDEX_UPD', {
          time: null,
          index: value,
          chartHit: false,
        })
      },
    },
    ohlcvData() {
      return [
        {
          left: 'Open',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[this.traningModeIndex]?.open?.toFixed(
                3
              )
            : this.formattedSymbolData?.[this.candleIndex]?.open?.toFixed(3),
        },
        {
          left: 'High',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[this.traningModeIndex]?.high?.toFixed(
                3
              )
            : this.formattedSymbolData?.[this.candleIndex]?.high?.toFixed(3),
        },
        {
          left: 'Low',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[this.traningModeIndex]?.low?.toFixed(3)
            : this.formattedSymbolData?.[this.candleIndex]?.low?.toFixed(3),
        },
        {
          left: 'Close',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[this.traningModeIndex]?.close?.toFixed(
                3
              )
            : this.formattedSymbolData?.[this.candleIndex]?.close?.toFixed(3),
        },
        {
          left: 'Volume',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[
                this.traningModeIndex
              ]?.volume?.toLocaleString()
            : this.formattedSymbolData?.[
                this.candleIndex
              ]?.volume?.toLocaleString(),
        },
        {
          left: 'Value',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[
                this.traningModeIndex
              ]?.value?.toLocaleString()
            : this.formattedSymbolData?.[
                this.candleIndex
              ]?.value?.toLocaleString(),
        },
        {
          left: 'VWAP',
          right: this.isTrainingMode
            ? this.formattedSymbolData?.[
                this.traningModeIndex
              ]?.vwap?.toLocaleString()
            : this.formattedSymbolData?.[this.candleIndex]?.vwap?.toFixed(3),
        },
      ]
    },
    traningModeIndex: {
      get() {
        return (
          this.trainingModeStartingIndex +
          (this.candleIndexJumpSelected?.index - this.trainingOffset)
        )
      },
      set(value) {
        this.$store.commit('SET_CANDLE_INDEX_UPD_TRAINING', {
          time: null,
          index: value,
          chartHit: false,
        })
      },
    },

    maxLength() {
      return this.formattedSymbolData?.length
    },

    selectedCandle() {

      return this.isTrainingMode
        ? moment(
            this.formattedSymbolData?.[this.traningModeIndex]?.date
          ).format('Do MMM YYYY')
        : moment(this.formattedSymbolData?.[this.candleIndex]?.date).format(
            'Do MMM YYYY'
          )

    },
    },

    watch:{
    maxLength(newValue, oldValue) {
      if (!oldValue && newValue && !this.candleIndex) {
        this.candleIndex = newValue - 1
      }
    },
    async candleIndexSelected(newValue, oldValue) {
      if (!oldValue) return

      if (newValue?.index < this.getSymbolData?.length) {
        const timeFrame = this.getSymbolData[newValue?.index][0]
        this.setTimeStampToSplitter(timeFrame)
      }
    },

    formattedSymbolData(newValue) {
      if (newValue?.length > 0 && this.maxLength) {
        console.log("formated change")
        this.candleIndex = this.maxLength - 1
        const splitters = this.dc.get('onchart.Splitters')
        if (splitters) {
          splitters?.forEach((splitter) => {
            if (splitter.name === 'CurrentCandle') {
              return this.dc.set(`${splitter.id}.data`, [
                [
                  moment(
                    this.formattedSymbolData?.[this.candleIndex]?.date
                  ).valueOf(),
                ],
              ])
            }
          })
        }
      }
    },

    isTrainingMode() {
      if (this.isTrainingMode) {
        this.traningModeIndex = this.trainingModeStartingIndexSubset
      } else {
        this.candleIndex = this.formattedSymbolData.length - 1
      }
    },

    async candleIndexJumpSelected(newValue, oldValue) {
      console.log('SET_CANDLE_INDEX traningModeIndex', newValue, oldValue)
      if (!oldValue || !this.isTrainingMode) return
      const formattedSymbolData = this.formattedSymbolData
      const symbolData = this.getSymbolData
      let trainingModeStartingIndex = this.trainingModeStartingIndex
      const trainingOffset = this.trainingOffset
      let selectedIndex = newValue?.index
      let selectedIndexOld = oldValue?.index
      const selectedTime = newValue?.time
      let trainingModeDataRaw = JSON.parse(
        JSON.stringify(this.trainingModeDataRaw)
      )
      if (selectedIndex <= trainingOffset) {
        if (selectedTime) {
          // When we are using chart to change data index
          this.setTimeStampToSplitter(selectedTime)
        } else {
          // when we are using arrow button to change data index
          if (selectedIndex >= 0) {
            const timeFrame = trainingModeDataRaw[selectedIndex][0]
            this.setTimeStampToSplitter(timeFrame)
          }
        }
        return
      }
      const selectedFromChart = newValue?.chartHit
      let endPoint = 0
      if (selectedFromChart) {
        let index = 0
        //   find index

        for (const dt of this.getSymbolData) {
          if (dt[0] > selectedTime) break
          index++
        }
        endPoint = index
      } else {
        endPoint = trainingModeStartingIndex + (selectedIndex - trainingOffset)
      }

      const maximumIndex = symbolData.length
      console.log(
        'trainingModeIndex',
        JSON.stringify({
          selectedIndex,
          selectedIndexOld,
          selectedTime,
          endPoint,
          maximumIndex,
          trainingModeStartingIndex,
          trainingOffset,
        })
      )
      if (endPoint <= maximumIndex && this.isTrainingMode) {
        const trainingModeData = this.trainingModeData
        const oldIndexStart = selectedIndexOld + 1
        let startPoint =
          trainingModeStartingIndex + (oldIndexStart - trainingOffset)
        if (selectedIndex > selectedIndexOld) {
          //this.getSymbolData[newValue]

          if (selectedFromChart) {
            let sliceData = symbolData.slice(startPoint, endPoint)
            let slicedDataIndex = 0
            for (let i = oldIndexStart; i <= selectedIndex; i++) {
              trainingModeDataRaw[i] = sliceData[slicedDataIndex]
              slicedDataIndex++
            }
            console.log(
              JSON.stringify({ sliceData, oldIndexStart, selectedIndex })
            )
          } else {
            trainingModeDataRaw[selectedIndex] = symbolData[startPoint]
          }
          trainingModeDataRaw = trainingModeDataRaw.map((candle) => [
            candle[0],
            candle[1] ? candle[1] : NaN,
            candle[2] ? candle[2] : NaN,
            candle[3] ? candle[3] : NaN,
            candle[4] ? candle[4] : NaN,
            candle[5] ? candle[5] : NaN,
          ])
       
          this.updateTrainingModeState({
            state: this.isTrainingMode,
            startingIndex: trainingModeStartingIndex,
            trainingDataIndex: trainingModeData.length - 1,
            trainingModeData,
            trainingModeDataRaw: trainingModeDataRaw,
          })
        } else if (selectedIndex < selectedIndexOld) {
          for (let i = trainingModeDataRaw.length - 1; i > selectedIndex; i--) {
            trainingModeDataRaw[i] = [
              trainingModeDataRaw[i][0],
              NaN,
              NaN,
              NaN,
              NaN,
              NaN,
            ]
          }
          this.updateTrainingModeState({
            state: this.isTrainingMode,
            startingIndex: trainingModeStartingIndex,
            trainingDataIndex: trainingModeData.length - 1,
            trainingModeData,
            trainingModeDataRaw: trainingModeDataRaw,
          })
          // this.dc.data.chart.data.pop()
        }
        if (selectedTime) this.setTimeStampToSplitter(selectedTime)
        else {
          const timeFrame = trainingModeDataRaw[selectedIndex][0]
          this.setTimeStampToSplitter(timeFrame)
        }
      }
    
    },

    }
}
</script>