import Price from "./primitives/price";

export default {
  data() {
    return {
      price: {},
    };
  },
  computed: {
    price_line() {
      return "priceLine" in this.sett ? this.sett.priceLine : true;
    },
  },
  methods: {
    priceInit() {
      this.price = new Price(this);
    },
  },
};
