<script>

import { Overlay } from '../../@trading-vue-js/src/index'

export default {
    name: 'RSI',
    mixins: [Overlay],
    methods: {
        meta_info() {
            return {
                author: 'StdSquad', version: '1.0.0',
                desc: 'Relative Strength Index',
                preset: {
                    name: 'RSI',
                    side: 'offchart',
                    settings: {
                        lineWidth:0.5,
                        color: '#ec206e',
                        bandColor: '#aaa',
                        backColor: '#9b9ba316',
                    }
                }
            }
        },
        use_for() { return ['RSI'] },
        calc() {
            return {
                props: {
                    days: { def: 14, text: 'days' }
                },
                conf: { renderer: 'Range' },
                update: `return rsi(close, days)[0]`
            }
        }
    }
}
</script>