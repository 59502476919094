import ChartingPatternComp from "../component/dialogBoxes/patterns/ChartingPatternComp.vue";


export const scannerList = [
  {
    id: 1,
    name: "Charting Patterns",
    tabs: [
      { name: "Charting Patterns", },
      { name: "Events" },
      { name: "Filters" },
    ],
    onClickHandler: "onScanClick",
    image: 'charting-patterns',
    value: 'patterns',
    type: "Handheld",
    status: "Active",
    lastChecked: "2024-08-01 14:23:45"
  },
  {
    id: 2,
    name: "Activity",
    onClickHandler: "onActivityClick",
    tabs: [{ name: "Activity" }, { name: "Filters" }],
    image: 'activity',
    value: 'activity',
    type: "Fixed",
    status: "Inactive",
    lastChecked: "2024-08-02 09:15:32"
  },
  {
    id: 3,
    name: "Candlestick",
    tabs: [{ name: "Candlestick" }, { name: "Filters" }],
    image: 'candlestick',
    value: 'candlesticks',
    type: "Fixed",
    status: "Inactive",
    lastChecked: "2024-08-02 09:15:32"
  },
  {
    id: 4,
    name: "Technical Indicators",
    tabs: [{ name: "Technical Indicators" }, { name: "Events" }, { name: "Filters" }],
    image: 'technical-indicators',
    value: 'indicators',
    type: "Fixed",
    status: "Inactive",
    lastChecked: "2024-08-02 09:15:32"
  },
  {
    id: 5,
    name: "Darvas Box",
    tabs: [{ name: "Darvas Box" }, { name: "Filters" }],
    image: 'darvas-box',
    value: 'darvasScan',
    type: "Fixed",
    status: "Inactive",
    lastChecked: "2024-08-02 09:15:32"
  },
  {
    id: 6,
    name: "Fundamental",
    tabs: [{ name: "Measures" }, { name: "Capitalization" }, { name: "Returns" }, { name: "Filters" }],
    image: 'fundamental',
    value: 'fundementals',
    type: "Fixed",
    status: "Inactive",
    lastChecked: "2024-08-02 09:15:32"
  },

  {
    id: 7,
    name: "Capitulation Days",
    tabs: [{ name: "Capitulation Days" },],
    image: 'capitulation-days',
    value: 'capitulation',
    type: "Fixed",
    status: "Inactive",
    lastChecked: "2024-08-02 09:15:32"
  },
  {
    id: 8,
    name: "Higher High, Higher Low",
    tabs: [{ name: "Higher High, Higher Low" },],
    image: 'hhll',
    type: "Fixed",
    status: "Inactive",
    lastChecked: "2024-08-02 09:15:32"
  },
  {
    id: 9,
    name: "Bollinger Squeeze",
    tabs: [{ name: "Bollinger Squeeze" }, { name: 'Filters' }],
    image: 'bollinger-squeeze',
    type: "Fixed",
    status: "Inactive",
    lastChecked: "2024-08-02 09:15:32"
  },
  {
    id: 10,
    name: "Divergense Scan",
    tabs: [{ name: "Divergense Scan" }, { name: 'Filters' }],
    image: 'divergense-scan',
    value: 'hhhl',
    type: "Fixed",
    status: "Inactive",
    lastChecked: "2024-08-02 09:15:32"
  },
  {
    id: 10,
    name: "Big Candle",
    tabs: [{ name: "Big Candle" }, { name: 'Filters' }],
    image: 'big-candle',
    value: 'hhhl',
    type: "Fixed",
    status: "Inactive",
    lastChecked: "2024-08-02 09:15:32"
  },
  {
    id: 11,
    name: "Heiken Ashi",
    tabs: [{ nmae: "Heiken Ashi" },],
    image: 'heiken-ashi',
    value: 'heikenAshiScan',
    type: "Fixed",
    status: "Inactive",
    lastChecked: "2024-08-02 09:15:32"
  },
];

export const tabButtons = [
  {
    id: 1,
    name: "Charting Patterns",  // Name of the tab or section
    description: "Learn about various charting patterns used in trading.", // A brief description of the content
    icon: "chart-line",  // Icon associated with this tab (could be a class or an icon name)
    isActive: true,  // Boolean to indicate if this tab is currently active
    route: "/charting-patterns",  // Route or link associated with this tab
    type: "education"  // Type or category of the content
  },
  {
    id: 2,
    name: "Events",
    description: "Detailed guide on different technical indicators.",
    icon: "tachometer-alt",
    isActive: false,
    route: "/technical-indicators",
    type: "tools"
  },
  {
    id: 3,
    name: "Filters",
    description: "Explore various trading strategies for different markets.",
    icon: "clipboard-list",
    isActive: false,
    route: "/trading-strategies",
    type: "strategies"
  },
  // {
  //   chartType: 'Charting Patterns',
  //   buttons: ['Charting Patterns','Events','Filters']
  // }
];



