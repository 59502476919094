<script>

import { Overlay } from '../../@trading-vue-js/src/index'

export default {
  name: 'BB',
  mixins: [Overlay],
  methods: {
    meta_info() {
      return {
        author: 'StdSquad', version: '1.0.0',
        desc: 'Bollinger Bands',
        preset: {
          name: 'Bollinger Bands',
          side: 'onchart',
          settings: {
            lineWidth: 0.5,
            color: '#2cc6c9ab',
            showMid: true,
            backColor: '#2cc6c94d'
          }
        }
      }
    },
    use_for() { return ['BB'] },
    calc() {
      return {
        props: {
          days: { def: 21, text: 'days' },
          stddev: { def: 2, text: 'StdDev' }
        },
        conf: { renderer: 'Channel' },
        update: `
                    let [m, h, l] = bb(close, days, stddev)
                    return [h[0], m[0], l[0]]
                `
      }
    }
  }
}
</script>