import axios from 'axios'
import logger from './logging'
import alerts from './alerts'
import Config from "../config";

const coreApiForSymbol = Config.CHARTING_API
axios.defaults.baseURL = coreApiForSymbol


axios.interceptors.response.use(null, error => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
  
    if(axios.isCancel(error)){
        return error.message
    }
  
    const message = error.response && error.response.data && error.response.data.message
    if(message){
      error.message = message
    }
    if (!expectedError) {
      logger.log(error)
      alerts.showError(error)
      //logout() //temp comment
    }
  
    return Promise.reject(error)
  })

  export function setJwt() {
    const jwt = localStorage.getItem('state')
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
  }

  export default {
    axios,
    get: axios.get,
    post: axios.post,
    put: axios.put,
    patch: axios.patch,
    delete: axios.delete,
    CancelToken: axios.CancelToken,
    setJwt
  }
  