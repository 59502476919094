<template>
      <div class="bg-white rounded-[4px] dark:border-none shadow-lg">
        <div
          v-for="(option,i) in options"
          :key="i"
          class="pl-4 pr-8 py-2.5 hover:bg-gray-100 dark:hover:bg-[#3f3f3f] dark:text-white dark:bg-[#2d2d2d] cursor-pointer"
          @click="selectOption(option)"
        >
          {{ option.label }}
        </div>
      </div>
  </template>
  
  <script>
  export default {
    name: 'SimpleDropdown',
    props: {
      options: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {

      };
    },
    methods: {
      selectOption(option) {
        this.$emit('handleSelect', option);
      },
    },
  };
  </script>
  
  <style scoped>
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    margin-top: 0.5rem;
    padding: 0.5rem 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  </style>
  