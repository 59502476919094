<template>
  <div class="p-6 h-[40rem] w-full">
    <h2 class="text-[18px] font-mediom text-black mb-4">Select Interval</h2>

    <!-- Flexbox Container -->
    <div class="flex flex-col w-4/12 rounded-[10px] bg-[#e7e7e7]">
      <!-- Radio Buttons -->
      <div
        v-for="scanType in scanTypes"
        :key="scanType.value"
        class="flex items-center px-[12px] py-[12px]"
      >
        <input
          type="radio"
          :value="scanType.value"
          v-model="selectedScanType"
          class="form-radio text-green-600 h-5 w-5"
        />
        <label class="ml-3 text-[16px] text-gray-700 font-medium">{{
          scanType.label
        }}</label>
      </div>
    </div>

    <div class="flex justify-start mt-6 gap-2">
      <!-- Type Dropdown -->
      <div class="w-3/12">
        <label class="block text-[14px] font-semibold text-[#000000]"
          >Type</label
        >
        <select
          v-model="selectedType"
          class="form-select w-[180px] h-[36px] px-3 flex justify-center items-center bg-[#F6F6F6] mt-1 border-gray-300 rounded-md shadow-sm"
        >
          <option
            v-for="option in marketTypes"
            :key="option.value"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </select>
      </div>

      <!-- Index Dropdown -->
      <div class="w-3/12" v-if="selectedType == 'market'">
        <label class="block text-[14px] font-semibold text-[#000000]"
          >Index</label
        >
        <select
          v-model="selectedIndex"
          @change="emitChange"
          class="form-select w-[180px] h-[36px] px-3 flex justify-center items-center bg-[#F6F6F6] mt-1 border-gray-300 rounded-md shadow-sm"
        >
          <option
        
            v-for="option in marketType"
            :key="option.value"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </select>
      </div>

      <!-- Category Dropdown -->
      <div class="w-3/12" v-if="selectedType == 'market'">
        <label class="block text-[14px] font-semibold text-[#000000]"
          >Category</label
        >
        <select
          v-model="selectedCategory"
          class="form-select w-[180px] h-[36px] px-3 flex justify-center items-center bg-[#F6F6F6] mt-1 border-gray-300 rounded-md shadow-sm"
        >
          <option
            v-for="option in filterTypes"
            :key="option.value"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </select>
      </div>

      <div  class="w-3/12" v-if="selectedType == 'scan-result'">
        <label class="block text-[14px] font-semibold text-[#000000]"
          >Scan Result</label
        >
        <select
         @click="onChangeHandler" 
          v-model="selectedSymbol" 
          class="form-select w-[180px] h-[36px] px-3 flex justify-center items-center bg-[#F6F6F6] mt-1 border-gray-300 rounded-md shadow-sm"
        >
          <option v-for="option in allSymbols" :key="option" :value="option" >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapActions, mapGetters } from 'vuex'
import { marketsData } from '../data/markets'
export default {
  name: 'ScanType',
  data() {
    return {
      scanTypes: [
        { value: 'D', label: 'Daily' },
        { value: 'W', label: 'Weekly' },
        { value: 'M', label: 'Monthly' },
      ],
      selectedScanType: 'D',
      selectedType: 'market',
      selectedIndex: 'ASX',
      selectedCategory: 'all',
      filterTypes: [
        {
          text: 'All',
          value: 'all',
        },
        {
          text: 'Sector',
          value: 'sector',
        },
        {
          text: 'Industry',
          value: 'industry',
        },
        {
          text: 'SubIndustry',
          value: 'subIndustry',
        },
      ],
      selectedFilterBy: 'sector',
      scanListData: null,
      selectedSymbol: null,
    }
  },
  methods: {
    ...mapActions(['retrieveSymbolsList']),
    emitChange() {
      // console.log("callllling...........")
      // Emit the new selected index value
      this.$emit('index-changed',this.selectedIndex);
    },
    onChangeHandler() {
      // console.log("Handler triggered");
      // console.log("selectedSymbol", this.selectedSymbol);
      this.$emit("selectedScannnerName",this.selectedSymbol)
    },
  },
  computed: {
    ...mapGetters([
      'getScanList',
      'getSectorGroup',
      'dataSettingsForScan',
      'dataSettings',
    ]),
    allSymbols() {
      return this.scanListData?.map((set) => set?.name) || []
    },
    modalID() {
      return this.selectedScanner.id
    },
    modalType() {
      return 'scanner'
    },
    marketType: {
      get() {
        return marketsData.map((m) => {
          return { value: m.code, text: m.desc }
        })
      },
    },
    /** @returns {String} */
    selectedTimeFrame: {
      get() {
        if (this.modalID && this.modalType === 'scanner') {
          return this.dataSettingsForScan(this.modalID, this.modalType)
            .timeFrame
        } else if (this.modalID && this.modalType === 'guru') {
          return this.dataSettingsForScan(this.modalID, this.modalType)
            .timeFrame
        } else return this.dataSettings.timeFrame
      },
      set(value) {
        if (this.modalID && this.modalType === 'scanner') {
          this.setScanDataSettings({
            type: 'scanner',
            id: this.modalID,
            settings: { timeFrame: value },
          })
        } else if (this.modalID && this.modalType === 'guru') {
          return this.setScanDataSettings({
            type: 'guru',
            id: this.modalID,
            settings: { timeFrame: value },
          })
        } else {
          this.setScanDataSettingsOld({ timeFrame: value })
        }
      },
    },
    marketTypes() {
      return this.getScanList?.length > 0
        ? [
            {
              text: 'Market',
              value: 'market',
            },
            {
              text: 'Watch list',
              value: 'watch-list',
            },
            {
              text: 'Scan Result',
              value: 'scan-result',
            },
          ]
        : [
            {
              text: 'Market',
              value: 'market',
            },
            {
              text: 'Watch list',
              value: 'watch-list',
            },
          ]
    },
  },
  mounted() {
    this.retrieveSymbolsList()
    this.scanListData = this.getScanList

    if (this.allSymbols.length > 0) {
      this.selectedSymbol = this.allSymbols[0]
    }

    console.log('scanListData==>', this.allSymbols)

    console.log('dataSettings==>', this.dataSettings?.timeFrame)
  },
  watch: {
    selectedSymbol(old, newData) {
      // console.log('asdfjsalkdjf', old, newData)
    },
  },
}
</script>
  
  <style scoped>
.form-radio:checked {
  background-color: #22c55e; /* Tailwind's green-500 */
}
</style>
  