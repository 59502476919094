import axios from 'axios'
import logger from './logging'
import alerts from './alerts'
import Config from "../config";

const baseUrlForUserApi = Config.USER_API   
const userHttp = axios.create({ baseURL:baseUrlForUserApi   })

userHttp.interceptors.response.use(null, (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    if (axios.isCancel(error)) {
      return error.message
    }
  
    const message =
      error.response && error.response.data && error.response.data.error
    if (message) {
      error.message = message
    }
    if (!expectedError) {
      logger.log(error)
      alerts.showError(error)
    }
  
    return Promise.reject(error)
  })
  

  export const createAccount = async(payload)=>{
    try {
      let res = await userHttp.post('/auth/initial-register',payload)
      if(res){
        return res
      }else{
        return false
      }
      
    } catch (e) {
      return false
    }
  
  }
  export const resendEmail = async(payload)=>{
    try {
      let res = await userHttp.post('/auth/resend-email',payload)
      if(res){
        return res
      }else{
        return false
      }
      
    } catch (e) {
      return false
    }
  
  }

  export const signin = async(payload)=>{
    try {
      let res = await userHttp.post('/auth/login',payload)
      if(res){
        return res
      }else{
        return false
      }
      
    } catch (e) {
      return false
    }
  
  }
  export const verifyToken = async(payload)=>{
    try {
      let res = await userHttp.get(`/auth/verify-token/${payload.token}`)
      if(res){
        return res
      }else{
        return false
      }
      
    } catch (e) {
      return false
    }
  
  }

export const getUserData = async (token = null) => {
  if(!token)
    token = localStorage.getItem('state')
  try {
    console.log("token==>",token)
    const { data } = await userHttp.get('/auth/profile', {
      headers: { Authorization: `Bearer ${token}` },
    })
    return data
  } catch (e) {
    console.log(e)
    // logout()
    return false
  }
}
  


export const updateUserSettings = async (settings,sec) => {
  const token = localStorage.getItem('state')
  try {
    const { data } = await userHttp.post('/user/update-settings', settings, {
      headers: { Authorization: `Bearer ${token}` },
    })
    return data
  } catch (e) {
    // logout()
    return false
  }
}