// Draws a segment, adds corresponding collision f-n

import Math2 from './math.js'

export default class Seg {
  // Overlay ref, canvas ctx
  constructor(overlay, ctx, m = '') {
    this.ctx = ctx
    this.comp = overlay
    this.T = overlay.$props.config.TOOL_COLL
    this.levelName = m
  }

  // p1[t, $], p2[t, $] (time-price coordinates)
  draw(p1, p2) {
    const layout = this.comp.$props.layout

    let x1 = layout.t2screen(p1[0])
    let y1 = layout.$2screen(p1[1])
    let x2 = layout.t2screen(p2[0])
    let y2 = layout.$2screen(p2[1])

    this.ctx.moveTo(x1, y1)
    this.ctx.lineTo(x2, y2)

    if (this.levelName) {
      this.ctx.fillStyle = this.ctx.strokeStyle
      this.ctx.textAlign = x1 < x2 ? 'left' : 'right'
      this.ctx.fillText(this.levelName, x1, y1 - 5)
    }

    this.comp.collisions.push(this.make([x1, y1], [x2, y2]))
  }

  // Collision function. x, y - mouse coord.
  make(p1, p2) {
    return (x, y) => {
      return Math2.point2seg([x, y], p1, p2) < this.T
    }
  }
}
