var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full"},[_c('FormLayout',[(_vm.success)?_c('div',[_c('div',[_c('span',{staticClass:"largeText",staticStyle:{"font-size":"44px"}},[_vm._v("Your password has been reset. "),_c('router-link',{attrs:{"to":{ name: 'SignIn' }}},[_c('span',{staticClass:"hover:underline cursor-pointer",staticStyle:{"color":"#f20e17"}},[_vm._v("Sign In")])])],1)])]):_c('div',{staticClass:"w-full h-full md:space-y-7"},[_c('div',{staticClass:"formFreeTrial mt-[1.5rem]",staticStyle:{"height":"15%","display":"flex","flex-direction":"column","justify-content":"center","font-size":"40px","color":"#300003","font-weight":"bold"}},[_c('div',{staticClass:"formSevenDayTrial text-appBlue border !border-appBlue",staticStyle:{"font-size":"18px","width":"fit-content","border-radius":"5px","padding":"5px 15px 5px 15px","font-weight":"500","display":"none","margin-bottom":"10px"}},[_vm._v(" 3-MONTHS FREE TRIAL ")]),_c('span',{staticClass:"headerTitle text-appGrayDark"},[_vm._v(" Reset Password ")]),(_vm.showResponseError)?_c('span',{staticClass:"errorText",staticStyle:{"color":"#f20e17","font-size":"15px","margin-top":"4%","font-weight":"300"}},[_vm._v(_vm._s(_vm.showResponseError))]):_vm._e()]),_c('div',{staticClass:"inputHeight",staticStyle:{"height":"8%"}},[_c('DynamicInput',{attrs:{"type":_vm.showPassword ? 'text' : 'password',"placeholder":"New password","iconImg":require(`@/assets/${
              _vm.isPasswordError
                ? 'redEye.svg'
                : _vm.showPassword
                ? 'openEye.svg'
                : 'closeEye.svg'
            }`),"isError":_vm.isPasswordError},on:{"password-visibility-toggled":_vm.handlePasswordVisibilityToggle},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',{staticStyle:{"margin-top":"1%"}},[_c('span',{staticClass:"errorText",staticStyle:{"color":"#f20e17","font-size":"13px"}},[_vm._v(_vm._s(_vm.passwordErrorMsg))])]),_c('div',{staticClass:"inputHeight mt-[2rem]",staticStyle:{"height":"8%"}},[_c('DynamicInput',{attrs:{"type":_vm.showPassword ? 'text' : 'password',"placeholder":"Confirm password","iconImg":require(`@/assets/${
              _vm.isNewPasswordError
                ? 'redEye.svg'
                : _vm.showPassword
                ? 'openEye.svg'
                : 'closeEye.svg'
            }`),"isError":_vm.isNewPasswordError},on:{"enter-pressed":_vm.handleReset,"password-visibility-toggled":_vm.handlePasswordVisibilityToggle},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('div',{staticStyle:{"margin-top":"1%"}},[_c('span',{staticClass:"errorText",staticStyle:{"color":"#f20e17","font-size":"13px"}},[_vm._v(_vm._s(_vm.newPasswordErrorMsg))])]),_c('div',{staticClass:"btnStyle inputHeight !mt-10",staticStyle:{"height":"8%"}},[_c('button',{class:_vm.password && _vm.confirmPassword
              ? 'bg-appBlue hover:bg-blueHover'
              : 'bg-[#E0E0E0] hover:bg-lightGrayHover',staticStyle:{"height":"100%","width":"100%","border-radius":"5px","font-size":"18px","display":"flex","align-items":"center","justify-content":"center","cursor":"pointer"},style:({
            color: _vm.password && _vm.confirmPassword ? 'white' : '#828282',
            cursor: _vm.isLoading ? 'not-allowed' : 'pointer',
            pointerEvents: _vm.isLoading ? 'none' : 'auto',
          }),on:{"click":_vm.handleReset}},[(_vm.isLoading)?_c('span',{staticStyle:{"margin-right":"10px"}},[_c('Loader',{attrs:{"customClass":"w-8 h-8"}})],1):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.isLoading ? 'Sending link' : 'Send link')+" ")]),(_vm.password && _vm.confirmPassword)?_c('div',{staticStyle:{"margin-left":"8%"}},[_c('img',{attrs:{"src":require("../assets/rightarrow.svg")}})]):_vm._e()])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }