<template>
  <div class="w-full h-full">
    <div
      class="w-full h-[4rem] px-4 flex items-center mt-3 font-[700] text-[16px]"
    >
      <span>Intervals</span>
    </div>
    <ul
      class="!w-full h-full bg-white -overflow-y-scroll dark:!bg-[#1A1A1A] dark:!border-[#6C707A] leading-3 flex flex-col items-center justify-between rounded-[3px] overflow-y-scroll scroll-container"
    >
      <div class="w-full h-full flex flex-col -space-y-1">
        <li
          class="flex w-full h-[5rem] items-center space-x-2 gap-2 px-8 py-4 -border-b text-appBtnTxt dark:!text-[#6C707A] -rounded-md"
          v-for="(interval, index) in intervals"
          :key="index"
          @click="handleSelectInterval(interval.key)"
          :class="
            selectedInterval === interval.key
              ? 'bg-appBtnActive dark:!bg-transparent text-appBtnTxt -!border-appBlue'
              : '-hover:border-[#E4E4E4] dark:hover:!bg-[#2D2D2D] hover:bg-appBtnHover cursor-pointer -!border-[transparent]'
          "
        >
          <div class="text-[16px]">{{ interval.label }}</div>
        </li>
      </div>
    </ul>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'IntervalVue',
  data() {
    return {
      intervals: [
        { key: 'D', label: 'Daily' },
        { key: 'W', label: 'Weekly' },
        { key: 'M', label: 'Monthly' },
      ],

      selectedInterval: 'D',
    }
  },
  components: {},
  methods: {
    ...mapActions([
      'setNewChartType',
      'toggleTheme',
      'handleNextSymbol',
      'handlePreviousSymbol',
      'toggleScannerModal',
      'setNewChartTimeFrame',
      'updateTrainingModeState',
    ]),

    handleSelectInterval(name) {
      console.log('name==>', name)
      // If the clicked dropdown is already open, close it. Otherwise, open the new dropdown.
      this.selectedInterval = name
      this.$emit('timeFrameEmit', name)
      console.log('intervalname', name, this.selectedInterval)
      this.setNewChartTimeFrame({ timeFrame: name })
      this.$emit('handleSelectInterval')
    },
  },
  computed: {
    ...mapGetters(['timeFrame']),
  },
  mounted() {
    this.selectedInterval = this.timeFrame
  },
  watch: {},
}
</script>
<style scoped></style>
