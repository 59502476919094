<template>
  <!-- <dialog-box
    modalHeader="Trailing Stop"
    :open="isOpen"
    @onClose="onClose"
    @ok="updateData"
  > -->
  <!-- themeDarkMode ? {background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:themeFontColor -->
  <!-- themeDarkMode ? {color:'#A2A2A2'}:themeFontColor -->
  <BaseModal :open="isOpen"> 
  <div style="height: 40vh; padding: 8px;width:40rem">
    <div class="h-[15%] flex items-center px-4 justify-between">
          <span class="text-[18px] dark:text-white font-sans font-[500]">Trailing Stop</span>
            <div
             @click="onClose"
              class="cursor-pointer p-[8px] rounded-md hover:bg-[#F0F3FA] dark:bg-[#FFFFFF1A] dark:hover:bg-[#2D2D2D]"
            >
          <span>
            <svg
              class="dark:text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
              width="18"
              height="18"
            >
              <path
                stroke="currentColor"
                stroke-width="1.2"
                d="m1.5 1.5 15 15m0-15-15 15"
              ></path>
            </svg>
          </span>
        </div>
        </div>
      <div class="w-full h-[70%] border-t border-b dark:border-[#2D2D2D] pt-3 px-4">
      <sui-form style="font-size: 14px;">
        <sui-form-field>
          <label class="dark:!text-[#A2A2A2]">Entry price</label>
          <sui-form-field>
            <input
            class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2] p-2"
              type="number"
              placeholder="Entry Price"
              step="0.01"
              min="0"
              v-model="entryPrice"
            />
          </sui-form-field>
        </sui-form-field>

        <sui-form-fields>
          <sui-form-field width="eight">
            <label class="dark:!text-[#A2A2A2]">Trailing Type</label>
            <sui-dropdown
             class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2]"
              selection
              :options="trailingType"
              v-model="selectedTrailingType"
            />
          </sui-form-field>
          <sui-form-field
            v-if="selectedTrailingType === 'step-price'"
            width="three"
          >
            <label class="dark:!text-[#A2A2A2]">Step Price</label>
            <input
            :style="themeDarkMode ? {background:'#505050',borderColor:'#505050',color:'#A2A2A2'}:[themeFontColor,themeSecondaryColor]"
              type="number"
             class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2] P-2"
              
              name="stepPrice"
        
              placeholder="Step Price"
              min="0"
              step="0.01"
              v-model="stepPrice"
            />
          </sui-form-field>

          <sui-form-field width="three" v-if="selectedTrailingType === 'value'">
            <label class="dark:!text-[#A2A2A2]">Position Size</label>
            <input
           class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2]"
              type="number"
              name="positionSize"
              placeholder="Position Size"
              value="1000"
            />
          </sui-form-field>
          <h3 v-if="selectedTrailingType === 'value'" :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor">and</h3>
          <sui-form-field width="three" v-if="selectedTrailingType === 'value'">
            <label class="dark:!text-[#A2A2A2]">Risk Value</label>
            <input
           class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2]"
              type="number"
              name="riskedValue"
              placeholder="Risk Value"
              value="500"
            />
          </sui-form-field>

          <!-- % -->
          <sui-form-field
            width="three"
            v-if="selectedTrailingType === '%value'"
          >
            <label class="dark:!text-[#A2A2A2]">Position Size</label>
            <input
           class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2]"
              type="number"
              name="positionSize"
              placeholder="Position Size"
              value="1000"
            />
          </sui-form-field>
          <h3 v-if="selectedTrailingType === '%value'" :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor">and</h3>
          <sui-form-field
            width="three"
            v-if="selectedTrailingType === '%value'"
          >
            <label  class="dark:!text-[#A2A2A2]">Risk %</label>
            <input
           class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2]"
              type="number"
              name="riskedValue"
              placeholder="Risk Value %"
              value="10"
            />
          </sui-form-field>

          <!-- atr -->
          <sui-form-field width="three" v-if="selectedTrailingType === 'atr'">
            <label class="dark:!text-[#A2A2A2]">Day Period</label>
            <input
             class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2]"
              type="number"
              name="atrPeriod"
              placeholder="ATR Period"
              value="10"
            />
          </sui-form-field>
          <h3 v-if="selectedTrailingType === 'atr'" :style="themeDarkMode ? {color:'#A2A2A2'}:themeFontColor">and</h3>
          <sui-form-field width="three" v-if="selectedTrailingType === 'atr'">
            <label  class="dark:!text-[#A2A2A2]">Multiplier</label>
            <input
           class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2] "
              type="number"
              name="multiplier"
              placeholder="Multiplier"
              value="5"
            />
          </sui-form-field>
        </sui-form-fields>

        <sui-form-field
          v-if="
            selectedTrailingType === 'atr' ||
            selectedTrailingType === 'step-price'
          "
        >
          <label class="dark:!text-[#A2A2A2]">Stop Price</label>
          <sui-form-field>
            <input
            class="dark:!bg-[#505050] dark:!border-[#505050] dark:!text-[#A2A2A2] p-2"
              type="number"
              placeholder="Stop Price"
              step="0.01"
        
              min="0"
              v-model="stopPrice"
            />
          </sui-form-field>
        </sui-form-field>
      </sui-form>
    </div>

    <div class="flex justify-end gap-3  h-[15%]  px-4 items-center pt-2">
        <div @click="onClose" class="text-[#292929] font-[600] text-[12px] hover:bg-[#F6F6F6] dark:!border-[#2D2D2D] dark:bg-[#FFFFFF1A] dark:hover:bg-[#232323] dark:text-[#F6F6F6] px-6 cursor-pointer py-2 border-[#BCBCBC] border rounded-[6px]">
          Close
        </div>
        <div @click="updateData" class="text-[#FFFFFF] bg-appBlue  font-[600] flex items-center space-x-2 text-[12px] hover:bg-blueHover dark:!border-[#2D2D2D] px-6 cursor-pointer py-2 border-[#BCBCBC] border rounded-[6px]">
        <span>
          Save
        </span>       
        </div>
      </div>

    </div>
  </BaseModal>
  <!-- </dialog-box> -->
</template>

<script>
import DialogBox from "@/baseComponents/DialogBox.vue";
import { mapActions, mapGetters } from "vuex";
import {chartEvents} from "../../chartEvents";
import BaseModal from "@/component/BaseModal.vue";

export default {
  name: "TrailingStop",
  components: {
    DialogBox,
    BaseModal
  },
  props: {
    trialingStopData:{
      type:Object,
      default: () => {}
    }
  },
  methods: {
    onClose() {
      this.$emit("hideTrailingStop");
    },
    updateData() {
      let identity = "UpdateTrailingStopSettings"+this.trialingStopData?.$uuid;
      // let identity = "UpdateTrailingStopSettings";
      chartEvents.$emit(identity,{
        stepPrice:this.stepPrice,
        stopPrice:this.stopPrice,
        entryPrice:this.entryPrice,
        uuid:this.trialingStopData?.$uuid,
      });
      this.$emit("hideTrailingStop");
      
    },
  },
  data() {
    return {
      isOpen:false,
      entryPrice:0,
      stopPrice:0,
      stepPrice:0,
      trailingType: [
        { text: "based on step price", value: "step-price" },
        { text: "$ value of a parcel", value: "value" },
        { text: "% value of a parcel", value: "%value" },
        { text: "ATR", value: "atr" },
      ],
      selectedTrailingType: "step-price",
    };
  },
  computed: {
    ...mapGetters([ "themeDarkMode", "themeFontColor", "themeSecondaryColor", "themePrimaryColor"]),
  },
  created() {
    console.log("TrailingStopTool",this.$props.trialingStopData)
    if(this.$props?.trialingStopData){
      this.stepPrice = this.$props?.trialingStopData?.stepPrice
      this.entryPrice = this.$props?.trialingStopData?.entryPrice
      this.stopPrice = this.$props?.trialingStopData?.stopPrice
    }
  },
  mounted() {
    if(this.$props?.trialingStopData){
      this.isOpen = true;
    }
  }
};
</script>

<style></style>
