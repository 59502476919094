<!-- BaseModal.vue -->

<template>
  <div class="base-modal overflow-hidden">
    <div
      class="fixed inset-0 backdrop-blur-sm bg-[#00000050] overflow-hidden transition-opacity base-modal"
    ></div>

    <div class="fixed base-modal inset-0 overflow-hidden">
      <div
        class="flex items-center justify-center min-h-full p-4 text-center sm:p-0"
      >
        <div
          @click.stop
          :class="[customClass, getTheme ? ' dark:bg-black' : ' bg-[#FFFFFF]']"
          class="relative dark:border-[#32323280] dark:shadow dark:border shadow-xl -p-2 overflow-x-auto text-left overflow-hidden transform transition-all sm:my-8 rounded-[8px]"
        >
          <slot> </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { index } from 'd3'
import { mapGetters } from 'vuex'

export default {
  name: 'BaseModal',
  props: {
    customClass: {
      type: String,
      default: '',
    },
    zIndex: Number,
  },
  computed: {
    ...mapGetters(['getTheme']),
  },
}
</script>

<style scoped>
.base-modal {
  z-index: 1005 !important;
}
</style>
