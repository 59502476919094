import Overlays from 'tvjs-overlays'




//--- custom chart overlays
import Bars from './chartTypes/Bars'
// import Candles from './chartTypes/Candles'
import Candles from '../@trading-vue-js/src/components/overlays/Candles.vue'
import HeikenAshi from './chartTypes/HeikenAshi'
import LineChart from './chartTypes/LineChart'
import Kagi from './chartTypes/Kagi'
import PnF from './chartTypes/PnF'
import UpAndDown from './chartTypes/UpAndDown'
import BarTrend from './chartTypes/BarTrend'
import CompareChart from './chartTypes/CompareChart'
import CompareChartMain from './chartTypes/CompareChartMain'
import Spline from './chartTypes/Spline'

//--- custom indicator overlays


import VolumeOBV from './indicators/VolumeOBV'
import MACD from './indicators/MACD'
import TradeLine from './indicators/Tradeline.vue'
import CovalData from "./indicators/CovalData"
import BB from './indicators/BB'
import SAR from './indicators/SAR'
import ATR from './indicators/ATR'
import RSI from './indicators/RSI'
import SMA from './indicators/SMA'
import ROC from './indicators/ROC'
import SMAOBV from './indicators/SMAOBV'
import SMAVolume from './indicators/SMAVolume'
import WilliamsR from './indicators/WilliamsR'
import TCI from './indicators/TCI'
import XMA from './indicators/XMA'
import WMA from './indicators/WMA'
import VWAP from './indicators/VWAP'
import Stoch from './indicators/Stoch'
import Value from './indicators/Value'
import DarvasBox from './indicators/DarvasBox'
import ChartPatterns from './indicators/ChartPatterns'
import VolumePrimary from './indicators/VolumePrimary.vue'
import ArrowData from './indicators/ArrowData.vue'


//--- custom tools overlays
import FibonacciRetrace from './tools/FibonacciRetrace'
import FibonacciTime from './tools/FibonacciTime'
import HLineTool from './tools/HLineTool'
import ArcTool from './tools/ArcTool'
import TrendLineTool from './tools/TrendLine'
import ChannelTool from './tools/ChannelTool'
import TradeVisualizerTool from './tools/TradeVisualizerTool'
import RectangleTool from './tools/RectangleTool'
import TrailingStopTool from './tools/TrailingStopTool'
import TextTool from './tools/TextTool'
import RangeTool from './tools/RangeTool'
import SplineExt from "./chartTypes/SplineExt.vue";
export default {
  //--- default overlays
  ...Overlays,
  //--- chart types
  Bars,
  VolumePrimary,
  Candles,
  Spline,
  SplineExt,
  HeikenAshi,
  LineChart,
  Kagi,
  BB,
  ROC,
  SMA,
  SAR,
  WilliamsR,
  ATR,
  RSI,
  PnF,
  BarTrend,
  UpAndDown,
  CompareChart,
  CompareChartMain,
  //--- indicators
  ArrowData,
  VolumeOBV,
  MACD,
  TradeLine,
  CovalData,
  SMAOBV,
  SMAVolume,
  TCI,
  XMA,
  WMA,
  VWAP,
  Value,
  Stoch,
  DarvasBox,
  ChartPatterns,
  //--- tools
  FibonacciRetrace,
  FibonacciTime,
  TrendLineTool,
  HLineTool,
  ChannelTool,
  TradeVisualizerTool,
  RectangleTool,
  TrailingStopTool,
  TextTool,
  RangeTool,
  ArcTool
}

