<template>
  <div
    @mouseover="MouseOverTableData"
    @mouseleave="MouseLeaveTableData"
    :class="
      isScrollBarVisible
        ? 'scrollable-container-visible'
        : 'scrollable-container-invisible'
    "
    class="!h-full overflow-x-hidden dark:border-[#1E293B] dark:!bg-[#1A1A1A] w-full !pb-[85px]"
  >
    <table
      v-if="symbols.length > 0"
      class="w-full !sshrink-0 text-[15px] bg-white text-left rtl:text-right"
    >
      <thead
        class="z-50 sticky top-0 border-b dark:!bg-[#1A1A1A] bg-white overflow-x-auto"
      >
        <tr class="sticky">
          <th
            class="py-2 px-4 border-b border-r-2 dark:border-[#2D2D2D] text-left font-medium dark:text-[#F6F6F6] text-gray-700"
          >
            Symbol
          </th>
          <th
            class="py-2 px-4 border-b border-r-2 dark:border-[#2D2D2D] text-left font-medium dark:text-[#F6F6F6] text-gray-700"
          >
            Last
          </th>
          <th
            class="py-2 px-4 border-b border-r-2 dark:border-[#2D2D2D] text-left font-medium dark:text-[#F6F6F6] text-gray-700"
          >
            Chg
          </th>
          <th
            class="py-2 px-4 border-b text-left font-medium dark:text-[#F6F6F6] text-gray-700"
          >
            Chg%
          </th>
        </tr>
      </thead>
      <tbody class="w-full">
        <tr
          @mouseover="isMouseOver(index)"
          @mouseleave="isMouseLeave"
          v-for="(item, index) in symbols"
          :key="index"
          class="hover:bg-[#F0F3FA] dark:!bg-[#1A1A1A] dark:hover:bg-[#2D2D2D] cursor-default hover:border-b dark:border-[#2D2D2D] !border-b px-2 h-[3.5rem]"
        >
          <td
            class="dark:text-[#F6F6F6] text-[black] flex justify-start gap-2 items-center -border text-[15px] w-full h-[3.5rem] px-3"
          >
            <div
              class="!w-[30px] h-[30px] flex justify-center items-center bg-blue-400 rounded-full"
            >
              <TickerIcon :ticker="item.symbol" />
            </div>
            <div @click="handleSymbol(item.symbol)" class="hover:underline cursor-pointer">
              {{ item.symbol }}
            </div>
            <div class="bg-gray-400 rounded-full w-[8px] h-[8px]"></div>
          </td>
          <td
            class="dark:text-[#F6F6F6] text-[black] text-[14px] w-full h-[3.5rem] px-3"
          >
            {{ 32.2 }}
          </td>
          <td
            class="dark:text-[#F6F6F6] text-[black] text-[14px] w-full h-[3.5rem] px-3"
          >
            {{ 0.56 }}
          </td>
          <td
            :class="2.11 < 0 ? 'text-red-500' : '!text-green-400'"
            class="dark:text-[#F6F6F6] text-[black] text-[14px] w-full h-[3.5rem] -border px-3 flex items-center"
          >
            <span>{{ 2.11 < 0 ? '' : '+' }}</span>
            {{ 2.11 }}%
          </td>
        </tr>
      </tbody>
    </table>

     <div v-else class="flex h-[50%] w-full justify-center items-center text-center">
          <span class="text-[1.5rem] text-appBlue font-medium">Run scanners to see results.
            <span class="underline cursor-pointer hover:text-darkBlue" @click="handleRun">Run</span></span>
        </div>
    </div>
  </template>
  <script>
import { mapGetters,mapActions } from 'vuex'
import TickerIcon from './TickerIcon'
  export default {
    name: 'SymbolInfo',
    data() {
      return {
        isMouseOnSymbol: null,
        isScrollBarVisible: false,
      }
    },
    props: {
      item: Object,
    },
    components: {
      TickerIcon
    },
    methods: {
      ...mapActions(['toggleScannerModal']),
      handleRun(){
        this.toggleScannerModal(true)
      },
      clickHandler(){
          console.log("symbols==>",this.symbols)
      },
      handleSymbol(sym) {
      this.$emit('getSymbolData', sym)
    },
      isMouseOver(id) {
        console.log("id===>",id)
        this.isMouseOnSymbol = id
  
        console.log('isMouseOnIndex', this.isMouseOnIndex)
      },
      isMouseLeave() {
        this.isMouseOnSymbol = null
  
        console.log('isMouseOnIndex', this.isMouseOnIndex)
      },
      MouseOverTableData() {
        this.isScrollBarVisible = true
      },
      MouseLeaveTableData() {
        this.isScrollBarVisible = false
      },
      openRemoveSymbolModal(id) {
        this.$emit('openRemoveSymbolModal', id)
      },
    },
    computed: {
        ...mapGetters(['getScanResult']),
        symbols() {
            return this.getScanResult // Access the getter here
    }
    },
    mounted() {
    },
  watch: {
    symbols(){
      const firstTickerName = this.getScanResult[0].symbol
      this.handleSymbol(firstTickerName)
    }
  },
}
 
</script>
<style scoped>
.scrollable-container {
  max-height: 100%; /* Set the desired height */
  overflow-y: auto;
  border: 1px solid #fff; /* Optional border */
  border-radius: 4px;
}

.content {
  padding: 16px;
}

/* Scrollbar styles visible*/
.scrollable-container-visible::-webkit-scrollbar {
  width: 7px; /* Width of the scrollbar */
  background: transparent !important;
}

.scrollable-container-visible::-webkit-scrollbar-track {
  background: transparent !important; /* Background of the track  */
  display: none;
}

.scrollable-container-visible::-webkit-scrollbar-corner {
  background: transparent !important;
  display: none;
}

.scrollable-container-visible::-webkit-scrollbar-thumb:hover {
  background: #9198b8; /* Color of the handle on hover */
}

.scrollable-container-visible::-webkit-scrollbar-thumb {
  background: #bdc0c8;
  border-radius: 10px;
}

/* invisible */

.scrollable-container-invisible::-webkit-scrollbar {
  width: 7px; /* Width of the scrollbar */
  background: transparent !important;
}

.scrollable-container-invisible::-webkit-scrollbar-thumb:hover {
  background: transparent; /* Color of the handle on hover */
}

.scrollable-container-invisible::-webkit-scrollbar-thumb {
  background: transparent;
}

.scrollable-container-invisible::-webkit-scrollbar-track {
  background: transparent; /* Background of the track  */
  display: none;
}
</style>
