<script>
// Line drawing tool
// TODO: make an angle-snap when "Shift" is pressed
import { Overlay, Tool } from '../../@trading-vue-js/src/index'
import Pin from './primitives/pin.js'
import Seg from './primitives/seg.js'
import {mapGetters} from "vuex";
import ToolSavingMixins from "./ToolSavingMixins";
export default {
  name: 'TrendLine',
  mixins: [Overlay, Tool,ToolSavingMixins],
  methods: {
    meta_info() {
      return { author: 'LakshanPerera', version: '1.1.0' }
    },
    tool() {
      return {
        // Descriptor for the tool
        group: 'Lines',
        type: 'Trend',
        hint: 'This hint will be shown on hover',
        data: [], // Default data
        settings: {
          color: '#42b28a',
          lineWidth: 2,
          lineStyle: 'solid',
          lineHeadStyle: 'normal',
          settingsList: ['color', 'lineWidth', 'lineStyle', 'lineHeadStyle']
        } // Default settings
      }
    },
    // Called after overlay mounted
    init() {
      // First pin is settled at the mouse position
      this.pins.push(new Pin(this, 'p1'))
      // Second one is following mouse until it clicks
      this.pins.push(
        new Pin(this, 'p2', {
          state: 'tracking'
        })
      )
      this.pins[1].on('settled', () => {
        // Call when current tool drawing is finished
        // (Optionally) reset the mode back to 'Cursor'

        this.updateSettingsState(this)
        this.set_state('finished')
        this.$emit('drawing-mode-off')
        this.$emit('object-selected')
      })
      /*if(this.p1 && this.p2 && this.p1.length > 2 && this.p2.length > 2){
        // const { t_magnet } =
        console.log(JSON.stringify(this.p1))
        console.log(JSON.stringify(this.p2))
        const p1Node = Math.round( this.layout.ti_map.t2i(this.p1[2]))
        const p2Node = Math.round( this.layout.ti_map.t2i(this.p2[2]))
        console.log(p1Node,this.p1[2],p2Node,this.p2[2],p2Node - p1Node)
        if(this.p1[0] !== p1Node){
          this.p1[0] = p1Node
        }

        if(this.p2[0] !== p2Node){
          this.p2[0] = p2Node
        }
        // console.log("you are migrated",JSON.stringify(cid),this.p1[2])
      }*/
      this.registerMouseEvent(this)
    },
    draw(ctx) {
      if (!this.p1 || !this.p2) return
      ctx.lineWidth = this.line_width
      ctx.strokeStyle = this.color
      ctx.fillStyle = this.color

      ctx.beginPath()

      if (this.sett.lineStyle === 'dashed') {
        ctx.setLineDash([5, 10])
      } else if (this.sett.lineStyle === 'dotted') {
        ctx.setLineDash([3, 4])
      }
      new Seg(this, ctx).draw(this.p1, this.p2)

      ctx.stroke()
      ctx.setLineDash([])

      if (this.lineHeadStyle === 'arrow') this.drawArrowhead(ctx)
      // console.log("render line",JSON.stringify(this.$props.settings.p1))
      this.render_pins(ctx)
    },
    /**
     * @param {CanvasRenderingContext2D} ctx canvas context
     */
    drawArrowhead(ctx) {
      const { t2screen, $2screen } = this.layout
      const x1 = t2screen(this.p1[0])
      const y1 = $2screen(this.p1[1])
      const x = t2screen(this.p2[0])
      const y = $2screen(this.p2[1])

      let radians = Math.atan((y - y1) / (x - x1))
      const addRad = ((x >= x1 ? 90 : -90) * Math.PI) / 180
      radians += addRad
      const xPct = (x - x1) / Math.max(x, x1)
      const yPct = (y - y1) / Math.max(y, y1)
      const xOffset = 5 * xPct
      const yOffset = 5 * yPct

      ctx.save()
      ctx.beginPath()
      ctx.translate(x + xOffset, y + yOffset)
      ctx.rotate(radians)
      ctx.moveTo(0, 0)
      ctx.lineTo(5 * this.line_width, 5 * this.line_width)
      ctx.lineTo(-5 * this.line_width, 5 * this.line_width)
      ctx.closePath()
      ctx.restore()
      ctx.fill()
    },
    use_for() {
      return ['TrendLine']
    },
    data_colors() {
      return [this.color]
    }
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters(['themePrimaryColor','themeSecondaryColor','themeFontColor']),
    sett() {
      return this.$props.settings
    },
    p1() {
      return this.$props.settings.p1
    },
    p2() {
      return this.$props.settings.p2
    },
    line_width() {
      return this.sett.lineWidth || 0.9
    },
    color() {
      return this.sett.color || '#42b28a'
    },
    lineHeadStyle() {
      return this.$props.settings.lineHeadStyle
    }
  },
  data() {
    return {}
  },
  created() {
    this.updateSettingsState(this)
  }
}
</script>
