<template>
  <div style="width: 100%; height: 100%; overflow: hidden">
    <div style="height: 10%">
      <Header></Header>
    </div>
    <div
      class="welcomeParent"
      style="
        height: 85%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 10rem;
      "
    >
      <div
        style="
          width: 60%;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
        class="welcome-container h-full flex flex-col justify-center -bg-slate-200"
      >
        <div class="w-full -bg-slate-400">
          <span
            class="extraLargeText text-appBlue xxs:leading-[50px] md:leading-[80px] xxs:!text-[30px] xs:!text-[34px] md:!text-[50px] 1.5lg:!text-[70px] font-bold"
            style="font-weight: bold"
            >Your account has been
            <span class="text-[#300003]">Created</span></span
          >
        </div>
        <div
          style="
            margin-bottom: 2rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          "
        >
          <div class="welcome-Detail" style="width: 50%; line-height: 1.5">
            <span class="mediumText" style="font-size: 13px"
              ><span
                class="text-appBlue"
                style="font-weight: 800; line-height: 45px"
                >Enjoy your 3-months free trial!</span
              >
              Our software is a Web Application and is best used on a laptop or
              computer device.</span
            >
          </div>
        </div>

        <div
          class="mainFormContainer w-[50px] h-[50px]"
          style="
            /* height: 30%;
            width: 30%; */
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <button
            class="bg-appBlue hover:bg-blueHover"
            @click="handleRoute()"
            style="
              padding: 14px;
              height: 100%;
              color: white;
              cursor: pointer;
              width: 100%;
              border-radius: 5px;
              font-size: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            Start
            <div style="margin-left: 8%">
              <img src="../assets/rightarrow.svg" />
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="smallFooter" style="display: none; width: 85%">
      <SmallScreenFooter></SmallScreenFooter>
    </div>

    <div style="height: 5%" class="hideDetail">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from '../component/Header.vue'
import Footer from '../component/Footer.vue'
import SmallScreenFooter from '../component/SmallScreenFooter.vue'

export default {
  name: 'WelcomePage',
  props: {
    token: String,
  },
  components: {
    Footer,
    Header,
    SmallScreenFooter,
  },
  data() {
    return {}
  },
  methods: {
    handleRoute() {
      localStorage.setItem('auth', true)
      console.log('token', this.token)
      localStorage.setItem('state', this.token)
      this.$router.push('/')
    },
  },
}
</script>

<style scoped></style>
