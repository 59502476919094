<template>
  <div class="w-full h-full">
    <div class="w-full -bg-green-300">
      <RightBarHead
        :mainHeading="'Comparing Symbols'"
        :selectedRight="selectedRight"
      />
    </div>

    <div class="w-full !h-[calc(100%-4.5rem)] -bg-green-400">
      <ComparingSymbols />
    </div>
  </div>
</template>
<script>
import ComparingSymbols from '../../dialogBoxes/ComparingSymbols.vue'
import RightBarHead from '../../RightBarHead.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  data() {
    return {}
  },
  components: {
    RightBarHead,
    ComparingSymbols,
  },
  props: {
    mainHeading: String,
    subHeading: String,
    selectedRight: String,
  },
  methods: {},
  computed: {
    ...mapGetters(['getScanResult']),
  },

  mounted() {
    console.log('getScanResult', this.getScanResult)
  },
  watch: {},
}
</script>
<style scoped></style>
