<!-- BaseModal.vue -->

<template>
    <div class="base-modal">
       
        <div class="fixed inset-0 backdrop-blur-sm bg-[#00000050] transition-opacity base-modal">
            
            <div class="flex justify-center items-center min-h-full p-4 text-center sm:p-0">

                <div
                    @click.stop
                    :class="[customClass,getTheme?' dark:bg-black':' bg-white']"
                    class="relative dark:border-[#32323280] dark:shadow dark:border shadow-xl p-2 overflow-x-auto text-left overflow-hidden transform transition-all sm:my-6 rounded-3xl"
                >
                    <slot>
                        
                    </slot>
                 </div>
               
            </div>

        </div>

    </div>
</template>
  
  <script>
import { mapGetters } from 'vuex';

  export default {
    name:"OwnBaseModal",
    props: {
      customClass: {
        type: String,
        default: "",
      },
    },
    computed: {
      ...mapGetters(['getTheme']),

    },
  };
  </script>
  
  <style scoped>
  .base-modal {
    z-index: 1000;
  }
  </style>
  