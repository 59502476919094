<script>
// Line drawing tool
import { Overlay, Tool } from '../../@trading-vue-js/src/index';
import Pin from "./primitives/pin.js";
import Seg from "./primitives/seg.js";
import {mapGetters} from "vuex";
export default {
  name: "FibonacciTime",
  mixins: [Overlay, Tool],
  methods: {
    meta_info() {
      return { author: "C451", version: "1.0.0" };
    },
    tool() {
      return {
        // Descriptor for the tool
        group: "Fibonacci",
        type: "Segment",
        hint: "This hint will be shown on hover",
        data: [], // Default data
        settings: {}, // Default settings
      };
    },
    // Called after overlay mounted
    init() {
      // First pin is settled at the mouse position
      this.pins.push(new Pin(this, "p1"));
      // Second one is following mouse until it clicks
      this.pins.push(
        new Pin(this, "p2", {
          state: "tracking",
        })
      );
      this.pins[1].on("settled", () => {
        // Call when current tool drawing is finished
        // (Optionally) reset the mode back to 'Cursor'
        this.set_state("finished");
        this.$emit("drawing-mode-off");
      });
    },
    draw(ctx) {
      if (!this.p1 || !this.p2) return;
      ctx.lineWidth = this.line_width * 4;
      ctx.strokeStyle = this.color;
      ctx.beginPath();
      let xFrom = this.p1[0];
      let xTo = this.p2[0];
      let yTop = this.p1[1];
      let yBottom = this.p2[1];
      // Left
      new Seg(this, ctx).draw([xFrom, yTop], [xFrom, yBottom]);
      // Right
      new Seg(this, ctx).draw([xTo, yTop], [xTo, yBottom]);
      ctx.stroke();
      // Rest right
      ctx.beginPath();
      ctx.lineWidth = this.line_width;
      let delta = xTo - xFrom;
      for (const m of [2, 3, 5, 8, 13, 21, 34, 55]) {
        new Seg(this, ctx).draw(
          [xFrom + delta * m, yTop],
          [xFrom + delta * m, yBottom]
        );
      }
      // Rest left
      for (const m of [1, 2, 3, 5, 8, 13, 21, 34, 55]) {
        new Seg(this, ctx).draw(
          [xFrom - delta * m, yTop],
          [xFrom - delta * m, yBottom]
        );
      }
      ctx.stroke();
      this.render_pins(ctx);
    },
    use_for() {
      return ["FibonacciTime"];
    },
    data_colors() {
      return [this.color];
    },
  },
  // Define internal setting & constants here
  computed: {
    ...mapGetters(['themePrimaryColor','themeSecondaryColor','themeFontColor']),
    sett() {
      return this.$props.settings;
    },
    p1() {
      return this.$props.settings.p1;
    },
    p2() {
      return this.$props.settings.p2;
    },
    line_width() {
      return this.sett.lineWidth || 0.9;
    },
    color() {
      return this.sett.color || "#42b28a";
    },
  },
  data() {
    return {};
  },
};
</script>
